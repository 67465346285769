import { useAxios } from "./useAxios.tsx";
import { doRefreshToken } from "./useRefreshToken.tsx";

interface IDeleteToolAxiosResponse {
    data: {
        message: string;
        toolId: string;
    } | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useDeleteTool = () => {
    const { commonFetch, isLoading, data, error }: IDeleteToolAxiosResponse = useAxios<any>({
        url: `/data/tool/delete`,
        method: "DELETE",
    });

    const deleteTool = async (toolId: string) => {
        await doRefreshToken();
        await commonFetch({
            queryParameters: { id: toolId }
        });
    };

    return { deleteTool, isLoading, data, error };
};
