import { IExecutableLink } from "../../../models/IExecutableLink.ts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPipelineInformationData {
    name: string;
    purpose: string;
    // Renamed to assayType in the pipeline information form.
    processDatatypes: string[];
    version: string;
    // originally pipelineLink: string; in the pipeline information form.
    documentation: string[];
    executableLinks: IExecutableLink[];
    // This field was originally `institution: string;` in the pipeline information form. It has been changed to an array.
    institution: string[];
    // New field for the pipeline information form.
    datatype: string;
    doi: string;
}

export interface BuilderPipelineInfoState {
    // All form data for the pipeline information panel.
    formData: IPipelineInformationData | null;
}

const initialState: BuilderPipelineInfoState = {
    formData: null
}

export const builderPipelineInfoSlice = createSlice({
    name: 'builderPipelineInfo',
    initialState,
    reducers: {
        setPipelineInfo: (state, action) => {
            state.formData = action.payload;
        },
        resetPipelineInfo: (state) => {
            state.formData = null;
        },
        /**
         * Delete an assay type from the processDatatypes array.
         * @param state
         * @param action
         */
        deleteAssayType: (state, action: PayloadAction<{ assayType: string }>) => {
            if (state.formData) {
                state.formData.processDatatypes = state.formData.processDatatypes.filter((assayType) => assayType !== action.payload.assayType);
            }
        },

        /**
         * Replace an assay type in the processDatatypes array.
         * @param state
         * @param action
         */
        replaceAssayType: (state, action: PayloadAction<{ oldAssayType: string, newAssayType: string }>) => {
            if (state.formData) {
                const index = state.formData.processDatatypes.indexOf(action.payload.oldAssayType);
                if (index > -1) {
                    state.formData.processDatatypes[index] = action.payload.newAssayType;
                }
            }
        },

        /**
         * Add an assay type to the processDatatypes array.
         * @param state
         * @param action
         */
        addAssayType: (state, action: PayloadAction<{ assayType: string }>) => {
            if (state.formData) {
                state.formData.processDatatypes.push(action.payload.assayType);
            }
        }
    }
});

export const {
    setPipelineInfo,
    resetPipelineInfo,
    deleteAssayType,
    replaceAssayType,
    addAssayType
} = builderPipelineInfoSlice.actions;
export default builderPipelineInfoSlice.reducer;
