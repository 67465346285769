import { doRefreshToken } from "./useRefreshToken.tsx";
import {AddToolAxiosResponse, AddToolDto} from "./useAddTool.tsx";
import { useAxios } from "./useAxios.tsx";

interface ExecutableLink {
    executableLink: string;
    linkType: string;
    default: boolean;
}

interface Purpose {
    text: string;
    executableLinks: ExecutableLink[];
}

interface Tool {
    name: string;
    versions: string[];
    purposes: Purpose[];
    input: string[];
    output: string[];
    link: string[] | undefined;
    doi: string;
    user: string[];
}

interface EditToolDto {
    tool: Tool;
    email: string;  // Include the email property
}

export interface EditToolResponse {
    id: string;
    name: string;
    input: string[];
    output: string[];
    dateSubmitted: Date;
    editedOn: Date;
}

export interface EditToolAxiosResponse {
    data: EditToolResponse | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useEditTool = () => {
    const { commonFetch, isLoading, data, error }: EditToolAxiosResponse = useAxios<EditToolResponse>({
        url: "/data/tool/edit",
        method: "POST",
    });

    const editTool = async (editToolDto: EditToolDto) => {
        await doRefreshToken();  // Ensure the token is refreshed

        // Call commonFetch and let it update the data and error states
        await commonFetch({
            input: editToolDto
        });

    };

    return { editTool, isLoading, data, error };
};
