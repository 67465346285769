import {createSlice} from "@reduxjs/toolkit";
import {ISearchResult} from "../../hooks/useSearch.tsx";

export interface ISearchState {
    searchTerm: string;
}

const initialState: ISearchState = {
    searchTerm: "",
}

const searchSlice = createSlice({
    name: "search",
    initialState: initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
});

export const {setSearchTerm} = searchSlice.actions;

export default searchSlice.reducer;
