import {useAxios} from "./useAxios.tsx";
import {useDispatch} from "react-redux";
import {login} from "../store/slices/authSlice.ts";

export const useKeycloakLogin = () => {
    const { commonFetch, isLoading, data, error } = useAxios<any>({
        url: `/auth/keycloakLogin`,
        method: "POST",
    });

    const dispatch = useDispatch();

    const keycloakLogin = async (username: string, password: string) => await commonFetch({ input: { username, password } });

    if (data) {
        const accessToken = data.access_token;
        localStorage.setItem('cobe-accessToken', accessToken);
        dispatch(login());
    }

    return { keycloakLogin, isLoading, data, error };
}
