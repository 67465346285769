import { useAxios } from "./useAxios.tsx";
import { DocumentLikeDataType } from "../models/DocumentLikeDataType.ts";

export interface IGetExecutableLikesForToolData {
    userId: string;
    date: string;
}

export interface IGetExecutableLikesForToolResponse {
    data: DocumentLikeDataType | null,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetExecutableLikesForTool = (toolId: string) => {
    const { commonFetch, isLoading, data, error }: IGetExecutableLikesForToolResponse = useAxios<DocumentLikeDataType | null>({
        url: `/data/like/executable_likes/tool/${toolId}`,
        method: "GET",
    });

    const getExecutableLikesForTool = async () =>  await commonFetch({});
    return { getExecutableLikesForTool, isLoading, data, error }
}
