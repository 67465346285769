import {useAxios} from "./useAxios.tsx";
export interface useGetToolSearchSuggestions {
    data: any;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetToolSearchSuggestions = () => {
    const { commonFetch, isLoading, data, error } : useGetToolSearchSuggestions = useAxios<any>({
        url: "/data/tool/suggestions",
        method: "GET",
    });

    const getToolSearchSuggestions = async (queryParams: any) =>  await commonFetch({queryParameters: queryParams});

    return { getToolSearchSuggestions, isLoading, data, error };
}
