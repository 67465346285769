import { IPurposeTool } from "../useSearch.tsx";
import { useAxios } from "../useAxios.tsx";
import { DraftInstitution } from "../../store/slices/Builder/BuilderSaveDraftState.ts";

// Base interface for any tool node
interface IBaseToolNode {
    id: string;
    type: string;
    name: string;
    toolId: string;
    version: string;
    purpose: string;
    // This is a new field that was not present in the original code, so it is optional
    institution?: DraftInstitution[];
}

// A tool node loaded from the database
interface IToolNode extends IBaseToolNode {
    _id: string;
    nodetype: string;
    __v: number;
}

// A tool node not yet persisted in the database
type IUnpersistedToolNode = Omit<IBaseToolNode, 'nodetype'>;

export interface IToolNodeData {
    _id: string;
    doi: string;
    input: string[];
    output: string[];
    link: string;
    purposes: IPurposeTool[];
    name: string;
    type: string;
    score: number;
    submittedBy: string;
    __v: number;
    keywords: string[];
    versions: string[];
    associatedTools: {
        _id: string,
        name: string,
        purposes: { text: string }[]
    },
    // This is a new field that was not present in the original code, so it is optional
    institution?: DraftInstitution[]
}

export type FoundToolNode = IToolNode | IUnpersistedToolNode;

// Interface for getNodeForTool data
export type IGetNodeForToolData = {
    node: FoundToolNode;
    tool: IToolNodeData;
}

export interface IGetNodeForToolAxiosResponse {
    data: IGetNodeForToolData;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetNodeForTool = () => {
    const { commonFetch, isLoading, data, error }: IGetNodeForToolAxiosResponse = useAxios<any>({
        url: "/view/pipeline-builder/node",
        method: "GET",
    });

    const getNodeForTool = async (toolName: string, version: string, purpose: string) =>
        await commonFetch({ queryParameters: { toolName, version, purpose } });

    return { getNodeForTool, isLoading, data, error };
}
