import {classNames} from "primereact/utils";
import {ContextMenuPassThroughOptions} from "primereact/contextmenu";

export const contextmenu: ContextMenuPassThroughOptions = {
    root: {
        className: 'py-1 bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-none shadow-md rounded-lg w-52',
    },
    menu: {
        className: classNames('m-0 p-0 list-none', 'outline-none')
    },
    menuitem: {
        className: 'relative, hover:bg-gray-200 dark:hover:bg-gray-800/80 hover:text-gray-700 dark:hover:text-white/80 select-none'
    },
    action: {
        className: classNames('cursor-pointer flex items-center no-underline overflow-hidden relative', 'text-gray-700 dark:text-white/80 py-3 px-5 select-none')
    },
    icon: {
        className: 'text-gray-600 dark:text-white/70 mr-2',
    },
    label: {
        className: 'text-gray-600 dark:text-white/70',
    },
    separator: {
        className: 'border-t border-gray-200 dark:border-gray-800/80 my-1'
    },
    transition: {
        timeout: { enter: 250 },
        classNames: {
            enter: 'opacity-0',
            enterActive: '!opacity-100 transition-opacity duration-250'
        }
    }
}
