import { PipelineNodeDetailList } from "../../PipelineDetails/PipelineNodeDetailList.tsx";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { Footer } from "./Footer.tsx";
import { IFormattedData } from "../../../models/IFormattedData.ts";
import { UserAvatarGroup } from "../../UserAvatarGroup/UserAvatarGroup.tsx";

interface PipelineExpandedDiagramSideBarProps {
    isVisible: boolean;
    onHide: (newVisibility: boolean) => void;
    pipelineNodeDetails: IFormattedData | null;
    dataForPDF: IFormattedData;
    openInLink: string;
}



export const PipelineExpandedDiagramSideBar = (props: PipelineExpandedDiagramSideBarProps) => {

    const sidebarHeader = (
        <>
            <div className="w-full h-16 bg-white border-gray-200 justify-start items-center gap-5 inline-flex">
                <div
                    className="grow shrink basis-0 text-neutral-800 text-xl font-semibold font-inter leading-normal">
                    {
                        props.pipelineNodeDetails
                        && props.pipelineNodeDetails.pipelines
                        && props.pipelineNodeDetails.pipelines.length > 0
                        &&
                        props.pipelineNodeDetails.pipelines[0].name
                    }
                </div>
            </div>
        </>
    );

    /**
     * Formats user's names into a string list for the user avatar group
     * @param names - The list of names to format
     * @returns The formatted string
     */
    function formatNames(names: string[]): string {
        const totalNames = names.length;

        switch (totalNames) {
            case 0:
                return "No names available";
            case 1:
                return names[0];
            case 2:
                return names.join(" & ");
            default:
                const remainingCount = totalNames - 2;
                return `${names[0]}, ${names[1]} & ${remainingCount} more`;
        }
    }

    return (
        <Sidebar
            visible={ props.isVisible }
            onHide={ () => props.onHide(false) }
            position={ "right" }
            header={ sidebarHeader }

            pt={ {
                root: {
                    className: "overflow-clip w-1/4"
                },
                header: {
                    className: "border-b py-2"
                }
            } }
        >
            {
                props.pipelineNodeDetails ?


                    <div className="h-full flex flex-col">
                        {/* Users & List of pipeline tools from the tree */ }
                        <div className="flex flex-col flex-grow overflow-y-auto pt-5 gap-2">
                            <div className="flex flex-row items-center pl-2 ">
                                <UserAvatarGroup users={ props.pipelineNodeDetails.pipelines[0].user }/>
                                <p className="text-neutral-800 text-sm font-normal font-inter leading-tight">
                                    { formatNames(props.pipelineNodeDetails.pipelines[0].user.map(user => user.name)) }
                                </p>
                            </div>
                            <p className="text-neutral-800 text-sm font-normal font-inter leading-tight">
                                Version: { props.pipelineNodeDetails.pipelines[0].version ? props.pipelineNodeDetails.pipelines[0].version : "Not Available" }
                            </p>
                            <PipelineNodeDetailList formattedData={ props.pipelineNodeDetails }/>
                        </div>

                        <Footer formattedData={ props.dataForPDF } openInLink={ props.openInLink } executableLinks={props.pipelineNodeDetails.pipelines[0]?.main.executableLinks}/>
                    </div>
                    : "Missing Pipeline Information"
            }
        </Sidebar>
    );
};
