import { useAxios } from "../useAxios.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store.ts";
import {consoleWrap} from "../../main.tsx";
import { BuilderSaveDraftState } from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import {doRefreshToken} from "../useRefreshToken.tsx";


interface ISaveDraftAxiosResponse {
    data: null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useSaveDraft = () => {
    const draftState = useSelector((state: RootState) => state.builderSaveDraft);

    const { commonFetch, isLoading, data, error }: ISaveDraftAxiosResponse = useAxios({
        url: `/view/pipeline-builder/save-draft/${ draftState._id }`,
        method: "POST",
    });

    const postSaveDraft = async (draftToSave: BuilderSaveDraftState) => {
        if (draftState._id) {
            await doRefreshToken()
            await commonFetch({ input: draftToSave });
        } else {
            consoleWrap.error("Save draft request could not be sent because the draft ID is missing.");
        }
    }

    return { postSaveDraft, isLoading, data, error };


}
