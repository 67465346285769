import {useAxios} from "./useAxios.tsx";

export interface IUserProfileResponse {
    pipelines: any[];
    tools: any[];
    user: {
        email: string;
        admin: boolean;
        affiliation: string;
        affiliationOptions: { label: string, value: string }[];
    };
}

export interface IGetUserProfileAxiosResponse {
    data: IUserProfileResponse | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetUserProfile = () => {
    const { commonFetch, isLoading, data, error }: IGetUserProfileAxiosResponse = useAxios<IUserProfileResponse>({
        url: `/user/profile`,
        method: "GET",
    });

    const getUserProfile = async () => await commonFetch({});

    return {
        getUserProfile,
        isLoading,
        data,
        error,
    };

};
