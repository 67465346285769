import React from "react";

export interface ApiWrapperErrorProps {
    label: string;
}

export const ApiWrapperError = (props: ApiWrapperErrorProps) => {
    return (
        <>
            { props.label }
        </>
    );
};
