import { useEffect, useRef, useState } from "react";
import {ToggleButton} from "primereact/togglebutton";
import { ISearchResult, useSearch } from "../../hooks/useSearch.tsx";
import {SearchResultsTabView} from "./SearchResultsTabView.tsx";
import {ApiWrapper} from "../ApiWrapper/ApiWrapper.tsx";
import {SearchBar} from "./SearchBar.tsx";
import {ToolBarButtons} from "./ToolBarButtons.tsx";
import {EmptyState} from "./EmptyState.tsx";
import { ISessionSearchState, updateSessionSearchResultState } from "../../helpers/state/SearchState.ts";
import {consoleWrap} from "../../main.tsx";
import {useDispatch} from "react-redux";

// used only to test that tailwind primereact is working in storybook
export const PrimeReactToggle = () => {
    return (
        <ToggleButton
            onLabel="Wizard"
            offLabel="Wizard"
            onIcon={(options: any) => <img alt="close icon" src="images/close-icon-blue.svg" {...options.iconProps} className="w-5 h-5" />}
            offIcon="pi pi-sliders-h"
            checked={false}
             />
    )
}




export const ExploreMainContent = (props: {searchResult?: any, searchTerm?: string}) => {

    const { searchResult } = props;

    //@ts-ignore
    const [ resultState, setResultState ] = useState<ISearchResult>(null);
    const [searchResultState, setSearchResultState] = useState<any>(null);

    const dispatch = useDispatch();
    
    const {
        getSearch,
        isLoading: searchLoading,
        data: searchResponse,
        error: searchError
    } = useSearch();

    const sortResultsByLikes = (data: ISearchResult) => {
        if (data && data.pipelines) {
            data.pipelines = data.pipelines.sort((a, b) => b.likeData.likes - a.likeData.likes);
        }
        if (data && data.tools) {
            data.tools = data.tools.sort((a, b) => b.likeData.likes - a.likeData.likes);
        }
        return data;
    };

    useEffect(() => {
        if (resultState) {
            setResultState(sortResultsByLikes(resultState));
        }
    }, [resultState]);

    useEffect(() => {
        loadSearchState();

        if (props.searchTerm) {
            getSearch({question: props.searchTerm, pipelineToolOnly: false})
        }
    }, []);

    useEffect(() => {
        if (searchResult) {
            setResultState(sortResultsByLikes(searchResult));
        }
    }, [searchResult]);

    useEffect(() => {
        if (searchResponse) {
            consoleWrap.log(searchResponse);
            setResultState(sortResultsByLikes(searchResponse));
            setSearchResultState(searchResponse);
        }
    }, [searchResponse]);

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            updateSessionSearchResultState(resultState);
        }
    }, [resultState]);

    const loadSearchState = () => {
        const savedSearchString = sessionStorage.getItem("searchState");
        if (savedSearchString) {
            const savedSearch: ISessionSearchState = JSON.parse(savedSearchString);
            setResultState(savedSearch.results);
        }
    }

    const search = (value: string) => {
        getSearch({question: value, pipelineToolOnly: false})
    }

    const foundResults = resultState && resultState?.pipelines.length > 0 || resultState?.tools.length > 0;

    return (
        <>
            <div className="flex-auto bg-white h-screen flex flex-col py-5">
                <SearchBar onSearchClick={search} />
                <ToolBarButtons />
                <ApiWrapper isLoading={searchLoading} error={searchError} data={searchResultState} loadingLabel={"Getting results..."} errorLabel={ "ERROR!" }>
                    {(!resultState || !foundResults ) && <EmptyState />}

                    {foundResults &&  <SearchResultsTabView numPipelines={resultState.pipelines.length}
                                                     numTools={resultState.tools.length}
                                                     pipelines={resultState.pipelines}
                                                     tools={resultState.tools}/>}
                </ApiWrapper>
            </div>
        </>
    )
};
