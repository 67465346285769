import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type BuilderMetaState = {
    // Whether the builder is in read-only mode.
    readOnly: boolean;
    flowViewer: {
        position: { x: number, y: number }
    }
}

const initialState: BuilderMetaState = {
    readOnly: false,
    flowViewer: {
        position: { x: 0, y: 0 }
    }
}

/**
 * Slice for the builder meta state. Includes information on how the builder should be loaded.
 */
const BuilderMeta = createSlice({
    name: "BuilderMeta",
    initialState,
    reducers: {
        setBuilderMetaReadOnly: (state, action) => {
            state.readOnly = action.payload;
        },
        setBuilderMetaFlowViewerPosition: (state, action: PayloadAction<{ x: number, y: number }>) => {
            state.flowViewer.position = action.payload;
        }
    }
});

export const {
    setBuilderMetaReadOnly,
    setBuilderMetaFlowViewerPosition
} = BuilderMeta.actions;
export default BuilderMeta.reducer;
