import { useAxios } from "../useAxios.tsx";
import { IDraftGraph, IDraftPipelineInfo } from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import {doRefreshToken} from "../useRefreshToken.tsx";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";

export interface IInitializeTreeRequestBody {
    pipeline: {
        name: string,
        datatype: string[],
        // Otherwise known as processDataTypes
        assayTypes: string[],
        version: string,
        link: string[],
        user: { name: string, link: string }[],
        main: {
            executableLinks: { executableLink: string, linkType: string, default: boolean }[],
            nodes: []
        },
        subBranches: [],
        purposes: string[]
        doi: string,
    },
}

export interface IInitializeTreeResponse {
    pipeline: IDraftPipelineInfo,
    "graph": IDraftGraph,
    "submittedBy": string,
    "dateSaved": string,
    "_id": string,
    "__v": number,
    status: DraftStatus,

}

export interface IInitializeTreeAxiosResponse {
    data: IInitializeTreeResponse;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useInitializeTree = () => {
    const { commonFetch, isLoading, data, error }: IInitializeTreeAxiosResponse = useAxios<any>({
        url: "/view/pipeline-builder/initialize-tree",
        method: "POST",
    });

    const getInitializedTreeData = async (reqBody: IInitializeTreeRequestBody) => {
        await doRefreshToken();
        await commonFetch({ input: reqBody });
    }

    return { getInitializedTreeData, isLoading, data, error };
}
