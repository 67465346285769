import { useAxios } from "../useAxios.tsx";
import {doRefreshToken} from "../useRefreshToken.tsx";

export interface ISubmissionTableRowData {
    id: string;
    name: string;
    status: string;
    userEmail: string;
    editedOn: Date;
    submittedOn: Date;
}

export interface IGetSubmissionsResponse {
    data: { submissions: ISubmissionTableRowData[] };
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}
export const useGetSubmissionTable = () => {
    const { commonFetch, isLoading, data, error }: IGetSubmissionsResponse = useAxios<any>({
        url: "/view/pipeline-builder/submissions-table",
        method: "GET",
    });

    const getSubmissionTableData = async () => {
        try {
            return await commonFetch({});
        } catch (e) {
            console.error("Failed to refresh token or fetch submission table data", e);
            throw e;
        }
    };

    return { getSubmissionTableData, isLoading, data, error };
};