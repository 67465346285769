
export const getFirstTwoLetters = (sentence: string): string => {
    // Split the sentence into words.
    const words = sentence.split(' ');

    if (words.length === 1) {
        return words[0][0];
    }

    // Get the first letter of each of the first two words.
    const firstLetterOfFirstWord = words[0][0];
    const firstLetterOfSecondWord = words[1][0];

    // Return the first letter of the first two words, concatenated together.
    return firstLetterOfFirstWord + firstLetterOfSecondWord;
}
