import { configureStore, ThunkAction, UnknownAction } from '@reduxjs/toolkit';
import counterReducer, { ICounterState } from "./slices/counterSlice.ts";
import builderPipelineInfoReducer, { BuilderPipelineInfoState } from "./slices/Builder/BuilderPipelineInfoState.ts";
import builderSaveDraftReducer, { BuilderSaveDraftState } from "./slices/Builder/BuilderSaveDraftState.ts";
import builderSubmittedPipelinesReducer, { BuilderSubmittedPipelinesState } from "./slices/Builder/BuilderSubmittedPipelines.ts";
import authReducer, { IAuthState } from "./slices/authSlice.ts";
import searchReducer, { ISearchState } from "./slices/searchSlice.ts";
import toolsReducer, { IToolsState } from "./slices/toolsSlice.ts";
import builderMetaReducer, { BuilderMetaState } from "./slices/Builder/BuilderMeta.ts";

export interface IRootState {
    counter: ICounterState;
    auth: IAuthState;
    builderPipelineInfo: BuilderPipelineInfoState
    builderSaveDraft: BuilderSaveDraftState,
    builderMeta: BuilderMetaState,
    builderSubmittedPipelines: BuilderSubmittedPipelinesState,
    search: ISearchState,
    tools: IToolsState,
}

export const store = configureStore<IRootState>({
    reducer: {
        counter: counterReducer,
        auth: authReducer,
        builderPipelineInfo: builderPipelineInfoReducer,
        builderSaveDraft: builderSaveDraftReducer,
        builderMeta: builderMetaReducer,
        builderSubmittedPipelines: builderSubmittedPipelinesReducer,
        search: searchReducer,
        tools: toolsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>
