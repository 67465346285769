import React from 'react';
import { Tag } from 'primereact/tag';
import { DraftStatus } from "../../models/Builder/DraftStatusEnum";

const getStyles = (status: DraftStatus): string | null => {
    switch (status) {
        case DraftStatus.Approved:
            return 'bg-approved_green';
        case DraftStatus.Draft:
            return 'bg-draft_gray';
        case DraftStatus.Submitted:
            return 'bg-accent_yellow bg-opacity-20';
        case DraftStatus.Changes_Requested:
            return 'bg-accent_red bg-opacity-20';
        default:
            return 'hidden';
    }
};

// Define the props type for the component
interface PipelineDraftStatusTagProps {
    status: DraftStatus;
}

export const PipelineDraftStatusTag: React.FC<PipelineDraftStatusTagProps> = ({ status }) => {
    return (
        <Tag className={`${getStyles(status)} !font-normal text-sm`} value={status} rounded />
    )
}
