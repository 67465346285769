import { useAxios } from "../useAxios.tsx";
import { IPipelineTableRowData } from "../../components/PipelineTable/PipelineTable.tsx";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";

export interface IPipelineBuilderTableRowResponse {
    id: string;
    name: string;
    status: DraftStatus;
    dataType: string;
    assayTypes: string;
    nodes: number;
    editedOn: string;
    createdOn: string;
}

export interface IPipelineDraftTableDataResponse {
    pipelines: IPipelineBuilderTableRowResponse[];
    drafts: IPipelineBuilderTableRowResponse[];
}

export interface IGetPipelineDraftTableDataAxiosResponse {
    data: IPipelineDraftTableDataResponse;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetPipelineDraftTableData = () => {
    const { commonFetch, isLoading, data, error }: IGetPipelineDraftTableDataAxiosResponse = useAxios<any>({
        url: "/view/pipeline-builder/pipeline-builder-table",
        method: "GET",
    });

    const getPipelineDraftTableData = async () =>
        await commonFetch({});

    return { getPipelineDraftTableData, isLoading, data, error };
}
