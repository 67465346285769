import {useAxios} from "../useAxios.tsx";
import {BuilderSaveDraftState} from "../../store/slices/Builder/BuilderSaveDraftState.ts";

export type GetPipelineDraftByIdResponse  = {
    data: BuilderSaveDraftState;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetPipelineDraftById = (draftId: string) => {
    const { commonFetch, isLoading, data, error }: GetPipelineDraftByIdResponse = useAxios<any>({
        url: `/view/pipeline-builder/pipeline-draft/${draftId}`,
        method: "GET",
    });

    const getPipelineDraftById = async () =>  {
        await commonFetch({});
    }

    return { getPipelineDraftById, isLoading, data, error };
};
