import {classNames} from "primereact/utils";
import {PasswordPassThroughOptions} from "primereact/password";

const TRANSITIONS = {
        enter: 'opacity-0 scale-75',
        enterActive: 'transition-transform transition-opacity duration-150 ease-in',
        exitActive: 'transition-opacity duration-150 ease-linear',
        exit: 'opacity-0'
};

export const password: PasswordPassThroughOptions = {
    root: ({ props }: any) => ({
        className: classNames('inline-flex relative', {
            'opacity-60 select-none pointer-events-none cursor-default': props.disabled
        })
    }),
    panel: ({ props, state }: any) => ({
        className: classNames('p-5 bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 shadow-md rounded-md'),
    }),
    meter: ({ props, state }: any) => ({
        className: classNames('mb-2 bg-gray-300 dark:bg-gray-700 h-3'),
    }),
    meterLabel: ({ state, props }: any) => ({
        className: classNames(
            'transition-width duration-1000 ease-in-out h-full',
            {
                'bg-red-500': state.meter?.strength == 'weak',
                'bg-orange-500': state.meter?.strength == 'medium',
                'bg-green-500': state.meter?.strength == 'strong'
            },
            { 'pr-[2.5rem] ': props.toggleMask }
        )
    }),
    showIcon: {
        className: classNames('absolute top-1/2 -mt-2', 'right-3 text-gray-600 dark:text-white/70')
    },
    hideIcon: {
        className: classNames('absolute top-1/2 -mt-2', 'right-3 text-gray-600 dark:text-white/70')
    },
    transition: {
        classNames: TRANSITIONS,
        addEndListener: (node: HTMLElement, done: any) => {
            node.addEventListener('transitionend', done, false);
        }
    }
}
