import { useAxios } from "./useAxios.tsx";
import { DocumentLikeDataType } from "../models/DocumentLikeDataType.ts";
import {doRefreshToken} from "./useRefreshToken.tsx";


type SetPipelineLikeResponse = {
    data: DocumentLikeDataType | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useTogglePipelineLike = (pipelineId: string) => {
    const { commonFetch, isLoading, data, error }: SetPipelineLikeResponse = useAxios<DocumentLikeDataType
        | null>({
        url: `/data/like/executable_likes/pipeline/${pipelineId}`,
        method: "POST",
    });

    const postTogglePipelineLike = async () =>  {
        await doRefreshToken();  // Ensure the token is refreshed
        await commonFetch({});
    }
        
    return { postTogglePipelineLike, isLoading, data, error }
}
