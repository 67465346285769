import { Handle, NodeProps, Position } from "reactflow";
import { BuilderToolNodeIds } from "./BuilderToolNode.tsx";
import { ReactSVG } from "react-svg";
import IMAGES from "../../../../assets/images/images.tsx";
import { PlusHandle } from "../PlusHandle.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store.ts";
import useNodeService from "../../../../hooks/pipeline-builder/useNodeService.ts";

export enum BuilderAssayNodeIds {
    NODE_ID_PREFIX = 'assay-node-',
    NODE_TYPE = 'assayNode',
    NODE_INPUT_HANDLE_ID = 'input',
    NODE_OUTPUT_HANDLE_ID = 'output'
}

export const generateBuilderAssayNodeId = () => {
    // Generate an ID that uses the prefix and a random number
    return BuilderAssayNodeIds.NODE_ID_PREFIX + Math.floor(Math.random() * 1000000000);
}

export interface BuilderAssayNodeData {
    // The label of the node
    label: string,
    // The byline of the node
    byline: string,
    // Handler for when the node is clicked
    onNodeClicked?: (nodeId: string) => void,
    // Whether the node is selected
    isSelected: boolean
    // The data of the node.
    // This data comes from the back end, after the user searches an existing node and fills in the options.
    data?: string
}

export const BuilderAssayNode = (props: NodeProps<BuilderAssayNodeData>) => {
    const { onNewNodeHandleClicked } = useNodeService();
    const isReadOnly = useSelector((state: RootState) => state.builderMeta.readOnly);

    return (
        <>
            {/* -m-[6px] is used because the border of this div, and the one nested directly below it offset the position of the node.
                So we need to offset the margin by the same total amount to keep it in the same position. border-8 + border-2 = 10px */ }
            <div
                className={ props.data.isSelected ? `-m-[6px] rounded-lg border-4 border-blue-700 border-opacity-0 bg-blue-700 bg-opacity-20 overflow-clip` : "" }>
                <div
                    className={ props.data.isSelected ? `self-stretch rounded-lg border-2 border-blue-700 bg-blue-700` : "" }>
                    <div
                        className="w-52 pl-3 pr-5 py-3 bg-white rounded-md shadow border border-gray-200 justify-center items-start gap-2 inline-flex"
                    >
                        <div
                            className="p-1.5 bg-indigo-400 rounded-4xl border border-black border-opacity-20 justify-center items-center gap-2.5 flex">
                            <div className="w-6 h-6 relative">
                                <div className="w-6 h-6 left-0 top-0 absolute">
                                    <ReactSVG src={ IMAGES.bar_chart_icon } className="text-white"/>
                                </div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                            <div
                                className="self-stretch text-neutral-800 text-sm font-semibold font-inter leading-none">
                                { props.data.label }
                            </div>
                            <div className="self-stretch text-neutral-500 text-xs font-normal font-inter leading-4">
                                { props.data.byline }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PlusHandle
                handleId={ BuilderToolNodeIds.NODE_OUTPUT_HANDLE_ID }
                onClick={ () => onNewNodeHandleClicked(props.id, BuilderToolNodeIds.NODE_OUTPUT_HANDLE_ID) }
                hidden={ isReadOnly }
            />
            <Handle
                type="target"
                position={ Position.Left }
                id={ BuilderToolNodeIds.NODE_INPUT_HANDLE_ID }
                className="invisible"
                hidden={ false }/>
        </>
    );
};
