import React from "react";
import 'primeicons/primeicons.css';
import {ProgressBar} from "primereact/progressbar";

export const ApiWrapperLoading = (props: { loadingLabel: string }) => {
    return (
        <>
            <div className="flex flex-col items-center h-full justify-center">
                <ProgressBar mode="indeterminate" className="max-w-sm w-full"/>
                <p>{props.loadingLabel}</p>
            </div>
        </>
    );
};
