import {useAxios} from "./useAxios.tsx";
import {useEffect} from "react";
import {login} from "../store/slices/authSlice.ts";
import {useDispatch} from "react-redux";
import {consoleWrap} from "../main.tsx";

export const useGithubLogin = () => {
    const { commonFetch, isLoading, data, error } = useAxios<any>({
        url: `/auth/github/token`,
        method: "POST",
    });

    const dispatch = useDispatch();


    const githubLogin = async (code: string) =>  await commonFetch({input: {code}});

    consoleWrap.log('useGithubLogin', { isLoading, data, error });

    useEffect(() => {
        if (data) {
            consoleWrap.log('data', data);
            const accessToken = data.access_token;
            localStorage.setItem('cobe-accessToken', accessToken);
            dispatch(login());
        }
    }, [data]);

    return { githubLogin, isLoading, data, error };
};
