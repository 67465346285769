import { useState } from "react";
import { useAxios } from "./useAxios"; // Assuming useAxios is in the same directory

type FeedbackInput = {
    feedback: string;
    email: string;
};

type FeedbackResponse = {
    success: boolean;
    message: string;
};

export function useSubmitFeedback() {
    const [feedbackMessage, setFeedbackMessage] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);
	const [viewModal, setViewModal] = useState(false)

    const { commonFetch, isLoading, data, error } = useAxios<FeedbackResponse>({
        url: "/feedback/submit",
        method: "POST",
    });

    const sendFormFeedback = async (email: string) => {
        setIsSubmitting(true);

        try {
            await commonFetch({
                input: { feedback: feedbackMessage, email },
            });
            setFeedbackMessage("");
			setViewModal(false)
            setConfirmationVisible(true);
        } catch (error) {
            console.error("Error submitting feedback:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return {
        feedbackMessage,
        setFeedbackMessage,
        isSubmitting,
        isLoading,
		confirmationVisible,
        setConfirmationVisible,
        sendFormFeedback,
		viewModal,
		setViewModal,
        error,
        data,
    };
}
