import { Button } from "primereact/button";
import {FormEvent} from "react";

interface RightPanelSaveButtonProps {
    onClick: (e: MouseEvent | FormEvent) => void;
}

export const RightPanelSaveButton = (props: RightPanelSaveButtonProps) => {
    return (
        <>
            <div
                className="w-full h-[72px] p-5 border-t border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
                <Button
                    label="Save"
                    className="h-8 w-full !py-0 text-sm font-semibold !text-white bg-blue-700 rounded font-inter"
                    onClick={ props.onClick }
                />
            </div>
        </>
    );
};
