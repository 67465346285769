import {useContext} from "react";
import {SideBarContext} from "../../pages/Home";
import {ToggleButton, ToggleButtonChangeEvent} from "primereact/togglebutton";

export const ToolBarButtons = (props: {clasName?: any}) => {
    const {isSideBarOpen, setIsSideBarOpen}: any = useContext(SideBarContext)
    return (
        <div className={`${props.clasName ? props.clasName : 'flex py-2 w-full px-[60px]'}`}>
            <ToggleButton
                onLabel="Wizard"
                offLabel="Wizard"
                onIcon={(options: any) => <img alt="close icon" src="images/close-icon-blue.svg" {...options.iconProps} className="w-5 h-5" />}
                offIcon="pi pi-sliders-h"
                checked={isSideBarOpen}
                onChange={(e:  ToggleButtonChangeEvent) => setIsSideBarOpen(e.value)} />
        </div>
    )
}
