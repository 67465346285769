import { ReactSVG } from "react-svg";
import IMAGES from "../../assets/images/images.tsx";
import { classNames } from "primereact/utils";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/store.ts";
import { useTogglePipelineLike } from "../../hooks/useTogglePipelineLike.ts";
import { DocumentLikeDataType } from "../../models/DocumentLikeDataType.ts";
import { useEffect } from "react";
import { useToggleToolLike } from "../../hooks/useToggleToolLike.ts";
import { updateSessionSearchResultLikesState } from "../../helpers/state/SearchState.ts";

export type LikeButtonProps = {
    // The number of likes for the document
    likeCount: number;
    // Whether the document is liked by the user. If true, the thumb up icon is filled in blue. If false, the thumb up icon is empty.
    isLiked: boolean;
    // Controlling whether the button is clickable
    isClickable: boolean;
    // Details used in sending the like toggle request to the backend
    likeActionDetails?: {
        datatype: "pipeline" | "tool";
        datatypeId: string;
        // The onLikesChanged function is called when the user clicks the like button and the like toggle request is successful
        // It allows the parent component to handle changed like data from after the like toggle request
        onLikesChanged?: (likeResponse: DocumentLikeDataType) => void;
    }
}

export const LikeButton = (props: LikeButtonProps) => {
    // The button should only be clickable if the user is logged in
    const isLoggedIn = useSelector((state: IRootState) => state.auth.isLoggedIn);

    // This button is clickable if the following conditions are all true: the user is logged in, the button is clickable, and the likeActionDetails exists
    const isUserClickable = isLoggedIn && props.isClickable && props.likeActionDetails;

    // The pipelineToggleLikeHook is only defined if the user is clickable and the likeActionDetails datatype is "pipeline"
    const {
        data: pipelineToggleLikeData,
        postTogglePipelineLike
    } = useTogglePipelineLike(props.likeActionDetails?.datatypeId || "");

    // The toolToggleLikeHook is only defined if the user is clickable and the likeActionDetails datatype is "tool"
    const {
        data: toolToggleLikeData,
        postToggleToolLike
    } = useToggleToolLike(props.likeActionDetails?.datatypeId || "");

    // If this is for a pipeline, and the response from the like toggle request is successful, then call the onLikesChanged function
    useEffect(() => {
        if (pipelineToggleLikeData && props.likeActionDetails?.onLikesChanged) {
            // Call the onLikesChanged function with the new like data
            props.likeActionDetails.onLikesChanged(pipelineToggleLikeData);

            // Update the like data in the session storage
            updateSessionSearchResultLikesState(
                props.likeActionDetails.datatypeId,
                "pipelines",
                pipelineToggleLikeData);
        }
    }, [pipelineToggleLikeData]);

    // If this is for a tool, and the response from the like toggle request is successful, then call the onLikesChanged function
    useEffect(() => {
        if (toolToggleLikeData && props.likeActionDetails?.onLikesChanged) {
            // Call the onLikesChanged function with the new like data
            props.likeActionDetails.onLikesChanged(toolToggleLikeData);

            // Update the like data in the session storage
            updateSessionSearchResultLikesState(
                props.likeActionDetails.datatypeId,
                "tools",
                toolToggleLikeData);
        }
    }, [toolToggleLikeData]);

    /**
     * This function is called when the user clicks the like button.
     * If the likeActionDetails datatype is "pipeline" and the pipelineToggleLikeHook is defined, then the postTogglePipelineLike function is called.
     * If the likeActionDetails datatype is "tool", then the postToggleToolLike function is called.
     */
    const handleLikeClick = () => {
        // If the likeActionDetails exists, then continue the onClick function
        if (props.likeActionDetails) {
            // If the datatype is "pipeline" and the pipelineToggleLikeHook exists, then call the postTogglePipelineLike function
            if (props.likeActionDetails.datatype === "pipeline") {
                postTogglePipelineLike();

                // If the datatype is "tool", then call the postToggleToolLike function
            } else if (props.likeActionDetails.datatype === "tool") {
                postToggleToolLike();
            }
        }
    }

    return (
        <>
            <div
                className={
                    classNames(
                        "flex flex-row rounded-full py-1 px-2 items-center",
                        {
                            "cursor-pointer hover:bg-gray-100 transition-colors duration-300": isUserClickable
                        }
                    ) }
                onClick={ isUserClickable ? handleLikeClick : undefined }>

                <div data-testid="likeBtn">
                    {
                        // If the document is liked, then display the filled thumb up icon. Otherwise, display the empty thumb up icon.
                        props.isLiked ?
                            // Color the filled thumb icon based on if the button is clickable or not.
                            <ReactSVG
                                src={ IMAGES.thumb_up_filled_icon }
                                className={
                                    classNames({
                                            "mr-1": true,
                                            "text-gray-700": !isUserClickable,
                                            "text-[#2463BC]": isUserClickable
                                        }
                                    )}/>
                            // Display the empty thumb up icon
                            : <ReactSVG src={ IMAGES.thumb_up_empty_icon } className="mr-1 text-gray-700"/>
                    }
                </div>
                <div data-testid="likeBtnNumLikes" className="text-sm font-normal font-inter">
                    { props.likeCount }
                </div>
            </div>
        </>
    );
};
