import { Handle, Position } from "reactflow";
import { MouseEvent } from "react";

interface PlusHandleProps {
    onClick?: () => void,
    handleId: string,
    className?: string,
    hidden?: boolean
}

export const PlusHandle = (props: PlusHandleProps) => {
    const onHandleClicked = (e: MouseEvent) => {
        // Stops the click event from propagating to the node. This avoids selecting the node when the handle is clicked.
        e.stopPropagation();
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <>
            <Handle
                type="source"
                position={ Position.Right }
                id={ props.handleId }
                onClick={ onHandleClicked }
                className={ `!w-[18px] !h-[18px] !bg-plus_icon !bg-blue-700 !translate-x-1 !-translate-y-2 !cursor-pointer ${ props.className } ${ props.hidden ? "invisible" : "" }` }
            />
        </>
    );
};
