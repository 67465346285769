import { useContext, useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Chip, ChipProps, ChipRemoveEvent } from "primereact/chip";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { SideBarContext } from "../../pages/Home";
import { useGetDecisionTree } from "../../hooks/useGetDecisionTree.tsx";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useGetPipelinePurposes } from "../../hooks/useGetPipelinePurposes.tsx";
import { MultiSelect } from "primereact/multiselect";
import { TimesCircleIcon } from "primereact/icons/timescircle";
import { FilterGraphQueryParamType, useFilterGraph } from "../../hooks/useFilterGraph.tsx";
import { consoleWrap } from "../../main.tsx";
import { setSearchTerm } from "../../store/slices/searchSlice.ts";
import { useDispatch } from "react-redux";


const Header = () => {
    const {setIsSideBarOpen}: any = useContext(SideBarContext)
    return (
        <>
            <div className="w-full h-[60px] flex items-center justify-between px-5 py-5">
                <div className="text-xl font-bold">Explore</div>
                <div onClick={() => setIsSideBarOpen(false)}
                    className="w-10 h-10 flex items-center justify-center cursor-pointer rounded-full hover:backdrop-brightness-95">
                    <img src="images/close-icon.svg" alt="Close" className="text-black" />
                </div>
            </div>
        </>
    )
}

const FiltersContent = (props: {onChange: (data: { assayType: string, purposes: string[] }) => void, reset: boolean}) => {
    const {onChange, reset} = props;
    const {isSideBarOpen}: any = useContext(SideBarContext)
    const {getDecisionTree, isLoading, error, data: decisionTreeData} = useGetDecisionTree();
    const {getPipelinePurposes, data: pipelinePurposesData, isLoading: pipelinePurposesIsLoading, error: pipelinePurposesError} = useGetPipelinePurposes();
    const [selectedAssayType, setSelectedAssayType] = useState('All')
    const [assayTypes, setAssayTypes] = useState<any[]>([])
    const [downstreamAnalysis, setDownstreamAnalysis] = useState<{value: string, label: string}[]>([])
    const [selectedDownstreamAnalysis, setSelectedDownstreamAnalysis] = useState<string[]>([])
    const [chips, setChips] = useState<string[]>([]);
    const toast = useRef<Toast>(null);

    useEffect(() => {
        getDecisionTree({});
    }, []);

    useEffect(() => {
        if (pipelinePurposesError) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: pipelinePurposesError.response.data.error.cause });
        }
    }, [pipelinePurposesError]);

    useEffect(() => {
        if (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.error.cause });
        }
    }, [error]);

    useEffect(() => {
        if (reset) {
            setSelectedAssayType('All');
            setSelectedDownstreamAnalysis([]);
            setChips([]);
        }
    }, [reset]);

    useEffect(() => {
        if (decisionTreeData) {
            setAssayTypes(decisionTreeData.datatypeOptions);
        }
    }, [decisionTreeData]);

    useEffect(() => {
        if (pipelinePurposesData) {
            consoleWrap.log('pipelinePurposesData', pipelinePurposesData);
            setDownstreamAnalysis(pipelinePurposesData.options);
        }
    }, [pipelinePurposesData]);

    useEffect(() => {
        setChips(selectedDownstreamAnalysis)
    }, [selectedDownstreamAnalysis]);

    const onDataTypesChange = (e: DropdownChangeEvent) => {
        consoleWrap.log('e', e);
        getPipelinePurposes({ assayType: e.value });
        setSelectedAssayType(e.value);
    }

    const onDownstreamAnalysisChange = (e: DropdownChangeEvent) => {
        consoleWrap.log('onDownstreamAnalysisChange', e.value);
        setSelectedDownstreamAnalysis(e.value);
        onChange({ assayType: selectedAssayType, purposes: e.value})
    }


    const selectedItemsLabel = () => {
        const count = selectedDownstreamAnalysis.length;
        let result = '';
        if (count === 1) {
            result = `${selectedDownstreamAnalysis.length.toString()} item selected`
        }
        if (count > 1) {
            result = `${selectedDownstreamAnalysis.length.toString()} items selected`
        }
        return result;
    }

    const onRemoveChip = (label: string | undefined) => {
        // find and remove string from array and update state

        const index = selectedDownstreamAnalysis.findIndex((item) => item === label);
            const arrayCopy = [...selectedDownstreamAnalysis];
            if (index > -1) {
                arrayCopy.splice(index, 1);
                consoleWrap.log('arrayCopy', arrayCopy);
                setSelectedDownstreamAnalysis(arrayCopy);
                onChange({ assayType: selectedAssayType, purposes: arrayCopy })
            }

    }

    const chipTemplate = (item: ChipProps) => {
        return (
            <div className="flex w-full">
                <span className="flex-[1_1_90%] leading-6 mt-1.5 mb-1.5 shrink">{item.label}</span>
                <TimesCircleIcon className="w-5 h-5 grow mt-2 cursor-pointer" onClick={() => {
                    onRemoveChip(item.label);
                }} />
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className={`${isSideBarOpen ? 'w-full' : 'w-0'}  h-full flex-col justify-between px-5 py-5 overflow-y-auto`}>
                    <div className="text-sm mb-1 font-normal">Assay</div>
                    {isSideBarOpen && <Dropdown
                        filter
                        value={selectedAssayType}
                        onChange={onDataTypesChange}
                        options={assayTypes}
                        optionLabel="label"
                        placeholder="Select an assay type"

                        pt={{
                            root: {
                                className: 'w-full',
                            },
                            list: {
                                className: 'w-[200px]',
                            },
                            item: {
                                className: 'w-[270px]',
                            }
                        }}
                    />
                    }
                    {(downstreamAnalysis && downstreamAnalysis.length > 0) &&
                        <>
                            <div className="text-sm mb-1 font-normal mt-5">Downstream analysis</div>
                            <MultiSelect
                                filter
                                placeholder={'Select downstream analysis'}
                                value={selectedDownstreamAnalysis}
                                onChange={onDownstreamAnalysisChange}
                                options={downstreamAnalysis}
                                optionLabel="label"
                                display="comma"
                                selectedItemsLabel={selectedItemsLabel()}
                                maxSelectedLabels={0}
                            />
                            <div className="mt-2">
                                {
                                    chips.map((item, index) => {
                                        return <Chip key={index}  label={item} template={chipTemplate}  className="mb-2" />
                                    })
                                }
                            </div>
                        </>

                    }

            </div>
        </>
    )
}

const BottomBar = (props: {onFindPipeLinesClick: () => void, onClearAllClick: () => void}) => {
    const {onFindPipeLinesClick, onClearAllClick} = props;
    const {isSideBarOpen}: any = useContext(SideBarContext)
    return (
        <>
            <div className={`${isSideBarOpen ? 'w-full' : 'hidden'} h-[60px] flex items-center justify-between px-5 py-5 mt-auto border-t-2 border-t-gray-200 transition-opacity`}>
                <Button label="Clear all" onClick={onClearAllClick} outlined pt={{
                    root: { className: 'text-sm font-semibold cursor-pointer border-1 border-gray-200 h-8 py-0 px-0 items-center inline-flex' },
                    label: { className: 'text-sm text-ellipsis overflow-hidden whitespace-nowrap font-semibold' },
                }} />
                <Button label="Find pipelines" onClick={onFindPipeLinesClick} pt={{
                    root: { className: 'inline-flex items-center text-sm font-semibold bg-interactive text-colors px-3 text-white w-[130px] h-8 py-0' },
                    label: { className: 'text-white text-sm font-semibold text-ellipsis overflow-hidden whitespace-nowrap' },
                }} />

            </div>
        </>
    )
}

export const FiltersSideBar = (props: {setFilterSearchResult: (data: any) => void}) => {
    const {setFilterSearchResult} = props;
    const {isSideBarOpen}: any = useContext(SideBarContext)
    const {filterGraph, data, isLoading, error} = useFilterGraph();
    const [filterInput, setFilterInput] = useState<FilterGraphQueryParamType>({ assayType: 'All', purposes: [] })
    const [resetFilters, setResetFilters] = useState<boolean>(false);
    const dispatch = useDispatch();
    const toast = useRef<Toast>(null);
    const onFiltersChange = (data: { assayType: string, purposes: string[] }) => {
        const dataType = data.assayType;
        const purposes = data.purposes;
        setFilterInput({ assayType: dataType, purposes: purposes});
    }

    const onFindPipeLinesClick = () => {
        filterGraph(filterInput);
        dispatch(setSearchTerm(''));
    }

    const onClearAllClick = () => {
        setResetFilters(true);
        setFilterInput({ assayType: 'All', purposes: [] });
        setTimeout(() => {
            setResetFilters(false);
        });
    }

    useEffect(() => {
        if (data) {
            setFilterSearchResult(data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.error.cause });
        }
    }, [error]);

    return (
        <>
            <Toast ref={toast} />
            <div className={`${isSideBarOpen ? 'w-[320px] border-r-2 border-r-gray-200' : 'w-0'} h-screen flex flex-col transition-width duration-300 easy`}>
                <Header/>
                <FiltersContent onChange={onFiltersChange} reset={resetFilters}/>
                <BottomBar onFindPipeLinesClick={onFindPipeLinesClick} onClearAllClick={onClearAllClick} />
            </div>
        </>
    )
}