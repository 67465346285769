import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import React from "react";
import { Panel } from "primereact/panel";
import {Tooltip} from "primereact/tooltip";

interface IRepeatableInputPanelProps {
    name : string;
    headerLabel: string;
    onAddAnotherOption: () => void | undefined;
    children: React.ReactNode;
    dataTestId?: string;
    readOnly?: boolean;
}

export const RepeatableInputPanel = (props: IRepeatableInputPanelProps) => {
    const header = <>
        <Tooltip target=".assay-type-target-icon" style={{ boxShadow: 'none' }}/>
        <div className="flex-row gap-1 items-center flex">
            <p className="text-black-900 font-inter text-base font-semibold">{props.headerLabel}</p>
            {props.name === 'assayTypes' && (
                <i className="assay-type-target-icon pi pi-info-circle"
                   data-pr-tooltip="If you want to add another item to this list please email your request to admin@cobe.ca."
                   data-pr-position="right"
                   style={{ marginLeft: '2px', cursor: 'pointer' }}
                />
            )}
        </div>
    </>

    const onAddButtonClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        if (props.onAddAnotherOption) {
            props.onAddAnotherOption();
        }
    }

    const footer = <>
        <Button
            disabled={ props.readOnly }
            data-testid={props.dataTestId}
            label="Add another option"
            className="w-44"
            icon={ "pi pi-plus" }
            onClick={ onAddButtonClicked }
            pt={ {
                root: {
                    className: classNames(
                        // Button styles
                        "!text-black-900 !pl-2 !pr-3 !py-2 !bg-white rounded shadow border border-zinc-400 justify-center items-center gap-1 ",
                        // Text styles
                        "text-center text-neutral-800 text-sm font-semibold font-inter leading-none"
                    )

                }
            } }
        />
    </>
    return (
        <>
            <Panel
                header={ header }
                footer={ footer }
                className="w-full"
                pt={ {
                    header: { className: "!bg-white !border-grey-200" },
                    footer: { className: "!bg-white border-grey-200 border-t-0" },
                    content: { className: "last:rounded-br-none last:rounded-bl-none border-b-0 px-0 py-0" }
                } }
            >
                { props.children }
            </Panel>
        </>
    );
}
