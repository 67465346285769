import IMAGES from "../../assets/images/images.tsx";
import React, {useEffect, useRef, useState} from "react";
import {InputText, InputTextPassThroughMethodOptions} from "primereact/inputtext";
import {Password} from "primereact/password";
import {classNames} from "primereact/utils";
import {Button, ButtonPassThroughMethodOptions} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {Toast} from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { useKeycloakLogin } from "../../hooks/useKeycloakLogin.tsx";
import { Dialog } from "primereact/dialog";
import {consoleWrap} from "../../main.tsx";

const BASE_URL = import.meta.env.VITE_BASE_API_URL;


const socialButtonsPt = {
    root: ({ props, context }: ButtonPassThroughMethodOptions) => ({
        className: classNames('flex flex-row-reverse h-[36px]',
            {
                'px-0 !py-2 text-base': props.size === null,
                'border border-checkbox_border hover:bg-colors': props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
            }
        )
    }),
    label: ({ props, context }: ButtonPassThroughMethodOptions) => ({
        className: classNames(

            {
                'text-black-900 text-social_sign_in font-semibold': props.outlined,
            }
        )
    })
}

export const Login = () => {
    const [checked, setChecked] = useState<boolean>(false);
    const [username, setUsername]: [string, React.Dispatch<any>] = useState<any>();
    const [password, setPassword]: [string, React.Dispatch<any>] = useState<any>();
    const [usernameIsInvalid, setUsernameIsInvalid] = useState(false);
    const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);

    const [dialogVisible, setDialogVisible] = useState(false);
    const {keycloakLogin, data: keycloakLoginData, error, isLoading} = useKeycloakLogin();

    const loginErrorToast = useRef<Toast>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (keycloakLoginData) {
            // redirect to home page
            navigate('/');
        }
        if (error) {
            showInvalidLoginToast("Invalid username or password.");
        }

    }, [keycloakLoginData, error]);

    const googleLogin = () => {
        window.open(`${BASE_URL}/auth/google`, '_self');
    }

    const githubLogin = () => {
        window.open(`${BASE_URL}/auth/github`, '_self');
    }

    const handleUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setUsername(value);

        if (value === '') {
            setUsernameIsInvalid(true);
        } else {
            setUsernameIsInvalid(false);
        }
    };

    const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPassword(value);

        if (value === '') {
            setPasswordIsInvalid(true);
        } else {
            setPasswordIsInvalid(false);
        }
    };

    const showInvalidLoginToast =  (message: any) => {
        if(loginErrorToast && loginErrorToast.current) {
            loginErrorToast.current.show({
                severity: "error",
                summary: message,
                closable: true,
                sticky: true
            });
        }

    }

    const handleLogin = async (e: any) => {
        // need prevent default, otherwise the page will be reloaded
        e.preventDefault();
        if ((!username || username.length === 0) || (!password || password.length === 0)) {
            showInvalidLoginToast("Please enter your credentials to sign in.");
            return;
        }

        keycloakLogin(username, password);
    }

    const hideDialog = () => {
        setDialogVisible(false);
    };

    const showDialog = () => {
        setDialogVisible(true);
    };

    const handleForgotPassword = (e: any) => {
        e.preventDefault();
        consoleWrap.log('Forgot password');
        showDialog();
    }

    // @ts-ignore
    return (
        <>
            <Toast ref={loginErrorToast}></Toast>
            <Dialog
                header="Forgot Password"
                visible={dialogVisible}
                onHide={hideDialog}
                closable={true}
                footer={<Button label="OK" onClick={hideDialog} style={{background: '#2E72D2'}} />}>
                <p>CoBE leverages UHN credentials to authenticate users. If you are having issues signing in with your UHN credentials, please contact UHN HelpDesk.</p>
            </Dialog>
        <div
            className="flex flex-col items-center justify-center absolute inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"
        >
            <div className="w-[440px] bg-white border-gray-200 border-solid border rounded">
                <div id="innerContainer" className="p-10">
                    <img src={IMAGES.cobe_horizontal_logo} alt="Logo" />
                    <div className="text-sign_in font-semibold mt-10">Sign in</div>
                    <div className="text-sm font-normal text-gray-700 mt-2">Sign in using your UHN credentials (T-ID or email address).</div>
                    <div className="text-sm mt-5 mb-1">Username</div>
                    <InputText
                        id="username"
                        data-testid={'username'}
                        aria-describedby="username-help"
                        className="w-full h-10"
                        onChange={handleUsernameInputChange}
                        pt={{
                            root: ({ props, context }: InputTextPassThroughMethodOptions) => ({
                                className: classNames({
                                    'border-error': usernameIsInvalid,
                                }),
                            })
                        }}
                    />
                    {usernameIsInvalid && <small className="text-error">Fill out this field.</small>}
                    <div className="text-sm mt-5 mb-1">Password</div>
                    <Password
                        data-testid={'password'}
                        id="password" aria-describedby="password-help" feedback={false} pt={{
                        root: ({ props }: any) => ({
                            className: classNames('w-full h-10',
                                ),
                        }),
                        input: ({ props }: any) => ({
                            className: classNames('w-full h-10', {
                                'border-error': passwordIsInvalid,
                            }),
                        })
                    }}
                              onChange={handlePasswordInputChange}
                    />
                    {passwordIsInvalid && <small className="text-error">Fill out this field.</small>}
                    <div className="mt-1">
                        <a href="#" className="text-xs font-normal text-interactive" onClick={handleForgotPassword}>Forgot password?</a>
                    </div>
                    <div className="flex items-center my-5">
                        <Checkbox data-testid={'remember_me'} inputId="remember_me" name="remember_me" onChange={e => setChecked(e.checked!)}  checked={checked} />
                        <span className="ml-2 text-sm font-normal">Remember Me</span>
                    </div>
                    <Button data-testid={'login_signin'} label="Sign in" className="w-full h-10" pt={{
                        root: { className: 'inline-flex items-center text-sm font-semibold bg-interactive text-colors px-3 text-white h-8 py-0' },
                        label: { className: 'text-white text-sm font-semibold text-ellipsis overflow-hidden whitespace-nowrap' },
                    }} onClick={handleLogin} />
                    <div className="inline-flex items-center justify-center w-full">
                        <hr className="w-64 h-px my-6 bg-gray-200 border-0 dark:bg-gray-700"/>
                            <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
                    </div>
                    <div className="flex flex-col">
                        <Button data-testid={'google_signin'} label="Sign in with Google" outlined pt={socialButtonsPt} className="mb-2"  onClick={googleLogin}>
                            <img src={IMAGES.google_icon} className="w-5 h-5 cursor-pointer h-2rem" />
                        </Button>
                        {/*<Button data-testid={'github_signin'} label="Sign in with GitHub" outlined pt={socialButtonsPt}>*/}
                        {/*    <img src={IMAGES.github_icon} className="w-5 h-5 cursor-pointer" />*/}
                        {/*</Button>*/}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};
