import React from 'react';
import {Page, Text, View, Document, Link, Image} from '@react-pdf/renderer';
import { pdfStyles as styles } from './styles/pdfStyles.ts';
import cobeLogo from "../assets/images/cobe-horizontal-logo.png";

// Component to display each label and value pair
const ToolInfoViewLine = ({ label, value }: { label: string; value: any }) => (
    <View style={styles.content}>
        <Text>
            <Text style={{ fontWeight: 'bold' }}>{label}:</Text> {value}
        </Text>
    </View>
);

// Custom PDF document component for tool details
export const ToolPDF = ({ toolData, id }: { toolData: any, id: string }) => {
    // Capture the current URL inside ToolPDF
    const pipelineUrl = `${window.location.origin}/tool/${id}/`;

    const users = toolData?.user || [];

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Header Section */}
                <View style={styles.header}>
                    <View style={styles.titleSection}>
                        {/* Hyperlink the toolData.name to currentUrl */}
                        <Link href={pipelineUrl} style={styles.headerTitleLink}>
                            <Text style={styles.headerTitle}>
                                {toolData.name} {toolData.versions && toolData.versions.length > 0 ? `(${toolData.versions})` : ''}
                            </Text>
                        </Link>
                        <Text style={styles.labInfoText}>
                            {users.map((user: { name: string }) => user.name).join(", ") || 'Unknown Lab'}
                        </Text>
                    </View>
                    <Image src={cobeLogo} style={styles.logo} />
                    <View style={styles.linkContainer}>
                        {toolData.link && toolData.link.length > 0 ? (
                            toolData.link.map((link: string, index: number) => (
                                <Text key={index} style={styles.linkButton}>{link}</Text>
                            ))
                        ) : (
                            <Text style={styles.unavailableText}>No executable link available</Text>
                        )}
                    </View>
                </View>

                {/* Tool Details */}
                <View>
                    {toolData.keywords && toolData.keywords.length > 0 && (
                        <ToolInfoViewLine label="Keywords" value={toolData.keywords.join(', ')} />
                    )}
                    <ToolInfoViewLine
                        label="Purpose"
                        value={toolData.purposes.map((p: { text: string }) => p.text).join(', ') || 'Unavailable'}
                    />
                    <ToolInfoViewLine label="Input" value={toolData.input.join(', ') || 'Unavailable'} />
                    <ToolInfoViewLine label="Output" value={toolData.output.join(', ') || 'Unavailable'} />

                    {/* Executable Links Section */}
                    {toolData.purposes.map((purpose: { executableLinks: any[] }, index: React.Key) => (
                        <View style={styles.executableSection} key={index}>
                            <Text style={styles.executableLabel}>Executables:</Text>
                            {purpose.executableLinks && purpose.executableLinks.length > 0 ? (
                                purpose.executableLinks.map((link, i) => (
                                    <Text key={i} style={styles.listItem}>• {link.executableLink}</Text>
                                ))
                            ) : (
                                <Text style={styles.unavailableText}>Unavailable</Text>
                            )}
                        </View>
                    ))}

                    {/* Link and DOI with conditional styling */}
                    <ToolInfoViewLine
                        label="Link"
                        value={toolData.link ? <Text style={styles.linkText}>{toolData.link}</Text> : 'Unavailable'}
                    />
                    <ToolInfoViewLine
                        label="DOI"
                        value={toolData.doi ? <Text style={styles.linkText}>{toolData.doi}</Text> : 'Unavailable'}
                    />
                </View>
            </Page>
        </Document>
    );
};
