import { createSlice } from "@reduxjs/toolkit";
import { IToolTableDataResponse } from "../../hooks/useGetToolTableData.tsx";

export interface IToolsState {
    rows: IToolTableDataResponse[];
}

const initialState: IToolsState = {
    rows: [],
}

const toolsSlice = createSlice({
    name: "search",
    initialState: initialState,
    reducers: {
        setToolsData: (state, action) => {
            state.rows = action.payload;
        },
        addTool: (state, action) => {
            state.rows.push(action.payload);
        },
        deleteTool: (state, action) => {
            state.rows = state.rows.filter((row) => row.id !== action.payload);
        }
    },
});

export const { setToolsData, addTool, deleteTool } = toolsSlice.actions;

export default toolsSlice.reducer;
