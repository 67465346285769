import { ReactSVG } from "react-svg";
import { getFirstTwoLetters } from "./utils.ts";
import { IExecutableLink, IUser } from "../../hooks/useSearch.tsx";
import { useNavigate } from "react-router-dom";
import { CodeLinkSplitButton } from "../CodeLinkButton/CodeLinkSplitButton.tsx";
import { LikeButton } from "../LikeButton/LikeButton.tsx";
import { consoleWrap } from "../../main.tsx";
import { ToolDocLinkButton } from "../CodeLinkButton/ToolDocLinkButton.tsx";
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { UserAvatarGroup } from "../UserAvatarGroup/UserAvatarGroup.tsx";
import { Tooltip } from "primereact/tooltip";

const Initials = ({ initials }: { initials: string }) => {
    return (
        <div className="w-8 h-8 bg-deep_purple rounded-full flex items-center justify-center">
            <span className="text-xs text-white font-semibold">{initials}</span>
        </div>
    );
};

const ResultPipelineNames = ({ id, name, datatype, onResultClick, purposeList }: { id: string, name: string, datatype: string | string[], onResultClick: () => void, purposeList: any[] }) => {
    const displayDatatype = Array.isArray(datatype) ? datatype.join(', ') : datatype;
    return (
        <div className="h-12 w-44 flex flex-col items-start">
            {
                purposeList.length > 0 &&
                <Tooltip
                target={`.result-name-target-${id}`}
                style={{ boxShadow: 'none'}}
                content={ purposeList.map(p => p.text).join(', ') }
                pt={{
                    root: { className: "pl-3 max-w-3xl " },
                    arrow: { className: "pl-2"},
                    text: { className: "!whitespace-nowrap !overflow-hidden !text-ellipsis" }
                }}
            />
            }

            <div className={ `result-name-target-${id} text-pp_name font-semibold text-interactive cursor-pointer` } onClick={onResultClick}>{name}</div>
            <div className="text-sm font-normal mt-1 text-gray-700">{displayDatatype}</div>
        </div>
    );
};

const ResultPipelineAuthor = ({ initials, labName }: { initials: string, labName: string }) => {
    return (
        <div className="flex flex-col justify-center w-44">
            <div className="flex items-baseline">
                <Initials initials={initials} />
                <div className="pl-2 flex-auto flex flex-col items-start justify-center text-sm font-normal overflow-visible">
                    <span className="whitespace-normal break-words">
                        {labName}
                    </span>
                </div>
            </div>
        </div>
    );
};

const ResultPipelineRating = ({ likes, users, isUserLiked }: { likes: number, users: number, isUserLiked: boolean }) => {
    return (
        <div className="h-12 w-44 flex justify-start">
            <div className="flex items-center">
                <LikeButton
                    likeCount={likes}
                    isLiked={isUserLiked}
                    isClickable={false}
                />
            </div>
        </div>
    );
};

export interface ISearchResultProps {
    searchResultType: 'pipeline' | 'tool';
    name: string;
    datatype: string;
    labName: string;
    likes: number;
    isUserLiked: boolean;
    users: IUser[];
    url: string[];
    id: string;
    purposes?: any[];
    executableLinks: any;
}

export const SearchResult = ({
    id,
    searchResultType,
    name,
    datatype,
    labName,
    likes,
    isUserLiked,
    users,
    url,
    purposes,
    executableLinks
}: ISearchResultProps) => {
    const navigate = useNavigate();
    const initials = getFirstTwoLetters(labName);

    const goToPipelineDetail = () => {
        navigate(`pipeline/${id}`, { state: { purposes } });
    };

    const goToToolDetail = () => {
        navigate(`tool/${id}`);
    };

    /**
     * Formats user's names into a string list for the user avatar group
     * @param names - The list of names to format
     * @returns The formatted string
     */
    function formatNames(names: string[]): string {
        const totalNames = names.length;

        switch (totalNames) {
            case 0:
                return "";
            case 1:
                return names[0];
            case 2:
                return names.join(" & ");
            default:
                const remainingCount = totalNames - 2;
                return `${names[0]}, ${names[1]} & ${remainingCount} more`;
        }
    }

    return (
        <div className="h-22 w-full">
            <Card className="shadow-lg rounded-lg">
                <div className="flex flex-wrap justify-between items-stretch py-5">
                    <ResultPipelineNames
                        id={id}
                        name={name}
                        datatype={datatype}
                        onResultClick={() => {
                            searchResultType === 'pipeline' ? goToPipelineDetail() : goToToolDetail();
                        }}
                        purposeList={purposes ? purposes : []}
                    />
                    <div className="flex flex-row justify-center w-44">
                        <UserAvatarGroup users={users} className="ml-2" />
                        <div className="pl-2 flex-auto flex flex-col items-start justify-center text-sm font-normal overflow-visible">
                            <span className="whitespace-normal break-words">
                                {
                                    users && users.length > 0 ? formatNames(users.map(user => user.name)) : 'Unknown'
                                }

                            </span>

                        </div>

                    </div>

                    <ResultPipelineRating likes={+likes} users={+users} isUserLiked={isUserLiked} />
                    {searchResultType === 'pipeline'
                        ? <CodeLinkSplitButton executableLinks={executableLinks} />
                        : <ToolDocLinkButton urlArray={url} />}
                </div>
            </Card>
        </div>
    );
};
