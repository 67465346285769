import {useAxios} from "./useAxios.tsx";

export const useGetSearchSuggestions = () => {
    const { commonFetch, isLoading, data, error } = useAxios<any>({
        url: "/data/search/suggestions",
        method: "GET",
    });

    const getSearchSuggestions = async (queryParams: any) =>  await commonFetch({queryParameters: queryParams});

    return { getSearchSuggestions, isLoading, data, error };
}
