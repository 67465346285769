const IMAGES = {
    cobe_logo: new URL('cobe-logo-old.svg', import.meta.url).href,
    search_icon: new URL('search-icon.svg', import.meta.url).href,
    build_icon: new URL('build-icon.svg', import.meta.url).href,
    learn_icon: new URL('learn-icon.svg', import.meta.url).href,
    profile_icon: new URL('profile-icon.svg', import.meta.url).href,
    cobe_horizontal_logo: new URL('cobe-horizontal-logo.svg', import.meta.url).href,
    google_icon: new URL('google-icon.png', import.meta.url).href,
    github_icon: new URL('github-icon.svg', import.meta.url).href,
    login_icon: new URL('login-icon.svg', import.meta.url).href,
    logout_icon: new URL('logout-icon.svg', import.meta.url).href,
    codeocean_icon: new URL('codeocean-icon.svg', import.meta.url).href,
    database_icon: new URL('database-icon.svg', import.meta.url).href,
    progress_icon: new URL('progress-icon.svg', import.meta.url).href,
    tool_icon_lg: new URL('tool-icon-lg.svg', import.meta.url).href,
    left_arrow_icon: new URL('left-arrow-icon.svg', import.meta.url).href,
    right_panel_icon: new URL('right-panel-icon.svg', import.meta.url).href,
    download_icon: new URL('download-icon.svg', import.meta.url).href,
    backspace_icon: new URL('backspace.svg', import.meta.url).href,
    bar_chart_icon: new URL('bar-chart-icon.svg', import.meta.url).href,
    hpc_icon: new URL('hpc-icon.svg', import.meta.url).href,
    pipeline_icon: new URL('pipeline-icon.svg', import.meta.url).href,
    tool_icon_sm: new URL('tool-icon-sm.svg', import.meta.url).href,
    checkmark_icon: new URL('checkmark-icon.svg', import.meta.url).href,
    more_horizontal_icon: new URL('more-horizontal-icon.svg', import.meta.url).href,
    trash_bin_icon: new URL('trash-bin-icon.svg', import.meta.url).href,
    checkmark_circle_icon: new URL('checkmark-circle-icon.svg', import.meta.url).href,
    send_icon: new URL('send-icon.svg', import.meta.url).href,
    thumb_up_filled_icon: new URL('thumb-up-filled-icon.svg', import.meta.url).href,
    thumb_up_empty_icon: new URL('thumb-up-empty-icon.svg', import.meta.url).href,
    uhn_logo: new URL('uhn-logo.svg', import.meta.url).href,
}
export default IMAGES;
