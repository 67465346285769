import React from 'react';
import { ReactSVG } from "react-svg";
import exclamation from "../../assets/images/exclamation.svg";

interface ErrorFieldProps {
    errorMessage: string;
}

export const ErrorField: React.FC<ErrorFieldProps> = ({ errorMessage}) => {
    return (
        <small className="p-error text-red-500 flex items-center">
            <ReactSVG src={exclamation} className="mr-1"/>
            {errorMessage}
        </small>
    );
};
