import {SearchBar} from "../ExploreMainContent/SearchBar.tsx";
import {useSearch} from "../../hooks/useSearch.tsx";
import React, {useEffect} from "react";
import IMAGES from "../../assets/images/images.tsx";
import { ToolBarButtons } from "../ExploreMainContent/ToolBarButtons.tsx";

export type LandingPageProps = {
    onSearchClick: (value: string) => void;
}

export const LandingPage = (props: LandingPageProps) => {

    const {
        getSearch,
        isLoading: searchLoading,
        data: searchResponse,
        error: searchError
    } = useSearch();

    const search = (value: string) => {
        props.onSearchClick(value);
    }

    useEffect(() => {
        if (searchResponse) {
        }
    }, [searchResponse]);

   return (
    <div className="flex-auto bg-white h-screen flex flex-col justify-center items-center py-5">
        <div id="landing_page_logo_container" className="flex flex-row">
            <span className="font-semibold text-welcome_landing_page mr-3">Welcome to</span> <img src={IMAGES.cobe_horizontal_logo} alt="Logo"/>
        </div>
        <div className="mt-5 mb-10 font-normal text-base">Search for tools and pipelines based on your analysis needs</div>
        <SearchBar onSearchClick={search} widthTailwindClass={"w-1/2"} />
        <ToolBarButtons clasName={'flex py-2'} />
    </div>
);
}
