import { IExecutableLink } from "../../../models/IExecutableLink.ts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToolNodeData } from "../../../hooks/pipeline-builder/useGetNodeForTool.ts";
import { DraftStatus } from "../../../models/Builder/DraftStatusEnum.ts";

export type DraftInstitution = {
    name: string,
    link: string
}

export interface IDraftPipelineInfo {
    name: string,
    // In new COBE, assay types on the front end fill the datatype field in the backend
    datatype: string[],
    // Did not exist in old cobe
    assayTypes: string[],
    version: string,
    // This was as string in old COBE
    link: string[],
    // This was just a single user in old COBE
    user: DraftInstitution[],
    main: {
        executableLinks: IExecutableLink[],
        nodes: MainDraftNode[]
    },
    // TODO: add type
    subBranches: any[],
    purposes: string[],
    doi: string,
}

export type GraphNodeInfoExecutableLink = IExecutableLink & { _id: string };

type GraphNodeInfo = {} | GraphToolNodeInfo;

export type GraphToolNodeInfo = {
    version: string,
    purpose: string,
    keywords: string[],
    input: string[],
    output: string[],
    score: number,
    // This was originally `link: string;` in the old code
    link: string[],
    doi: string,
    executableLinks: GraphNodeInfoExecutableLink[],
    institution: DraftInstitution[]
}

export type MainDraftNode = MainDraftBaseNode | MainDraftToolNode | MainDraftUnpersistedToolNode;
export type GraphDraftNode = GraphDraftBaseNode | GraphDraftToolNode | GraphDraftUnpersistedToolNode;

export type MainDraftBaseNode = {
    // Database UUID of the node (ex: "5f9b3b7b7b7...")
    // Null if the node is not yet saved to the database
    _id: string | null,
    // Type of the node (ex: "origin")
    type: string,
    // Name of the node (ex: "Genomics Data")
    name: string,
    nodetype: string,
    id: string,
    __v: number,
    class?: string
}

export type GraphDraftBaseNode = {
    _id: string,
    nodetype: string,
    id: string,
    name: string,
    type: string,
    __v: number,
    class?: string,
    info: GraphNodeInfo
}

export type MainDraftToolNode = {
    _id: string,
    nodetype: string,
    id: string,
    type: string,
    name: string,
    toolId: string,
    version: string | null,
    purpose: string,
    __v: number,
    input: string[],
    output: string[],
}

export type GraphDraftToolNode = {
    _id: string,
    nodetype: string,
    id: string,
    type: string,
    name: string,
    toolId: string,
    version: string | null,
    purpose: string,
    __v: number,
    info: GraphToolNodeInfo,
    class?: string,
}

export type MainDraftUnpersistedToolNode = {
    id: string,
    type: string,
    name: string,
    toolId: string,
    version: string,
    purpose: string,
    input: string[],
    output: string[],
}

export type GraphDraftUnpersistedToolNode = {
    id: string,
    type: string,
    name: string,
    toolId: string,
    version: string,
    purpose: string,
    info: GraphToolNodeInfo,
    class?: string,
}

export interface IDraftGraphLink {
    // Source node ID from graph.nodes[x].id (ex: "node_1")
    source: string,
    // Target node ID from graph.nodes[x].id (ex: "node_2")
    target: string,
}

export type IDraftGraphTool = Omit<IToolNodeData, "associatedTools">;

export interface IDraftGraph {
    nodes: GraphDraftNode[],
    links: IDraftGraphLink[],
    // This is the tool property of the response from the endpoint api/view/data/tool
    tools: IDraftGraphTool[]
}


export interface BuilderSaveDraftState {
    pipeline: IDraftPipelineInfo,
    graph: IDraftGraph,
    _id: string | null,
    status: DraftStatus,
    submittedBy: string
}

export const initialBuilderSaveDraftState: BuilderSaveDraftState = {
    pipeline: {
        name: "",
        datatype: [],
        assayTypes: [],
        version: "",
        doi: "",
        link: [],
        user: [{
            name: "",
            link: ""
        }],
        main: {
            executableLinks: [],
            nodes: []
        },
        subBranches: [],
        purposes: [],
    },
    graph: {
        nodes: [],
        links: [],
        tools: []
    },
    _id: null,
    status: DraftStatus.Draft,
    submittedBy: ""
}

const BuilderSaveDraftSlice = createSlice({
    name: "builderSaveDraft",
    initialState: initialBuilderSaveDraftState,
    reducers: {
        setBuilderSaveDraft: (state, action: PayloadAction<BuilderSaveDraftState>) => {
            return action.payload;
        },
        resetBuilderSaveDraft: (state) => {
            return initialBuilderSaveDraftState;
        },
        /**
         * Set the draft pipeline info. Does NOT set the nodes property in main. This is typically used when the pipeline info is saved in the right panel.
         * @param state - Current state
         * @param action - Payload action containing the draft pipeline info
         */
        setPipelineInfoInSaveDraft: (state, action: PayloadAction<IDraftPipelineInfo>) => {
            state.pipeline = {
                ...action.payload,
                main: {
                    ...action.payload.main,
                    nodes: state.pipeline.main.nodes
                }
            };
        },

        /**
         * Upsert a tool node into the main nodes of the pipeline info draft.
         * @param state
         * @param action
         */
        upsertToolNodeIntoMainDraft: (state, action: PayloadAction<{
            oldNodeId: string,
            node: MainDraftToolNode | MainDraftUnpersistedToolNode
        }>) => {

            // Check if the node is already in the pipeline info main. If it is, update it.
            const existingMainNodeIndex = state.pipeline.main.nodes.findIndex(node => node.id === action.payload.oldNodeId);
            if (existingMainNodeIndex !== -1) {
                state.pipeline.main.nodes[existingMainNodeIndex] = action.payload.node;
            } else {
                // If the node is not already in the pipeline info main, add it.
                state.pipeline.main.nodes.push(action.payload.node);
            }
        },

        /**
         * Upsert a tool node into the graph nodes of draft.
         * @param state
         * @param action
         */
        upsertToolNodeIntoGraphDraft: (state, action: PayloadAction<{
            oldNodeId: string,
            node: GraphDraftToolNode | GraphDraftUnpersistedToolNode,
            link: IDraftGraphLink
        }>) => {
            // Check if the node is already in the graph. If it is, update it.
            const existingMainNodeIndex = state.graph.nodes.findIndex(node => node.id === action.payload.oldNodeId);
            if (existingMainNodeIndex !== -1) {
                state.graph.nodes[existingMainNodeIndex] = action.payload.node;
                state.graph.links = state.graph.links.map(link => {
                    if (link.source === action.payload.oldNodeId) {
                        return {
                            ...link,
                            source: action.payload.node.id
                        }
                    } else if (link.target === action.payload.oldNodeId) {
                        return {
                            ...link,
                            target: action.payload.node.id
                        }
                    }
                    return link;
                });
            } else {
                // If the node is not already in the pipeline info main, add it.
                state.graph.nodes.push(action.payload.node);
                state.graph.links.push(action.payload.link);
            }
        },

        /**
         * Add a tool to the draft. This is typically used when a tool is added to the graph.
         * @param state - Current state
         * @param action - Payload action containing the tool to add. This should be the response from api/view/tool?toolId=...
         */
        addToolToDraft: (state, action: PayloadAction<IDraftGraphTool>) => {
            // Check if the tool in the payload is already in the tools array. If it is, update it.
            const existingToolIndex = state.graph.tools.findIndex(tool => tool._id === action.payload._id);
            if (existingToolIndex !== -1) {
                state.graph.tools[existingToolIndex] = action.payload;
            } else {
                // If the tool is not already in the tools array, add it.
                state.graph.tools.push(action.payload);
            }
        },

        /**
         * Add an assay type node to the draft. Automatically adds an edge from the root node to it.
         * @param state - Current state
         * @param action - Payload action containing the tool to add. This should be the response from api/view/tool?toolId=...
         */
        addAssayTypeToDraft: (state, action: PayloadAction<{
            mainNode: MainDraftBaseNode,
            graphNode: GraphDraftBaseNode
        }>) => {
            state.pipeline.main.nodes.push(action.payload.mainNode);
            state.graph.nodes.push(action.payload.graphNode);
            const newEdge: IDraftGraphLink = {
                // Node 0 should be the root node
                source: state.pipeline.main.nodes[0].id,
                target: action.payload.graphNode.id
            };
            state.graph.links.push(newEdge);
            state.pipeline.assayTypes.push(action.payload.mainNode.name);
        },

        /**
         * Delete nodes and their edges from the draft.
         * This function is designed to be used in conjunction with the flow context service delete function.
         * Deletes from
         *  - graph.nodes
         *  - pipeline.main.nodes
         *  - graph.links
         *  - graph.tools
         * @param state
         * @param action - Payload action containing the ids of the nodes to delete
         */
        deleteNodes(state, action: PayloadAction<{ idsToDelete: string[] }>) {
            const deletedNodes = state.graph.nodes.filter((node: any) => action.payload.idsToDelete.includes(node.id));
            const updatedStateGraphNodes = state.graph.nodes.filter((node: any) => !action.payload.idsToDelete.includes(node.id));
            const updatedStateMainNodes = state.pipeline.main.nodes.filter((node: any) => !action.payload.idsToDelete.includes(node.id));
            const updatedStateEdges = state.graph.links.filter((edge: any) => !action.payload.idsToDelete.includes(edge.source) && !action.payload.idsToDelete.includes(edge.target));
            // Delete only first instance of each tool in the tool list
            const updatedTools = state.graph.tools.filter((tool) => {
                return !deletedNodes.some((node) => node.name === tool.name)
            });
            state.graph.nodes = updatedStateGraphNodes;
            state.pipeline.main.nodes = updatedStateMainNodes;
            state.graph.links = updatedStateEdges;
            state.graph.tools = updatedTools;
        },

        /**
         * Delete an assay type from the pipeline info.
         * @param state
         * @param action
         */
        deleteAssayType(state, action: PayloadAction<{ assayType: string }>) {
            state.pipeline.assayTypes = state.pipeline.assayTypes.filter((assayType) => assayType !== action.payload.assayType);
        },

        /**
         * Replace an assay type in the pipeline info.
         * @param state
         * @param action - Payload action containing the old assay type name and new assay type node structure
         */
        replaceAssayType(state, action: PayloadAction<{ oldAssayType: string, newAssayType: MainDraftBaseNode }>) {
            if (!action.payload.newAssayType._id) {
                return state;
            }
            // Need to add this as Typescript thinks that newAssayType._id can be null otherwise
            const newAssayTypeId = action.payload.newAssayType._id;

            // Find the index of the oldAssayType in the main nodes
            const oldAssayNodeMainIndex = state.pipeline.main.nodes.findIndex((node) => node.name === action.payload.oldAssayType);

            // Find the index and ID of the oldAssayType in the graph nodes
            const oldAssayNodeGraphIndex = state.graph.nodes.findIndex((node) => node.name === action.payload.oldAssayType);
            const oldAssayNodeGraphId = state.graph.nodes[oldAssayNodeGraphIndex].id;

            // If the oldAssayType is not found in either the main or graph nodes, there is a problem so return the unchanged state
            if (oldAssayNodeMainIndex === -1 || oldAssayNodeGraphIndex === -1) {
                return state;
            }

            // Replace the node in both the main and graph nodes lists
            state.pipeline.main.nodes[oldAssayNodeMainIndex] = action.payload.newAssayType;
            state.graph.nodes[oldAssayNodeGraphIndex] = {
                ...action.payload.newAssayType,
                _id: newAssayTypeId,
                info: {}
            };

            // Replace all IDs in edges matching the oldAssayType with the newAssayType
            state.graph.links = state.graph.links.map((edge) => {
                if (edge.source === oldAssayNodeGraphId) {
                    return {
                        ...edge,
                        source: action.payload.newAssayType.id
                    }
                } else if (edge.target === oldAssayNodeGraphId) {
                    return {
                        ...edge,
                        target: action.payload.newAssayType.id
                    }
                }
                return edge;
            });

            // Find the old assay name in the assayTypes array and replace it with the new assay name
            const assayTypeIndex = state.pipeline.assayTypes.findIndex((assayType) => assayType === action.payload.oldAssayType);
            if (assayTypeIndex !== -1) {
                state.pipeline.assayTypes[assayTypeIndex] = action.payload.newAssayType.name;
            }
        },

        /**
         * Update the status of the draft.
         * @param state
         * @param action
         */
        updateStatus(state, action: PayloadAction<DraftStatus>) {
            state.status = action.payload;
        }
    }
})

export const {
    setBuilderSaveDraft,
    resetBuilderSaveDraft,
    setPipelineInfoInSaveDraft,
    addToolToDraft,
    upsertToolNodeIntoMainDraft,
    upsertToolNodeIntoGraphDraft,
    deleteNodes,
    deleteAssayType,
    updateStatus,
    replaceAssayType,
    addAssayTypeToDraft
} = BuilderSaveDraftSlice.actions;

export default BuilderSaveDraftSlice.reducer;
