import {
    TabPanelPassThroughOptions,
    TabPanelProps,
    TabViewPassThroughMethodOptions,
    TabViewPassThroughOptions,
} from "primereact/tabview";
import {classNames} from "primereact/utils";

export const tabview: TabViewPassThroughOptions = {
    root: ({props, state}: any) => ({
        className: 'flex flex-row',
    }),
    panelContainer: ({props, state}: any) => ({
        className: 'w-full pl-[40px]'
    }),
    navContainer: ({props, state}: any) => ({
        className: 'w-[200px]',
    }),
    navContent: ({props, state}: TabViewPassThroughMethodOptions) => {
        return {
            className: classNames('px-2'),
        }
    },
    nav: ({props, state}: any) => ({
        className: classNames('flex flex-col gap-y-3'),
    }),
};

export const tabpanel: TabPanelPassThroughOptions = {
    // root: ({props, state}: any) => ({
    //     className: 'flex flex-row',
    // }),
    // content: ({props, parent}: {props: TabPanelProps, parent: TabViewPassThroughMethodOptions}) => {
    //     return {
    //         className: 'flex flex-col',
    //     }
    // },
    headerTitle: {
        className: classNames('p-2 rounded-md hover:bg-gray-200')
    },
    header: {
       className: classNames('mx-2')
    },
    headerAction: {
        className: classNames('py-3 font-bold hover:text-gray-700 hover:text-gray-700')
    },
};
