import React from "react";

interface ModalFieldLabelProps {
    label: string;
    required?: boolean;
}

export const ModalFieldLabel: React.FC<ModalFieldLabelProps> = ({ label, required }) => {
    return (
        <div className="flex flex-row gap-1">
            <div className="self-stretch text-black-900 text-sm font-normal font-inter leading-tight">
                {label}
            </div>
            { required && <div className="text-red-500 text-sm font-normal font-inter leading-tight">*</div> }
        </div>
    );
};
