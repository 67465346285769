import { useAxios } from "../useAxios.tsx";
import { MainDraftBaseNode } from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import {doRefreshToken} from "./../useRefreshToken.tsx";

export type IGetAssayTypeData = {
    assayType: MainDraftBaseNode;
}

export type IGetAssayTypeAxiosResponse = {
    data: IGetAssayTypeData;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetAssayType = () => {
    const { commonFetch, isLoading, data, error }: IGetAssayTypeAxiosResponse = useAxios<any>({
        url: "/view/pipeline-builder/assay-type",
        method: "GET",
    });

    const getAssayType = async (name: string) => {
        await doRefreshToken();  // Ensure the token is refreshed
        await commonFetch({ queryParameters: { name } });
    }
    return { getAssayType, isLoading, data, error };
}
