import {useCurrentPath} from "../../hooks/useCurrentPath.tsx";
import {ReactSVG} from "react-svg";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import {css} from "glamor";
import React, {useEffect, useRef} from "react";
import IMAGES from "../../assets/images/images.tsx";
import {useNavigate} from "react-router-dom";
import {useGetUserProfile} from "../../hooks/useGetUserProfile.tsx";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";
import {useDispatch, useSelector} from "react-redux";
import {logout as logoutAction} from "../../store/slices/authSlice.ts";
import {IRootState} from "../../store/store.ts";
import {consoleWrap} from "../../main.tsx";
import { doRefreshToken } from "../../hooks/useRefreshToken.tsx";
import { useSubmitFeedback } from "../../hooks/useSubmitFeedback.tsx";


const BLUE = '#2463BC';
const BLACK = '#000000';

const SEARCH_PATH = '/';
const BUILD_PATH = '/build';
const LEARN_PATH = '/learn';
const ADMIN_PATH = '/admin';
const PROFILE_PATH = '/profile';

const getStyles = (currentPath: string, path: string) => {
    return css({
        ' path': {
            fill: `${currentPath === path ? BLUE : BLACK}`,
        }
    });
}



export const VerticalNavBar = () => {
    const isLoggedIn = useSelector((state: IRootState) => state.auth.isLoggedIn)
    const path = useCurrentPath();
    const navigate = useNavigate();

    const {
        getUserProfile,
        isLoading: userProfileIsLoading,
        data: userProfileData,
        error: userProfileError} = useGetUserProfile();
    consoleWrap.log('path', path);

	const {
		feedbackMessage,
		setFeedbackMessage,
		isSubmitting,
		sendFormFeedback,
		confirmationVisible,
		setConfirmationVisible,
		viewModal,
		setViewModal
	} = useSubmitFeedback();

    useEffect(() => {
        if (isLoggedIn) {
            doRefreshToken().then(() => {
                getUserProfile();
            }).catch(e => {
                console.error("Failed to refresh token or fetch user profile", e);
            });
        }
    }, [isLoggedIn]);

    const dispatch = useDispatch();

    const toast = useRef<Toast>(null);

    const searchStyles = getStyles(path, SEARCH_PATH)

    const buildStyles = getStyles(path, BUILD_PATH)

    const learnStyles = getStyles(path, LEARN_PATH)

    const adminStyles = getStyles(path, ADMIN_PATH)

    const profileStyles = getStyles(path, PROFILE_PATH)

    const getUserProfileClick = () => {
        getUserProfile();
    }

    consoleWrap.log("userProfileData " + JSON.stringify(userProfileData?.user?.admin))

    const logout = () => {
        localStorage.removeItem('cobe-accessToken');
        toast.current?.show({severity: 'success', summary: 'Success', detail: 'Logged out successfully'});
        dispatch(logoutAction());
        navigate('/');
    }

	const modalFooter = (
		<div
			className="w-full h-full bg-white justify-end items-center gap-3 inline-flex">
			<Button
				data-testid={'cancelBtn'}
				className="px-3 !py-2 !bg-white !text-gray-800 text-headingSm font-semibold font-inter rounded shadow border border-zinc-400 justify-center items-center gap-2.5 flex"
				onClick={() => 
					{
						setViewModal(false);
						setFeedbackMessage("");
					} 
				}>
				Cancel
			</Button>
			<Button
				data-testid={'send-request-review'}
				className="!py-2 !bg-blue-700 text-white text-headingSm font-semibold font-inter rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex"
				onClick={() => sendFormFeedback(userProfileData?.user?.email || "")}
			>
				Send
			</Button>
		</div>
	);

	const modalHeader = (
		
		<div className="w-full">
			<span>
				Send Feedback to CoBE
			</span>
		</div>
	);

	const closeConfirmationModal = () => {
		setConfirmationVisible(false);
		setFeedbackMessage("");
	};
	

    return (
        <div className="w-[88px] h-screen bg-white flex flex-col items-center py-5 border-r-2 border-r-gray-200">
            <Toast ref={toast}></Toast>
            {!isLoggedIn &&  <Tooltip target=".build-icon, .send-feedback" style={{ boxShadow: 'none' }} />}


            <ul className="flex flex-col w-full h-full justify-between">
                <li className="flex flex-col items-center w-full mb-8">
                    <img src={IMAGES.cobe_logo} alt="Logo" />
                </li>
                <div className="flex flex-col items-center w-full mb-auto pt-5">
                    <li className="flex flex-col items-center w-full mb-5" onClick={() => navigate('/')}>
                        <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === SEARCH_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                <ReactSVG src={IMAGES.search_icon} {...searchStyles} />
                        </div>
                        <span className={`text-vertical_nav font-semibold ${path === SEARCH_PATH ? 'text-interactive' : 'text-gray-800'}`}>Explore</span>
                    </li>
					<li	data-pr-tooltip="Please login to use this feature"
						data-pr-position="right"
						data-pr-at="right center"
						data-pr-my="left center"
						onClick={() => navigate(BUILD_PATH)}
						className="flex flex-col items-center w-full mb-5 build-icon"
					>
						<div
                            className={` mb-1 inline-flex justify-center items-center w-[56px] ${path === BUILD_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                            <ReactSVG src={IMAGES.build_icon}  {...buildStyles} />
                        </div>
                        <span className={`text-vertical_nav text-headingSm  font-semibold ${path === BUILD_PATH ? 'text-interactive' : 'text-gray-800'}`}>Build</span>

					</li>
                    <li className="flex flex-col items-center w-full mb-5" onClick={() => navigate(LEARN_PATH)}>
                        <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === LEARN_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                            <ReactSVG src={IMAGES.learn_icon} {...learnStyles} />
                        </div>
                        <span className={`text-vertical_nav text-headingSm  font-semibold ${path === LEARN_PATH ? 'text-interactive' : 'text-gray-800'}`}>Learn</span>
                    </li>
                    {isLoggedIn && userProfileData?.user?.admin && (
                        <li className="flex flex-col items-center w-full mb-5" onClick={() => navigate(ADMIN_PATH)}> {/* Add the admin icon */}
                            <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === ADMIN_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                <ReactSVG src={IMAGES.profile_icon} {...adminStyles} />  {/* Use the admin icon */}
                            </div>
                            <span className={`text-vertical_nav text-headingSm  font-semibold ${path === ADMIN_PATH ? 'text-interactive' : 'text-gray-800'}`}>Admin</span>
                        </li>
                    )}
                </div>
                {
                    isLoggedIn ? (
                        <>
							<li 
								onClick={() => setViewModal(true)}
								className="flex flex-col items-center w-full mb-5 font-normal send-feedback"
								data-pr-tooltip="Please login to use send us feedback"
								data-pr-position="right"
							>
								<span className={`text-vertical_nav text-center text-feedback`}>Send</span>
								<span className={`text-vertical_nav text-center text-feedback`}>Feedback</span>
							</li>
                            <li data-testid="logoutBtn" className="flex flex-col items-center w-full mb-5" onClick={logout}>
                                <div className={`mb-1 inline-flex justify-center items-center w-[56px] hover:bg-selected hover:rounded-full`}>
                                    <ReactSVG src={IMAGES.logout_icon} {...profileStyles} />
                                </div>
                                <span className={`text-vertical_nav text-headingSm  font-semibold ${path === PROFILE_PATH ? 'text-interactive' : 'text-gray-800'}`}>Logout</span>
                            </li>
                            <li className="flex flex-col items-center w-full" onClick={getUserProfileClick}>
                                <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === PROFILE_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                    <ReactSVG src={IMAGES.profile_icon} {...profileStyles} />
                                </div>
                                <span className={`text-vertical_nav text-headingSm font-semibold ${path === PROFILE_PATH ? 'text-interactive' : 'text-gray-800'}`}>Profile</span>
                            </li>
                        </>
                    ) : (
						<>
							<li 
								className="flex flex-col items-center w-full mb-5 font-normal send-feedback"
								data-pr-tooltip="Please login to send us feedback"
								data-pr-position="right"
							>
                        		<span className={`text-vertical_nav text-center text-gray-400`}>Send</span>
								<span className={`text-vertical_nav text-center text-gray-400`}>Feedback</span>
                    		</li>

							<li data-testid="loginBtn" className="flex flex-col items-center w-full" onClick={() => navigate('/login')}>
								<div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === PROFILE_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
									<ReactSVG src={IMAGES.login_icon} {...profileStyles} />
								</div>
								<span className={`text-vertical_nav text-headingSm font-semibold  ${path === PROFILE_PATH ? 'text-interactive' : 'text-gray-800'}`}>Login</span>
							</li>
						</>

                    )

                }

            </ul>

			<Dialog
				header={modalHeader}
				visible={viewModal}
				onHide={() => {
					setViewModal(false);
					setFeedbackMessage("");
				}}
				footer={modalFooter}
				style={{ width: "600px", height: "300px"}}
				className="border-b-2 border-zinc-400"
			>
				<div>
					<div className="flex-col justify-start items-start gap-1 flex">
						<label htmlFor="message" className="text-black-900 text-bodyMd">Use the text box below to report issues or bugs, to request new data or assay types, to suggest new features, or to seek general CoBE support</label>
						<InputTextarea
							autoResize
							id="message"
							name="message"
							value={feedbackMessage}
							placeholder="Tell us about what prompted this feedback..."
							onChange={(e) => setFeedbackMessage(e.target.value)}
							rows={5}
							maxLength={500}
							className="w-full px-3 py-2.5 mb-3 bg-white rounded border border-zinc-400"
						/>
						<div className="text-right text-gray-600 text-sm">
                			{feedbackMessage.length} / 500
            			</div>
					</div>
				</div>
			</Dialog>

			<Dialog
				header={modalHeader}
				visible={confirmationVisible}
				onHide={closeConfirmationModal}
				footer={<div></div>}
				style={{ width: "600px"}}
				className="border-b-2 border-zinc-400"
			>
				<div className="text-center flex flex-col justify-center items-center gap-4 h-full">
					<p className="text-headingLg font-semibold font-inter text-black-900">Feedback sent!</p>
					<p className="text-bodySm font-inter text-black-900 ">
						We use feedback like yours to help fix issues and improve CoBE.
					</p>
					<Button
						data-testid="closeBtn"
						className="!py-2 !bg-blue-700 text-white text-headingSm font-semibold font-inter rounded shadow border border-blue-700"
						onClick={ ()=> {
							setConfirmationVisible(false);
							setFeedbackMessage("");
						}}
					>
						Close
					</Button>
				</div>
			</Dialog>

        </div>
    );
}

