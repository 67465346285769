import { useAxios } from "./useAxios.tsx";
import { doRefreshToken } from "./useRefreshToken.tsx";

export interface IToolTableDataResponse {
    id: string;
    name: string;
    input: string[];
    output: string[];
    dateSubmitted: Date;
    editedOn: Date;
}

export interface IGetToolTableDataResponse {
    data: IToolTableDataResponse[] | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetToolTableData = () => {
    const { commonFetch, isLoading, data, error }: IGetToolTableDataResponse = useAxios<any>({
        url: '/data/tools/tool-builder-table',
        method: "GET",
    });

    const getToolTableData = async () => {
        await doRefreshToken();
        await commonFetch({});
    }

    return { getToolTableData, isLoading, data, error };
}
