import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import React from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Field, FieldProps, FormikErrors } from "formik";
import { SelectItemOptionsType } from "primereact/selectitem";
import { ErrorField } from "../../ErrorField/ErrorField.tsx";
import {classNames} from "primereact/utils";
import { useRef } from "react";

type FormProps = {
    // Dropdown options
    dropdownOptions: SelectItemOptionsType;
    text: string;
    onSelectionChange?: (oldValue: string, newValue: string) => void;
}

type IAssayTypeFormInputProps = RepeatableInputComponentProps & FormProps;

/**
 * RepeatableInputFormGroup is a component that renders a dropdown input field for assay types.
 * It allows the user to select or enter a process data type.
 * The component also includes a delete button for removing the input field.
 *
 * @component
 * @param {object} props - Properties passed to the component
 * @param {string} props.name - The name of the form field
 * @param {number} props.index - The index of the form field in the form array
 * @param {function} props.onDelete - Function to call when the delete button is clicked
 * @param {SelectItemOptionsType} props.dropdownOptions - The options for the dropdown
 *
 * @example
 * <RepeatableInputFormGroup
 *   name="assayTypes"
 *   index={0}
 *   onDelete={handleDelete}
 *   dropdownOptions={processDataTypeOptions}
 * />
 *
 * @returns The RepeatableInputFormGroup component
 */
export const RepeatableInputFormGroup = (props: IAssayTypeFormInputProps) => {

    const formikInputName = `${props.name}[${props.index}]`;
    const dropdownRef = useRef<Dropdown | null>(null);

    /**
     * onDropdownChange is a function that is called when the dropdown value changes.
     * @param e - The dropdown change event from `onChange`
     * @param fieldProps - The field props from Formik
     */
    const onDropdownChange = (e: DropdownChangeEvent, fieldProps: FieldProps) => {
        // Get the old value before changing it
        const oldVal: string = fieldProps.form.values[props.name][props.index];
        // Set the form value
        fieldProps.form.setFieldValue(formikInputName, e.value);
        // Call the on change function
        if (props.onSelectionChange) {
            props.onSelectionChange(oldVal, e.value);
        }
    };

    // Function to block manual typing
    const preventTyping = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
    };

    // Determine if typing should be blocked based on the field name
    const shouldPreventTyping = props.name === 'assayTypes' || props.name === 'processDatatypes'; // Prevent typing only for "assayTypes"

    return (
        <div className="w-full shrink border-b py-4 px-5 justify-center items-end gap-4">
            <div className="flex w-full flex-col gap-1">
                <div className="w-full flex items-start gap-0.5">
                    <div className="text-black-900 text-sm font-normal font-inter leading-tight">
                        {props.text}
                    </div>
                    {props.text !== "Lab Group/Institution" && (
                        <div className="text-red-500 text-sm font-normal font-inter leading-tight pl-0.5">*</div>
                    )}
                </div>
                <div className="flex w-full justify-between flex-grow flex-row items-center gap-4">
                    <div className="flex-grow">
                        <Field name={formikInputName}>
                            {(fieldProps: FieldProps) => {
                                const errors = fieldProps.form.errors as FormikErrors<{ assayTypes: string[] }>;
                                const showError = fieldProps.form.submitCount > 0 &&
                                    errors.assayTypes &&
                                    errors.assayTypes[props.index];
                                return (
                                    <>
                                        <div className="flex flex-row">
                                            <Dropdown
                                                ref={dropdownRef} // Attach ref to Dropdown
                                                disabled={props.readOnly} // Handle read-only state
                                                editable={true} // Keep dropdown editable
                                                value={fieldProps.field.value} // Bind selected value
                                                options={props.dropdownOptions} // Dropdown options
                                                placeholder="Select"
                                                onChange={(e) => onDropdownChange(e, fieldProps)} // Handle selection
                                                onFocus={() => {
                                                    if (shouldPreventTyping) {
                                                        dropdownRef.current?.show(); // Open dropdown on focus if typing is prevented
                                                    }
                                                }}
                                                onKeyDown={shouldPreventTyping ? preventTyping : undefined} // Conditionally prevent typing
                                                className={`h-10 w-full rounded border ${showError ? "!border-red-500" : "border-zinc-400"}`}
                                                pt={{
                                                    trigger: {
                                                        className: classNames('absolute inset-y-0 right-0', 'bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg')
                                                    },
                                                }}
                                            />
                                            <div id="trashIcon">
                                                {!props.readOnly && props.index !== 0 && (
                                                    <ReactSVG
                                                        src={IMAGES.trash_bin_icon}
                                                        onClick={() => props.onDelete(props.index)}
                                                        className="p-2 rounded-full justify-start items-start gap-2.5 flex transition duration-300 ease-in-out hover:bg-zinc-100 hover:cursor-pointer"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {showError && (
                                            <div className="mt-1">
                                                <ErrorField errorMessage={"Assay Type is required"} />
                                            </div>
                                        )}
                                    </>
                                );
                            }}
                        </Field>
                    </div>
                </div>
            </div>
        </div>
    );
};
