import { ConfirmDialog } from "primereact/confirmdialog";
import React from "react";

export function DeleteConfirmDialog() {
    return <ConfirmDialog
        pt={ {
        root: {
            className: "!w-[360px]"
        },
        header: {
            className: "!px-5 !pt-5 !p-0 !pb-0.5 items-center flex",
        },
        headerTitle: {
            className: "text-black-900 text-sm font-semibold tracking-wide",
        },
        content: {
            className: "w-full !p-3 !pl-1 !pr-5 !pt-1 inline-flex text-delete_confirmation_msg font-normal",
        },
        footer: {
            className: "flex flex-row-reverse gap-2 !px-5 !pb-5 pt-0",
        },
        acceptButton: {
            root: {
                className: "text-delete_confirmation_msg inline-flex items-center justify-center text-white font-semibold bg-red-500 border border-red-500 hover:bg-red-600 hover:border-red-600 h-8"
            }
        },
        rejectButton: {
            root: {
                className: "text-delete_confirmation_msg inline-flex items-center justify-center text-gray-500 font-semibold border border-gray-500 hover:bg-gray-300/20 h-8"
            }
        }
    } }
    />;
}
