import React, { Dispatch, SetStateAction } from "react";
import { Node } from "reactflow"

export type SelectedNode = Node | null;

export interface IBuilderPipelineSelectedNodeContext {
    selectedNode: SelectedNode | null;
    setSelectedNode: Dispatch<SetStateAction<Node<any, string | undefined> | null>> ;
}

const defaultSetNodes: Dispatch<SetStateAction<Node<any, string | undefined> | null>> = () => {};

/**
 * Context for the selected node in the pipeline builder.
 */
export const BuilderPipelineSelectedNodeContext = React.createContext<IBuilderPipelineSelectedNodeContext>({
    selectedNode: null,
    setSelectedNode: defaultSetNodes
});
