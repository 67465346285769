export const EmptyState = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center w-full h-full px-[60px]">
                <div className="w-[400px] flex flex-col items-center justify-center">
                    <div className="text-xl font-bold">No tools or pipelines found</div>
                    <div className="text-sm mt-5 w-[380px] text-center">Try searching by name, assay type, purpose, or by using the filter panel.</div>
                </div>
            </div>
        </>
    )
}
