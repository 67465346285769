import React, { useEffect, useState } from "react";
import { PipelineFlowViewer } from "../../components/ReactFlow/PipelineFlowViewer.tsx";
import { Location, useLocation } from "react-router-dom";
import {
    PipelineExpandedDiagramSideBar
} from "../../components/PipelineExpandedDiagramPage/SideBar/PipelineExpandedDiagramSideBar.tsx";
import {
    PipelineExpandedDiagramTopBar
} from "../../components/PipelineExpandedDiagramPage/PipelineExpandedDiagramTopBar.tsx";
import { IFormattedData } from "../../models/IFormattedData.ts";
import { Node } from "reactflow";
import { ToolNodeData } from "../../components/ReactFlow/CustomNodes/ToolNode.tsx";
import { animationDuration } from "../../cobe-design-system/prime-react-sidebar.ts";


export interface PipelineExpandedDiagramLocationState {
    formattedData: IFormattedData;
    openInLink: string;
}

export const PipelineExpandedDiagramPage = () => {

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [currentSelectedNode, setCurrentSelectedNode] = useState<Node | null>(null);

    const location: Location<PipelineExpandedDiagramLocationState> = useLocation();

    useEffect(() => {
        // If currentSelectedNode is null, then the sidebar should show all details in location.state.formattedData.
        // Otherwise, it should show the details of the currentSelectedNode
        if (currentSelectedNode) {
            setSidebarVisible(true);
        } else {
            setSidebarVisible(false);
        }
    }, [currentSelectedNode]);

    // If the sidebar is closing, wait for the animation to finish before setting the currentSelectedNode to null
    // This makes sure that when the sidebar is next opened, it shows the details of the entire pipeline
    useEffect(() => {
        if (!sidebarVisible) {
            // Use a timeout so that the data doesnt change before the animation stops.
            setTimeout(() => {
                setCurrentSelectedNode(null);
            }, animationDuration.ms);
        }
    }, [sidebarVisible]);

    const onNodeClicked = (event: MouseEvent, node: Node) => {
        if (node.type !== "baseNode") {
            setCurrentSelectedNode(node);
        }
    }

    /**
     * Returns a copy of the formattedData with only the selected node
     * This function searches the purposes, subbranches and main nodes of the pipeline to find the selected node
     * If the node is not found, null is returned
     *
     * @param flowNode - The node to find in the formattedData
     * @returns A copy of the formattedData with only the selected node
     */
    const getFormattedNode = (flowNode: Node<ToolNodeData>): IFormattedData | null => {
        if (location.state && location.state.formattedData) {
            // Find the node in the graph
            const formattedNode = location.state.formattedData.graph.nodes.find((n) => n.id === flowNode.data.id);
            if (!formattedNode) {
                return null;
            }
            // Find the node in the pipeline main nodes
            const graphNode = location.state.formattedData.pipelines[0].main.nodes.find((n) => n._id === flowNode.data._id);
            if (graphNode) {
                // Create a copy of the formattedData for manipulation. This MUST by a deep copy to avoid altering the original data.
                const newFormattedData: IFormattedData = JSON.parse(JSON.stringify(location.state.formattedData));;
                // If they exist, set the graph and pipeline main nodes to be the only nodes in the graph
                if (graphNode) {
                    newFormattedData.graph.nodes = [formattedNode];
                    newFormattedData.graph.links = [];
                    newFormattedData.pipelines[0].main.nodes = [graphNode];
                    newFormattedData.pipelines[0].subBranches = [];
                    newFormattedData.pipelines[0].purposes = [];
                }
                return newFormattedData;
            } else {
                // Find the node in the pipeline's purpose and subbranch lists
                const purposeNodeIndex = location.state.formattedData.pipelines[0].purposes.findIndex((p) => {
                    return p.nodes.find(n => n._id === flowNode.data._id) !== undefined
                });
                const purposeNode = location.state.formattedData.pipelines[0].purposes[purposeNodeIndex];
                const subBranchNodeIndex = location.state.formattedData.pipelines[0].subBranches.findIndex((p) => {
                    return p.nodes.find((n: any) => n._id === flowNode.data._id) !== undefined
                });
                const subBranchNode = location.state.formattedData.pipelines[0].subBranches[subBranchNodeIndex];

                // Create a copy of the formattedData for manipulation. This MUST by a deep copy to avoid altering the original data.
                const newFormattedData: IFormattedData = JSON.parse(JSON.stringify(location.state.formattedData));

                // Empty irrelevant node lists
                newFormattedData.graph.nodes = [formattedNode];
                newFormattedData.graph.links = [];
                newFormattedData.pipelines[0].main.nodes = [];

                // If the node was found in the purposes or subbranches, set the relevant node list to be the only nodes in the graph
                newFormattedData.pipelines[0].purposes = purposeNode ? [purposeNode] : [];
                newFormattedData.pipelines[0].subBranches = subBranchNode ? [subBranchNode] : [];


                return newFormattedData;
            }



        }
        return null;
    }

    return (
        <div className="w-full h-screen flex flex-col items-stretch">
            <PipelineExpandedDiagramTopBar
                setSideBarVisible={ () => setSidebarVisible(true) }
                pipelineName={ location.state.formattedData.pipelines[0].name }/>
            {
                location.state ?
                    <PipelineFlowViewer
                        formattedData={ location.state.formattedData }
                        isExpandable={ false }
                        openInLink={ location.state.openInLink ? location.state.openInLink : "" }
                        onNodeClicked={ onNodeClicked }
                        selectedNode={ currentSelectedNode }
                    />
                    : "Missing Pipeline Information"
            }
            <PipelineExpandedDiagramSideBar
                isVisible={ sidebarVisible }
                onHide={ setSidebarVisible }
                pipelineNodeDetails={
                    currentSelectedNode ? getFormattedNode(currentSelectedNode) : ( location.state && location.state.formattedData ? location.state.formattedData : null )
                }
                dataForPDF = {location.state.formattedData}
                // location.state && location.state.formattedData ? location.state.formattedData : null}
                openInLink={ location.state && location.state.openInLink ? location.state.openInLink : "" }
            />


        </div>
    );
};
