import {DividerPassThroughOptions, DividerProps} from "primereact/divider";
import {classNames} from "primereact/utils";

export const divider: DividerPassThroughOptions = {
    // @ts-ignore
    root: ({ props }: DividerProps) => ({
        className: classNames(
            'flex relative', // alignments.
            {
                'w-full my-2 mx-0 py-0 px-2 before:block before:left-0 before:absolute before:top-1/2 before:w-full before:border-t before:border-gray-300 before:dark:border-blue-900/40':
                    props.layout == 'horizontal', // Padding and borders for horizontal layout.
                'min-h-full mx-4 md:mx-5 py-5 before:block before:min-h-full before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2 before:border-l before:border-gray-300 before:dark:border-blue-900/40':
                    props.layout == 'vertical' // Padding and borders for vertical layout.
            },
            {
                'before:border-solid': props.type == 'solid',
                'before:border-dotted': props.type == 'dotted',
                'before:border-dashed': props.type == 'dashed'
            } // Border type condition.
        )
    }),
    content: {
        className: classNames(
          'px-1 bg-white z-10 dark:bg-gray-900'
        )
    },
}
