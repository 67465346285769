import { useAxios } from "./useAxios.tsx";

export type PipelinePurposeData = {
    options: { value: string; label: string; }[];
}

type GetPipelinePurposesResponse = {
    data: PipelinePurposeData | null,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetPipelinePurposes = () => {
    const { commonFetch, isLoading, data, error }: GetPipelinePurposesResponse = useAxios<PipelinePurposeData>({
        url: "data/pipeline/purposes",
        method: "GET",
    });

    const getPipelinePurposes = async (queryParams: { assayType: string, keyword?: string }) => await commonFetch({ queryParameters: queryParams });

    return { getPipelinePurposes, isLoading, data, error };
};
