import { ReactSVG } from "react-svg";
import IMAGES from "../../../../assets/images/images.tsx";
import { NodeProps } from "reactflow";
import React from "react";
import { PlusHandle } from "../PlusHandle.tsx";
import { NodeType } from "../../../../models/Builder/NodeTypeEnum.ts";

export enum RootBuilderNodeIds {
    OUTPUT_HANDLE_ID = "output",
    NODE_ID = "root-node",
    TYPE = "rootBuilderNode"
}

export interface RootBuilderNodeProps {
    label: string;
    isSelected: boolean;
    nodeType: NodeType;
    cobeData: any;
}

export const RootBuilderNode = (props: NodeProps<RootBuilderNodeProps>) => {

    return (
        <>
            {/* -m-[6px] is used because the border of this div, and the one nested directly below it offset the position of the node.
                So we need to offset the margin by the same total amount to keep it in the same position. border-8 + border-2 = 10px */ }
            <div
                className={ props.data.isSelected ? `-m-[6px] rounded-4xl border-4 border-blue-700 border-opacity-0 bg-blue-700 bg-opacity-20 overflow-clip` : "" }>
                <div
                    className={ props.data.isSelected ? `self-stretch rounded-4xl border-2 border-blue-700 bg-blue-700` : "" }>
                    <div
                        className="w-52 p-3 bg-white rounded-4xl shadow border border-gray-200 justify-start items-center gap-2 inline-flex">
                        <div
                            className={ `bg-accent_green p-1.5 rounded-4xl border border-black border-opacity-20 justify-center items-center gap-2.5 flex` }>
                            <div className="w-6 h-6 relative">
                                <div className="w-6 h-6 left-0 top-0 absolute ">
                                    <ReactSVG src={ IMAGES.database_icon }/>
                                </div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                            <div
                                className="w-full text-neutral-800 text-sm font-semibold font-inter leading-none">{ props.data.label }
                            </div>
                            <div className="self-stretch text-neutral-500 text-xs font-normal font-inter leading-4">
                                Data Type
                            </div>
                        </div>

                        <PlusHandle
                            className={ 'invisible' }
                            handleId={ RootBuilderNodeIds.OUTPUT_HANDLE_ID }
                            onClick={ () => {
                            } }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
