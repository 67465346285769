import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import React from "react";
import { IFormattedData } from "../../../models/IFormattedData.ts";
import { CodeLinkSplitButton } from "../../CodeLinkButton/CodeLinkSplitButton.tsx";
import { IExecutableLink } from "../../../hooks/useSearch.ts";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PipelinePDF } from "../../../pdf/PipelinePDF.tsx";
import {Button} from "primereact/button";
import {useParams} from "react-router-dom";  // Make sure this path is correct

interface PipelineExpandedDiagramSideBarProps {
    executableLinks: IExecutableLink[] | null;
    openInLink: string;
    formattedData: IFormattedData;   // Add this prop for PDF export
}

const passThroughProps = {
    root: {
        className: 'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600\': props.severity === \'info\' && !props.text && !props.outlined && !props.plain'
    },

};

export const Footer = (props: PipelineExpandedDiagramSideBarProps) => {
    const { openInLink, executableLinks, formattedData } = props;

    const { id } = useParams();

    return (
        <div className="h-14 pt-3 border-t border-gray-200 justify-between items-center inline-flex">
            {/* Left side code button */}
            <CodeLinkSplitButton executableLinks={executableLinks} />

            {/* PDF Export button */}
            <PDFDownloadLink
                document={<PipelinePDF formattedData={formattedData} id={id!} />}
                fileName={`${formattedData?.pipelines[0]?.name || 'pipeline'}.pdf`}
            >
                {({ loading }) =>
                    loading ? (
                        <Button pt={passThroughProps} className="h-[32px] !py-0 bg-pipeline_diagram_view_button text-white rounded shadow border border-pipeline_diagram_view_button" label="Generating PDF..." />
                    ) : (
                        <Button pt={passThroughProps} className="h-[32px] !py-0 bg-pipeline_diagram_view_button text-white rounded shadow border border-pipeline_diagram_view_button" label="Export" />
                    )
                }
            </PDFDownloadLink>
        </div>
    );
};
