import { Edge, MarkerType, Node } from "reactflow";
import {
    AddLeafDecisionNodeCoordinateOffsets,
    addLeafDecisionNodeHeight,
    AddLeafDecisionNodeIds,
    AddLeafDecisionNodeProps
} from "../../components/Builder/Flow/Nodes/AddLeafDecisionNode.tsx";
import { RootBuilderNodeIds, RootBuilderNodeProps } from "../../components/Builder/Flow/Nodes/RootBuilderNode.tsx";
import { BuilderAssayNodeData, BuilderAssayNodeIds } from "../../components/Builder/Flow/Nodes/BuilderAssayNode.tsx";
import { BuilderToolNodeData, BuilderToolNodeIds } from "../../components/Builder/Flow/Nodes/BuilderToolNode.tsx";
import { NodeType } from "../../models/Builder/NodeTypeEnum.ts";
import {
    GraphDraftBaseNode,
    GraphDraftNode,
    GraphDraftToolNode,
    IDraftGraph
} from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import { consoleWrap } from "../../main.tsx";


/**
 * Generates a list of nodes and edges for the given assay types.
 * The edges connect the root node to the assay nodes.
 * @param assayTypeNodes - The list of assay types to generate nodes for.
 * @param onNewNodeHandleClicked - The function to call when a new node handle is clicked.
 * @param rootNodeId - The id of the root node.
 * @returns {[nodes: Node[], edges: Edge[]]}
 */
export const generateNodesAndEdgesForAssayTypes = (assayTypeNodes: GraphDraftNode[], rootNodeId: string): [nodes: Node[], edges: Edge[]] => {
    // Initialize empty arrays for new nodes and edges
    let newNodeList: Node[] = [];
    let newEdgeList: Edge[] = [];

    // Iterate over each assay type node
    assayTypeNodes.forEach((assayType, index) => {
        // Get the node ID
        const nodeId = assayType.id;

        // Create a new node with the ID, position, data, and type
        const newNode: Node<BuilderAssayNodeData> = {
            id: nodeId,
            position: { x: 0, y: 0 },
            data: {
                label: assayType.name,
                byline: "Assay Type",
                isSelected: false,
            },
            type: BuilderAssayNodeIds.NODE_TYPE
        };

        // Create a new edge connecting the root node to the new node
        const newEdge: Edge = {
            id: `${ rootNodeId }-${ nodeId }`,
            source: rootNodeId,
            target: nodeId,
            markerEnd: { type: MarkerType.Arrow }
        };

        // Add the new node and edge to their respective arrays
        newNodeList.push(newNode);
        newEdgeList.push(newEdge);
    });

    // Return the arrays of new nodes and edges
    return [newNodeList, newEdgeList];
}

/**
 * Returns a copy of the decision node.
 * The data in this node is missing the onNewToolClicked and onNewPipelineClicked functions.
 * This node gets a default position of 0, 0.
 */
export const generateEmptyDecisionNode = (): Node<AddLeafDecisionNodeProps> => {
    const newNode: Node<AddLeafDecisionNodeProps> = {
        id: AddLeafDecisionNodeIds.NODE_ID,
        position: { x: 0, y: 0 },
        data: {
            onNewToolClicked: () => {
            },
            onNewPipelineClicked: () => {
            },
        },
        type: AddLeafDecisionNodeIds.NODE_TYPE
    };
    return newNode;
}

/**
 * Returns an empty root node. The data in this node is missing the onHandleClicked and onNodeClicked functions.
 * @returns Node<RootBuilderNodeProps>
 **/
export const generateRootNode = (cobeData: GraphDraftNode) => {
    const initialNode: Node<RootBuilderNodeProps> = {
        id: cobeData.id,
        type: RootBuilderNodeIds.TYPE,
        position: { x: 0, y: 0 },
        data: {
            label: cobeData.name,
            isSelected: false,
            nodeType: NodeType.ORIGIN,
            cobeData
        }
    };
    return initialNode;
}

/**
 * Generates a new Assay Type node for the given assay type.
 * Note: the onHandleClicked function is set to an empty function. It should be filled where appropriate.
 * @param nodeId
 * @param label
 */
export const generateAssayTypeNode = (nodeId: string, label: string) => {
    const newNode: Node<BuilderAssayNodeData> = {
        id: nodeId,
        position: { x: 0, y: 0 },
        data: {
            label,
            byline: "Assay Type",
            isSelected: false,
        },
        type: BuilderAssayNodeIds.NODE_TYPE
    };
    return newNode;
}

/**
 * Returns a copy of the nodes array without the add leaf node.
 * @param nodes - The array of nodes to filter.
 */
export const getNodesWithoutAddLeafNode = (nodes: Node[]) => {
    const newNodeList = nodes.filter(node => node.id !== AddLeafDecisionNodeIds.NODE_ID);
    return newNodeList;
}

export interface IFilteredAddLeafEdgeResult {
    addLeafEdge: Edge | null;
    newEdgeList: Edge[];
}

/**
 * Returns the edges without the edge that connects the source node to the decision node.
 * Also returns the edge that connects the source node to the decision node.
 * @returns {{addLeafEdge: Edge, newEdgeList: Edge[]}}
 * @param edges
 */
export const getEdgesWithoutAddLeafEdge = (edges: Edge[]): IFilteredAddLeafEdgeResult => {
    const addLeafEdgeIndex = edges.findIndex(edge => edge.id === AddLeafDecisionNodeIds.EDGE_ID);
    if (addLeafEdgeIndex === -1) return { addLeafEdge: null, newEdgeList: [...edges] };
    const addLeafEdge = edges[addLeafEdgeIndex];
    const newEdgeList = edges.slice(0, addLeafEdgeIndex).concat(edges.slice(addLeafEdgeIndex + 1));
    return { addLeafEdge, newEdgeList };
}

/**
 * Generates the edge that connects the source node to the decision node
 *
 * @param sourceNodeId - The id of the node that is the source of the connection
 * @param decisionNode - The decision node that is the target of the connection
 * @param sourceHandleId - The id of the handle that is the source of the connection
 */
export const generateDecisionNodeEdge = (sourceNodeId: string, decisionNode: Node<AddLeafDecisionNodeProps>, sourceHandleId: string) => {
    const newEdge: Edge = {
        id: AddLeafDecisionNodeIds.EDGE_ID,
        source: sourceNodeId,
        target: decisionNode.id,
        sourceHandle: sourceHandleId,
        targetHandle: AddLeafDecisionNodeIds.NODE_INPUT_HANDLE_ID,
        hidden: true,
        markerEnd: {
            type: MarkerType.Arrow
        }
    };
    return newEdge;
}

/**
 * Calculates the position of the decision node relative to the source node such that it is centered vertically and to the right of the source node.
 * @param sourceNode - The node that is the source of the connection
 */
export const calculateDecisionNodePosition = (sourceNode: Node): { xPos: number, yPos: number } => {
    const xPos = sourceNode.position.x + AddLeafDecisionNodeCoordinateOffsets.X;

    if (!sourceNode.height) {
        return { xPos, yPos: sourceNode.position.y };
    }
    // Calculate the position of the decision node relative to the source node such that it is centered vertically
    const yPos = sourceNode.position.y + ( sourceNode.height / 2 ) - ( addLeafDecisionNodeHeight / 2 ) + AddLeafDecisionNodeCoordinateOffsets.Y;
    return { xPos, yPos };
}

/**
 * Generate the assay type nodes and edges for the react-flow graph.
 * @param graphState - The draft graph state.
 * @returns The assay type nodes and edges for the react-flow graph.
 */
export const getFlowAssayTypes = (graphState: IDraftGraph, rootNodeId: string) => {
    const dataTypeNodes = graphState.nodes.filter(node => node.type === "datatype");
    const nodeAndEdgeLists = generateNodesAndEdgesForAssayTypes(
        dataTypeNodes,
        rootNodeId
    );
    return nodeAndEdgeLists;
}

/**
 * Generate the react-flow root node.
 * @param graphState - The draft graph state.
 * @param onNewNodeHandleClicked - The function to call when a new node handle is clicked.
 * @param onNodeClicked - The function to call when a node is clicked.
 * @returns The root node for the react-flow graph.
 */
export const getFlowRootNode = (graphState: IDraftGraph) => {
    // Find the genomics node in the draft data
    const genomicsNodeCobe = graphState.nodes.find(node => node.type === "origin");

    // If the genomics node was not found, log an error and return. This should never happen if the draft was loaded from backend correctly.
    if (!genomicsNodeCobe) {
        consoleWrap.error("Genomics node not found in the draft slice.");
        return null;
    }
    // Generate a root node component using the origin node data
    const rootNode = generateRootNode(genomicsNodeCobe);
    // Set the onHandleClicked function to connect the new node to the output handle
    return rootNode
}

/**
 * Returns true if the node is a persisted node. Used to check if a GraphDraftNode is a GraphDraftBaseNode or GraphDraftToolNode.
 * @param node - The node to check.
 */
const graphNodeIsPersisted = (node: any): node is GraphDraftBaseNode | GraphDraftToolNode => {
    return 'nodetype' in node;
}

/**
 * Returns true if the node is a base node. Used to check if a GraphDraftNode is a GraphDraftBaseNode.
 * @param node - The node to check.
 */
const graphNodeIsBaseNode = (node: any): node is GraphDraftBaseNode => {
    return 'nodetype' in node && node.nodetype === "BaseNode";
}

export const getFlowToolNodes = (graphState: IDraftGraph) => {
    // Filter out non-tool nodes
    const toolNodes = graphState.nodes.filter(node => {
        // Need to check if the node is persisted or not first. This is because unpersisted nodes do not have a nodetype property.
        // If the node is not persisted, return true to include it in the list, as only tool nodes can be in this state.
        return !graphNodeIsBaseNode(node);
    });
    const newNodes = toolNodes.map((toolNode) => {
        if (graphNodeIsBaseNode(toolNode)) {
            consoleWrap.error("A base node was found while generating tool nodes.");
            const blankNode: Node = { id: "", position: { x: 0, y: 0 }, data: {}, type: "" };
            return blankNode;
        }

        const node: Node<BuilderToolNodeData> = {
            id: toolNode.id,
            position: { x: 0, y: 0 },
            data: {
                label: toolNode.name,
                byline: toolNode.info.executableLinks.length ? toolNode.info.executableLinks[0].linkType : "",
                isSelected: false,
                toolData: {
                    toolName: toolNode.name,
                    toolPurposeDropdown: toolNode.purpose,
                    inputFileTypeDropdown: toolNode.info.input[0],
                    outputFileTypeDropdown: toolNode.info.output[0],
                    version: toolNode.version,
                    executableLinks: toolNode.info.executableLinks,
                    toolDocumentation: toolNode.info.link,
                    doi: toolNode.info.doi,
                    labGroups: toolNode.info.institution.map((institution) => institution.name),
                    label: toolNode.name,
                    byline: toolNode.info.executableLinks.length ? toolNode.info.executableLinks[0].linkType : "",
                }
            },
            type: BuilderToolNodeIds.NODE_TYPE
        }
        return node;
    });

    return newNodes;
}

export const getFlowEdgesFromState = (graphState: IDraftGraph) => {
    const newEdges = graphState.links.map((link) => {
        const newEdge: Edge = {
            id: link.source + "-" + link.target,
            source: link.source,
            target: link.target,
            markerEnd: { type: MarkerType.Arrow }
        };
        return newEdge;
    });

    return newEdges;
}
