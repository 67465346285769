import { useAxios } from "./useAxios.tsx";
import { DocumentLikeDataType } from "../models/DocumentLikeDataType.ts";
import {doRefreshToken} from "./useRefreshToken.tsx";

type SetToolLikeResponse = {
    data: DocumentLikeDataType | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}
``
export const useToggleToolLike = (toolId: string) => {
    const { commonFetch, isLoading, data, error }: SetToolLikeResponse = useAxios<DocumentLikeDataType | null>({
        url: `/data/like/executable_likes/tool/${ toolId }`,
        method: "POST",
    });

    const postToggleToolLike = async () => {
        await doRefreshToken();  // Ensure the token is refreshed
        await commonFetch({});
    }
    return { postToggleToolLike, isLoading, data, error }
}
