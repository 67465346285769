export const formatDateTime = (dateString: Date): string => {
    if (!dateString) return "N/A"; // Handling null or undefined input
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return "Invalid date"; // Handling invalid date strings
    }
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', '');
};
