import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Field, FieldProps } from "formik";
import { SelectItemOptionsType } from "primereact/selectitem";
import {InputText} from "primereact/inputtext";
import {ErrorField} from "../../ErrorField/ErrorField.tsx";

interface ToolDocumentationFormInput extends RepeatableInputComponentProps {
    required?: boolean; // Optional prop to indicate if the field is required
}
export const ToolDocumentationFormInput = (props: ToolDocumentationFormInput) => {

    const formikInputName = `${ props.name }[${ props.index }]`;

    return (
        <div className="w-full shrink h-24 border-b py-4 px-5 justify-center items-end gap-4">
            <div className="flex w-full flex-col gap-1">
                <div className="w-full flex items-start gap-0.5">
                    {/* Conditionally display asterisk if required */}
                    <label className="text-black-900 text-sm font-normal font-inter leading-tight">
                        Documentation Link {props.required && <span className="text-red-500">*</span>}
                    </label>
                </div>
                <div className="flex w-full justify-between flex-grow flex-row items-center gap-4">
                    <Field name={formikInputName}>
                        {({ field, form: { submitCount }, meta }: FieldProps) => {
                            const showError = submitCount > 0 && meta.error;

                            return (
                                <div className="flex flex-col w-full gap-0.5">
                                    <InputText
                                        {...field}
                                        disabled={props.readOnly}
                                        className={`h-8 w-full px-2 py-1 text-sm border rounded ${
                                            showError ? "border-red-500" : "border-gray-300"
                                        }`}
                                    />
                                    {/* Render error message if showError is true and add margin-top for spacing */}
                                    {showError && meta.error && (
                                        <div className="mt-1 mb-4">
                                            <ErrorField errorMessage={meta.error || ""}/>
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    </Field>

                    <div className="w-10 flex-shrink-0">
                        {
                            !props.readOnly && props.index !== 0 &&
                            <ReactSVG
                                src={ IMAGES.trash_bin_icon }
                                onClick={ () => props.onDelete(props.index) }
                                className="p-2 rounded-full justify-start items-start gap-2.5 flex transition duration-300 ease-in-out hover:bg-zinc-100 hover:cursor-pointer"
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
