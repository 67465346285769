import {CheckboxPassThroughOptions} from "primereact/checkbox";
import {classNames} from "primereact/utils";

export const checkbox: CheckboxPassThroughOptions = {
    root: {
        className: classNames('cursor-pointer inline-flex relative select-none items-center', 'w-5 h-6')
    },
    input: ({ props, context }: any) => ({
        className: classNames(
            'flex items-center justify-center',
            'border border-checkbox_border border-solid w-5 h-5 text-black-900 transition-colors duration-200',
            {
                'border border-checkbox_border bg-white dark:border-blue-900/40 dark:bg-gray-900': !context.checked,
                'border border-checkbox_border bg-interactive dark:border-blue-400 dark:bg-blue-400': context.checked
            },
            {
                'border hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !props.disabled,
                'border cursor-default opacity-60': props.disabled
            }
        )
    }),
    icon: ({ props, context }: any) => ({
        className: classNames('w-4 h-4 transition-all duration-200 text-white font-bold dark:text-gray-900'),
    }),
}
