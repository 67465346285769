import {
    BuilderPipelineSelectedNodeContext,
    IBuilderPipelineSelectedNodeContext
} from "../../store/context/PipelineBuilder/builder-pipeline-selected-node-context.ts";
import React from "react";
import { BuilderFlowContext, IBuilderFlowContext } from "../../store/context/PipelineBuilder/builder-flow-context.ts";

export interface IBuilderContextValues {
    builderSelectedNodeContextValue: IBuilderPipelineSelectedNodeContext;
    builderFlowContextValue: IBuilderFlowContext;
    children: React.ReactNode;
}

/**
 * BuilderContext
 * This is a component that provides all context for the builder.
 * Data is split into multiple contexts in order to keep the data organized and to prevent unnecessary re-renders.
 * Each context is logically separated and is used to store different types of data, thus if one context changes, only the components that use that context will re-render.
 * If additional context is needed, it can be added here.
 */
export const BuilderContext = (props: IBuilderContextValues) => {
    return (
        <>
            <BuilderPipelineSelectedNodeContext.Provider value={ props.builderSelectedNodeContextValue }>
                <BuilderFlowContext.Provider value={ props.builderFlowContextValue }>
                        { props.children }
                </BuilderFlowContext.Provider>
            </BuilderPipelineSelectedNodeContext.Provider>
        </>
    );
};
