import { Edge, Node } from "reactflow";
import { graphlib } from "dagre-d3-es";
import { layout } from "dagre-d3-es/src/dagre";
import { GraphDirection } from "./graph-direction.ts";

export interface IGeneralLayoutConfig {
    graphDirection: GraphDirection;
    rankSeparationDistance: number;
}

export const generalLayoutNodes = (nodes: Node[], edges: Edge[], layoutConfig: IGeneralLayoutConfig) => {
    // Create a new graph instance
    const treeGraph: graphlib.Graph = new graphlib.Graph();
    treeGraph.setGraph({
        rankdir: layoutConfig.graphDirection,
        ranksep: layoutConfig.rankSeparationDistance,
        // // edgesep: 10,
        // nodesep: 10,
        // ranker: 'longest-path',
    });
    treeGraph.setDefaultEdgeLabel(() => ( {} ));

    // Add nodes to the graph. The name is the node id. The second parameter is metadata about the node.
    // In this case we're going to add labels to each of our nodes.
    nodes.forEach(node => {
        treeGraph.setNode(node.id, { width: 288, height: 64 });
    });

    // Add edges to the graph.
    edges.forEach(edge => {
        treeGraph.setEdge(edge.source, edge.target);
    });


    layout(treeGraph, {});

    treeGraph.nodes().forEach(nodeId => {
        const nodeInfo = treeGraph.node(nodeId);
        const node = nodes.find(n => n.id === nodeId);
        if (node) {
            node.position = { x: nodeInfo.x - nodeInfo.width / 2, y: nodeInfo.y - nodeInfo.height / 2 };
        }
    });

    return nodes;
};

export const builderLayoutNodes = (nodes: Node[], edges: Edge[], graphDirection: GraphDirection) => {
    // Create a new graph instance
    const treeGraph: graphlib.Graph = new graphlib.Graph();
    treeGraph.setGraph({
        rankdir: graphDirection,
        ranksep: 120,
        // // edgesep: 10,
        // nodesep: 10,
        // ranker: 'longest-path',
    });
    treeGraph.setDefaultEdgeLabel(() => ( {} ));

    // Add nodes to the graph. The name is the node id. The second parameter is metadata about the node.
    // In this case we're going to add labels to each of our nodes.
    nodes.forEach(node => {
        treeGraph.setNode(node.id, { width: 288, height: 64 });
    });

    // Add edges to the graph.
    edges.forEach(edge => {
        treeGraph.setEdge(edge.source, edge.target);
    });


    layout(treeGraph, {});

    treeGraph.nodes().forEach(nodeId => {
        const nodeInfo = treeGraph.node(nodeId);
        const node = nodes.find(n => n.id === nodeId);
        if (node) {
            node.position = { x: nodeInfo.x - nodeInfo.width / 2, y: nodeInfo.y - nodeInfo.height / 2 };
        }
    });

    return nodes;
};
