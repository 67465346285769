import React, { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FieldArray, Formik } from "formik";
import { ModalFieldLabel } from "../../Modal/ModalFieldLabel.tsx";
import { RepeatableInputPanel } from "../PipelineInfo/RepeatableInputPanel.tsx";
import { RepeatableInputFormGroup } from "../PipelineInfo/RepeatableInputFormGroup.tsx";
import { ExecutableLinkFormInput } from "../PipelineInfo/ExecutableLinkFormInput.tsx";
import { RightPanelSaveButton } from "./RightPanelTemplate/RightPanelSaveButton.tsx";
import { ToolDocumentationFormInput } from "../PipelineInfo/ToolDocumentationFormInput.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../store/store.ts";
import { FormikProps } from "formik/dist/types";
import {
    deleteAssayType as deleteAssayTypeInInfoState,
    replaceAssayType as replaceAssayTypeInInfoState,
    addAssayType as addAssayTypeInInfoState,
    IPipelineInformationData,
    setPipelineInfo
} from "../../../store/slices/Builder/BuilderPipelineInfoState.ts";
import {
    addAssayTypeToDraft,
    deleteAssayType as deleteAssayTypeInDraftState,
    deleteNodes,
    IDraftPipelineInfo,
    replaceAssayType,
    setPipelineInfoInSaveDraft
} from "../../../store/slices/Builder/BuilderSaveDraftState.ts";
import { consoleWrap } from "../../../main.tsx";
import { useSaveDraft } from "../../../hooks/pipeline-builder/useSaveDraft.ts";
import { ErrorField } from "../../ErrorField/ErrorField.tsx";
import { BuilderFlowContext } from "../../../store/context/PipelineBuilder/builder-flow-context.ts";
import useNodeService from "../../../hooks/pipeline-builder/useNodeService.ts";
import { confirmDialog } from "primereact/confirmdialog";
import { Node } from "reactflow";
import { IFormInitializationValue } from "../../../hooks/pipeline-builder/useInitializeForm.ts";
import { useGetAssayType } from "../../../hooks/pipeline-builder/useGetAssayType.ts";
import { BuilderAssayNodeData } from "../Flow/Nodes/BuilderAssayNode.tsx";
import { generateAssayTypeNode } from "../../../helpers/PipelineBuilder/BuilderFlowHelpers.ts";
import { IExecutableLink } from "../../../models/IExecutableLink.ts";
import {Tooltip} from "primereact/tooltip";

interface PipelineInformationProps {
    assayTypeOptions: IFormInitializationValue[],
    institutionOptions: IFormInitializationValue[],
    dataTypeOptions: IFormInitializationValue[]
}

const validate = (values: IPipelineInformationData) => {
    const errors: any = {};
    if (!values.name) {
        errors.name = 'Pipeline Name is required';
    }
    if (!values.datatype) {
        errors.datatype = 'Data Type is required';
    }

    if (values.processDatatypes.length === 0) {
        errors.assayTypes = ["Required"];
    } else {
        // Check if any of the assay types are empty
        const assayTypeErrors = values.processDatatypes.map((val) => {
            if (!val || val.trim() === '') {
                return "Required";
            }
            return "";
        });
        if (assayTypeErrors.some((error) => error)) {
            errors.assayTypes = assayTypeErrors;
        }
    }

    if (values.executableLinks && values.executableLinks.length > 0) {
        const executableLinkErrors: any[] = values.executableLinks.map((link: IExecutableLink) => {
            const linkErrors: any = {};
            if (!link.executableLink || !link.linkType) {
                if (!link.executableLink) {
                    linkErrors.executableLink = "Executable Link is required";
                }
                if (!link.linkType) {
                    linkErrors.linkType = "Executable Type is required";
                }
            }
            return linkErrors;
        });

        if (executableLinkErrors.some(error => Object.keys(error).length > 0)) {
            errors.executableLinks = executableLinkErrors;
        }
    }

    return errors;
};

export const PipelineInformationRightPanel = (props: PipelineInformationProps) => {

    const { nodes } = useContext(BuilderFlowContext);
    const { deleteNode: deleteNodeInContext } = useNodeService();


    const pipelineInfoSlice = useSelector((state: RootState) => state.builderPipelineInfo);
    const dispatch = useDispatch();

    // Ref to the formik used to submit the form from outside the Formik component
    const formikInnerRef = useRef<FormikProps<IPipelineInformationData>>(null);

    const readOnly = useSelector((state: RootState) => state.builderMeta.readOnly);

    const { replaceNode, addAssayTypeNode } = useNodeService();

    // State that determines if an assay type is being replaced or added. This state is updated and used when an assay type is being added/replaced.
    const [
        assayTypeToBeUpdated,
        setAssayTypeToBeUpdated
    ] = useState<{ name: string, updateType: "replace" | "add" } | null>(null)

    const {
        postSaveDraft,
        isLoading: saveDraftIsLoading
    } = useSaveDraft();

    const {
        getAssayType,
        data: getAssayTypeData
    } = useGetAssayType();

    const onSaveClicked = (e: MouseEvent | FormEvent) => {
        e.preventDefault();
        if (formikInnerRef.current) {
            formikInnerRef.current.submitForm();
        }
    }

    /**
     * Formik submit handler. Sets the data of the selected node to the form values.
     *
     * @param values
     */
    const onFormikSubmit = async (values: IPipelineInformationData) => {
        consoleWrap.log("Formik Submitted: ", values);
        // Update the form data that is stored in the state
        // This may be removed in the future if we find that the pipeline info part of the save draft state is just as useful.
        dispatch(setPipelineInfo(values));
        const listOfInstitutions = values.institution.map((institution: string) => ( { name: institution, link: "" } ));

        // Convert the form value object into a payload for the save pipeline info draft action
        const saveDraftPayload: IDraftPipelineInfo = {
            name: values.name,
            datatype: [values.datatype],
            assayTypes: values.processDatatypes,
            version: values.version,
            link: values.documentation,
            main: {
                executableLinks: values.executableLinks,
                nodes: []
            },
            user: listOfInstitutions,
            purposes: [values.purpose],
            subBranches: [],
            doi: values.doi,
        }
        dispatch(setPipelineInfoInSaveDraft(saveDraftPayload));

        const currDraftState = store.getState().builderSaveDraft;
        await postSaveDraft(currDraftState);

    }

    /**
     * Deletes an assay type from the form, context and redux.
     *
     * @param index - Index of the assay type in the form array
     * @param nodeName - Name of the assay type to be deleted
     * @param formikRemoveFunc - Formik remove function to remove the assay type from the formik array
     * @param contextNodes - List of nodes in the context
     */
    const deleteAssayType = async (index: number, nodeName: string, formikRemoveFunc: (index: number) => void, contextNodes: Node[]) => {
        // Find the node to be deleted in the context
        const nodeToBeDeleted = contextNodes.find(node => node.data.label === nodeName);

        // If the node couldnt be found then somehow the assay list and the node list are not in sync. Uh oh.
        if (!nodeToBeDeleted) {
            consoleWrap.error("Assay type to be deleted not found in the context");
            return;
        }

        // Delete the node from the context, redux
        const deletedNodeIds = deleteNodeInContext(nodeToBeDeleted.id);
        dispatch(deleteNodes({ idsToDelete: deletedNodeIds }));

        // Delete assay type from right panel formik, draft state and state pipeline info state
        dispatch(deleteAssayTypeInDraftState({ assayType: nodeName }));
        dispatch(deleteAssayTypeInInfoState({ assayType: nodeName }));
        formikRemoveFunc(index);

        const currDraftState = store.getState().builderSaveDraft;
        await postSaveDraft(currDraftState);
    }

    /**
     * Handler for deleting an assay type from the form.
     *
     * @param index - Index of the assay type in the form array
     * @param nodeName - Name of the assay type to be deleted
     * @param formikRemoveFunc - Formik remove function to remove the assay type from the formik array
     */
    const handleDeleteAssayType = (index: number, nodeName: string, formikRemoveFunc: (index: number) => void, formik: FormikProps<any>) => {
        if (!nodeName) {
            const processDatatypesValues: string[] = [...formik.values.processDatatypes];
            processDatatypesValues.splice(index, 1);
            formikRemoveFunc(index);
            formik.setFieldValue('processDatatypes', processDatatypesValues)
            return;
        }
        confirmDialog({
            message: 'Are you sure you want to delete this? This action cannot be undone',
            header: 'Delete?',
            defaultFocus: 'accept',
            acceptLabel: 'Yes, delete assay',
            rejectLabel: 'Cancel',
            closable: false,
            accept: () => {
                deleteAssayType(index, nodeName, formikRemoveFunc, nodes);
            },
            reject: () => {
            }
        });
    }

    /**
     * Handler for when an assay type selection changes. This function is called when the dropdown value changes.
     * Sends the request to get the new assay type node from the backend.
     *
     * @param oldValue - The old assay type name
     * @param newValue - The new assay type name
     */
    function handleAssayTypeSelectionChange(oldValue: string, newValue: string) {
        // Check if the value has changed
        if (oldValue !== newValue && newValue) {
            // Determine if the value is being replaced or added. If the old value was the default empty dropdown value "" then it is an add.
            // But if there was a string value there before, this is a replacement.
            setAssayTypeToBeUpdated({
                name: oldValue,
                updateType: oldValue ? "replace" : "add"
            });
            // If the new value is not empty, then we need to fetch the new assay type node
            onNewAssayType(newValue);
        }
    }

    /**
     * Fetches the new assay type from the backend.
     * @param assayTypeName - The name of the assay type to fetch
     */
    const onNewAssayType = async (assayTypeName: string) => {
        // Fetch new assay type from backend
        await getAssayType(assayTypeName);
    }

    // When an assay type is fetched from the backend, begin the process of adding it to state and context
    useEffect(() => {
        // If the assay type data has been fetched and the assay type to be replaced is not null then proceed with replacing the assay type in context and state
        if (getAssayTypeData && assayTypeToBeUpdated && getAssayTypeData.assayType._id) {
            if (assayTypeToBeUpdated.updateType === "replace") {
                // Find the old node in the state
                const oldAssayNode = store.getState().builderSaveDraft.graph.nodes.find(node => node.type === "datatype" && node.name === assayTypeToBeUpdated.name);

                // Replace the old value with the new value in the state
                dispatch(replaceAssayType({
                    oldAssayType: assayTypeToBeUpdated.name,
                    newAssayType: getAssayTypeData.assayType
                }));
                // Generate an assay node with the new data.
                const newAssayNode: Node<BuilderAssayNodeData> = generateAssayTypeNode(getAssayTypeData.assayType.id, getAssayTypeData.assayType.name);
                // If it was found, proceed with replacing it in context
                if (oldAssayNode) {
                    replaceNode(oldAssayNode.id, newAssayNode);
                }
                // Update the right panel state
                dispatch(replaceAssayTypeInInfoState({
                    oldAssayType: assayTypeToBeUpdated.name,
                    newAssayType: getAssayTypeData.assayType.name
                }));

                const currDraftState = store.getState().builderSaveDraft;
                postSaveDraft(currDraftState);
            } else {
                // If not replace then this is adding a new assay type

                // Add the new assay type node to the state. Automatically adds an edge as well.
                dispatch(addAssayTypeToDraft({
                    mainNode: getAssayTypeData.assayType,
                    graphNode: {
                        _id: getAssayTypeData.assayType._id,
                        nodetype: getAssayTypeData.assayType.nodetype,
                        id: getAssayTypeData.assayType.id,
                        name: getAssayTypeData.assayType.name,
                        type: getAssayTypeData.assayType.type,
                        __v: getAssayTypeData.assayType.__v,
                        class: getAssayTypeData.assayType.class,
                        info: {}
                    }
                }));
                // Generate a new assay flow node with the new data
                const newAssayNode: Node<BuilderAssayNodeData> = generateAssayTypeNode(getAssayTypeData.assayType.id, getAssayTypeData.assayType.name);
                // Add the new node to the flow context
                addAssayTypeNode(newAssayNode);

                // Update the right panel state
                dispatch(addAssayTypeInInfoState({
                    assayType: getAssayTypeData.assayType.name
                }));

                const currDraftState = store.getState().builderSaveDraft;
                postSaveDraft(currDraftState);
            }
            
            setAssayTypeToBeUpdated(null);
        }
    }, [getAssayTypeData]);

    return (
        <>
            { pipelineInfoSlice.formData &&
                <div
                    className="flex self-stretch grow shrink basis-0 flex-col justify-start items-start overflow-y-scroll overflow-x-clip">

                    {/* Form */ }
                    <div className="flex-1 overflow-y-auto w-full px-5 pb-0">
                        <Formik
                            initialValues={ pipelineInfoSlice.formData }
                            onSubmit={ onFormikSubmit }
                            validate={ validate }
                            innerRef={ formikInnerRef }
                        >
                            { (formik) => {

                                return (
                                    <form
                                        onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
                                        className="flex-col justify-start items-start gap-4 inline-flex max-w-full w-full pt-5 pb-0">
                                        <div
                                            className="self-stretch h-12 flex-col justify-start items-start gap-1 flex">
                                            <div
                                                className="self-stretch text-neutral-800 text-xl font-semibold font-inter leading-normal">
                                                Pipeline Information
                                            </div>
                                            <div
                                                className="self-stretch text-neutral-500 text-sm font-normal font-inter leading-tight">
                                                Enter general pipeline information.
                                            </div>
                                        </div>

                                        {/* Name input */ }
                                        <div
                                            className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={ "Pipeline Name" } required={ true }/>
                                            <InputText
                                                disabled={ readOnly }
                                                placeholder="New Pipeline"
                                                { ...formik.getFieldProps("name") }
                                                className={ `self-stretch px-3 py-2.5 bg-white rounded border justify-start items-center gap-2.5 inline-flex ${ formik.submitCount > 0 && formik.errors.name ? "border-red-500" : "border-zinc-400" }` }
                                                pt={ { root: { className: "!text-gray-800" } } }
                                            />

                                        </div>
                                        { formik.errors.name && formik.submitCount > 0 && (
                                            <ErrorField errorMessage={ formik.errors.name }/>
                                        ) }

                                        {/* Data Type field */ }
                                        <div
                                            className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
                                            <Tooltip target=".data-type-target-icon" style={{boxShadow: 'none'}}/>
                                            <div className="flex-row gap-1 align-middle flex">
                                                <ModalFieldLabel label={"Data Type"} required={true}/>
                                                <i className="data-type-target-icon pi pi-info-circle"
                                                   data-pr-tooltip="If you want to add another item to this list please email your request to admin@cobe.ca."
                                                   data-pr-position="top"
                                                   style={{marginLeft: '2px', cursor: 'pointer'}}
                                                />
                                            </div>
                                            <InputText
                                                {...formik.getFieldProps('datatype')}
                                                id="datatype"
                                                readOnly={true}
                                                className={`p-inputtext-sm w-full text-black border-gray-300 cursor-not-allowed ${
                                                    formik.submitCount > 0 && formik.errors.datatype ? "border-red-500" : "border-gray-300"
                                                }`}
                                            />
                                        </div>
                                        { formik.errors.datatype && formik.submitCount > 0 && (
                                            <ErrorField errorMessage={ formik.errors.datatype }/>
                                        ) }

                                        {/* Assay Types input */ }
                                        <RepeatableInputPanel
                                            name={ "assayTypes" }
                                            headerLabel={ "Assay Types" }
                                            onAddAnotherOption={ () => {
                                                formik.setFieldValue('processDatatypes', [...formik.values.processDatatypes, ''])
                                            } }
                                            readOnly={ readOnly }
                                        >
                                            <FieldArray name={ "processDatatypes" }>
                                                { ({ remove, form }) => {
                                                    // Filter out the options that have already been selected
                                                    const filteredOptions = props.assayTypeOptions.filter((option) => !form.values.processDatatypes.includes(option.value));
                                                    return ( formik.values.processDatatypes.map((val: any, index: number) => (
                                                        <div key={ `AssayTypeFormInput-${ index }` }>
                                                            <RepeatableInputFormGroup
                                                                onDelete={ () => handleDeleteAssayType(index, val, remove, formik) }
                                                                name="processDatatypes"
                                                                text="Assay Type"
                                                                index={ index }
                                                                dropdownOptions={ filteredOptions }
                                                                readOnly={ readOnly }
                                                                onSelectionChange={ (oldValue, newValue) => {
                                                                    handleAssayTypeSelectionChange(oldValue, newValue);
                                                                } }
                                                            />
                                                        </div>
                                                    )) )
                                                } }
                                            </FieldArray>
                                        </RepeatableInputPanel>


                                        {/* Purpose input */ }
                                        <div
                                            className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={ "Purpose" }/>
                                            <InputText
                                                disabled={ readOnly }
                                                placeholder="Purpose of the pipeline"
                                                { ...formik.getFieldProps("purpose") }
                                                className={ `self-stretch px-3 py-2.5 bg-white rounded border justify-start items-center gap-2.5 inline-flex ${ formik.submitCount > 0 && formik.errors.purpose ? "border-red-500" : "border-zinc-400" }` }
                                                pt={ {
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                } }
                                            />
                                        </div>

                                        {/* Version input */ }
                                        <div
                                            className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={ "Version" }/>
                                            <InputText
                                                { ...formik.getFieldProps("version") }
                                                disabled={ readOnly }
                                                className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                                pt={ {
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                } }
                                            />
                                        </div>

                                        {/* Pipeline Link input */ }
                                        <RepeatableInputPanel
                                            name={ "executables" }
                                            dataTestId={ "executablesAddOptionBtn" }
                                            headerLabel={ "Executables" }
                                            onAddAnotherOption={ () => {
                                                formik.setFieldValue('executableLinks', [...formik.values.executableLinks, {
                                                    executableLink: "",
                                                    linkType: "",
                                                    default: false
                                                }])
                                            } }
                                            readOnly={ readOnly }
                                        >
                                            <FieldArray name={ "executableLinks" }>

                                                { ({ remove }) => {
                                                    return (
                                                        formik.values.executableLinks.map((val: any, index: number) => {
                                                            return (
                                                                <div key={ `ExecutableLinkFormInput-${ index }` }>
                                                                    <ExecutableLinkFormInput
                                                                        name="executableLinks"
                                                                        placeholder="Link to Executable"
                                                                        onDelete={ remove }
                                                                        index={ index }
                                                                        readOnly={ readOnly }
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    );
                                                } }
                                            </FieldArray>

                                        </RepeatableInputPanel>

                                        {/* Pipeline Documentation */ }
                                        <RepeatableInputPanel
                                            name={ "pipelineDocumentation" }
                                            headerLabel={ "Pipeline Documentation" }
                                            onAddAnotherOption={ () => {
                                                formik.setFieldValue('documentation', [...formik.values.documentation, '']);
                                            } }
                                            readOnly={ readOnly }
                                        >
                                            <FieldArray name={ "documentation" }>
                                                { ({ remove }) => {
                                                    return (
                                                        formik.values.documentation?.map((val: any, index: number) => {
                                                            return (
                                                                <ToolDocumentationFormInput
                                                                    onDelete={ remove }
                                                                    name="documentation"
                                                                    index={ index }
                                                                    readOnly={ readOnly }
                                                                />
                                                                // {...formik.submitCount > 0 && errors.toolDocumentation  &&
                                                                // <ErrorField errorMessage={errors.toolDocumentation[0]} />
                                                                // }
                                                            );

                                                        })

                                                    );
                                                } }
                                            </FieldArray>
                                        </RepeatableInputPanel>

                                        {/* DOI input */ }
                                        <div
                                            className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={ "DOI" }/>
                                            <InputText
                                                { ...formik.getFieldProps("doi") }
                                                disabled={ readOnly }
                                                className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                                pt={ {
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                } }
                                            />
                                        </div>

                                        {/* Institution dropdown */ }
                                        <RepeatableInputPanel
                                            name={ "labGroups" }
                                            headerLabel={ "Lab Group/Institution" }
                                            onAddAnotherOption={ () => {
                                                // Ensure that a new object with the necessary keys is added to the array
                                                formik.setFieldValue('institution', [...formik.values.institution, ""])
                                            } }
                                            readOnly={ readOnly }
                                        >
                                            <FieldArray name={ "institution" }>
                                                { ({ remove, form }) => {
                                                    // Filter out the options that have already been selected
                                                    const filteredOptions = props.institutionOptions.filter((option) => !form.values.institution.includes(option.value));
                                                    return (
                                                        formik.values.institution.map((val: any, index: number) => {
                                                            return (
                                                                <RepeatableInputFormGroup
                                                                    key={ `InstitutionFormInput-${ index }` }
                                                                    onDelete={ remove }
                                                                    name="institution"
                                                                    index={ index }
                                                                    dropdownOptions={ filteredOptions }
                                                                    text="Lab Group/Institution"
                                                                    readOnly={ readOnly }
                                                                />
                                                            );
                                                        })
                                                    );
                                                } }
                                            </FieldArray>
                                        </RepeatableInputPanel>
                                    </form>
                                )
                            } }
                        </Formik>
                    </div>

                    {/* Save */ }
                    {
                        !readOnly &&
                        <div className="w-full h-[72px]">
                            <RightPanelSaveButton onClick={ onSaveClicked }/>
                        </div>
                    }
                    { readOnly && <div className="h-4"></div> }
                </div>
            }
        </>
    );
};
