import {PrimeReactPTOptions} from "primereact/api";
import {button} from "./prime-react-button.ts";
import {togglebutton} from "./prime-react-toggle-button.ts";
import {checkbox} from "./prime-react-checkbox.ts";
import {autocomplete} from "./prime-react-autocomplete.ts";
import { progressspinner } from "./prime-react-progress-spinner.ts";
import { global } from "./prime-react-global.ts";
import { progressbar } from "./prime-react-progress-bar.ts";
import { tabpanel, tabview } from "./prime-react-tabview.ts";
import {inputtext} from "./prime-react-inputtext.ts";
import {password} from "./prime-react-password.ts";
import {datatable} from "./prime-react-datatable.ts";
import {toast} from "./prime-react-toast.ts";
import { sidebar } from "./prime-react-sidebar.ts";
import { avatar } from "./prime-react-avatar.ts";
import { avatargroup } from "./prime-react-avatar-group.ts";
import {dropdown} from "./prime-react-dropdown.ts";
import {multiselect} from "./prime-react-multiselect.ts";
import {chip} from "./prime-react-chip.ts";
import { dialog } from "./prime-react-dialog.ts";
import { panel } from "./prime-react-panel.ts";
import {tooltip} from "./prime-react-tooltip.ts";
import {splitbutton} from "./prime-react-splitbutton.ts";
import {divider} from "./prime-react-divider.ts";
import {contextmenu} from "./prime-react-contextmenu.ts";
import {confirmdialog} from "./prime-react-confirmdialog.ts";
import {tag} from "./prime-react-tag.ts";
import {paginator} from "./prime-react-paginator.ts";

export const CoBeDesignSystem: PrimeReactPTOptions = {
    button,
    togglebutton,
    checkbox,
    tabview,
    tabpanel,
    autocomplete,
    progressspinner,
    global,
    progressbar,
    inputtext,
    password,
    toast,
    sidebar,
    avatar,
    avatargroup,
    dropdown,
    multiselect,
    chip,
    dialog,
    tooltip,
    panel,
    datatable,
    splitbutton,
    divider,
    contextmenu,
    confirmdialog,
    tag,
    paginator
}
