import { IExecutableLink }  from "../../hooks/useSearch";

const linksOrder= ["CodeOcean", "CodeOceanVPC", "WebApp", "Github", "HPC"];
export const orderExecutableLinks = (executableLinks: IExecutableLink[] | null) => {
    if (!executableLinks || executableLinks.length === 0) {
        return [];
    }
    return executableLinks.sort((a: { linkType: string; }, b: { linkType: string; }) => linksOrder.indexOf(a.linkType) - linksOrder.indexOf(b.linkType));
}

// default link is the first link found in the list of executable links
export const getDefaultExecutableLink = (executableLinks: IExecutableLink[] | null) => {
    const orderedLinks = orderExecutableLinks(executableLinks || []);
    return orderedLinks[0]?.executableLink || "";
}
