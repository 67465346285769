import { useAxios } from "./useAxios.tsx";

export type FilterGraphQueryParamType = {
    assayType: string;
    purposes: string[];
}

type GetFilterGraphResponse = {
    data: FilterGraphQueryParamType | null,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useFilterGraph = () => {
    const { commonFetch, isLoading, data, error }: GetFilterGraphResponse = useAxios<FilterGraphQueryParamType>({
        url: "data/search/graph/filter",
        method: "POST",
    });

    const filterGraph = async (input: FilterGraphQueryParamType) => await commonFetch({ input });

    return { filterGraph, isLoading, data, error };
};
