import { useAxios } from "../useAxios.tsx";
import { doRefreshToken } from "../useRefreshToken.tsx";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";

export interface ApprovePipelineDraftDataResponse {
    "newStatus": DraftStatus.Approved;
}

type ApprovePipelineDraftAxiosResponse = {
    data: ApprovePipelineDraftDataResponse | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useApprovePipelineDraft = () => {
    const { commonFetch, isLoading, data, error }: ApprovePipelineDraftAxiosResponse = useAxios({
        url: '/view/pipeline-builder/approve',
        method: "POST",
    });

    const approvePipelineDraft = async (pipelineId: string, email: string) => {
        try {
            await doRefreshToken();
            await commonFetch({ input: { pipelineId, email } });
        } catch (e) {
            console.error("Failed to refresh token or approve pipeline draft", e);
            throw e;
        }
    };

    return { approvePipelineDraft, isLoading, data, error };
};