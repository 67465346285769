import { useAxios } from "./useAxios.tsx";
import { IPipeline, IPurposeTool, ITool, IUser } from "./useSearch.tsx";
import { IGraph } from "../models/Graph/IGraph.ts";

export interface IGetToolByIdData {
    versions: string[];
    doi: string;
    link: string[];
    score: number;
    keywords: any[];
    output: string[];
    input: string[];
    purposes: IPurposeTool[];
    name: string;
    user: IUser[];
    graph: IGraph
    pipeline: IPipeline,
    tools: ITool[],
}

export interface IGetToolByIdResponse {
    data: IGetToolByIdData | null,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetToolById = () => {
    const { isLoading, commonFetch, data, error }: IGetToolByIdResponse = useAxios<IGetToolByIdData | null>({
        url: '/data/tool',
        method: "GET"
    });

    const getGetToolById = async (queryParams: { id: string, getAssociatedTools: boolean }) => {
        await commonFetch({
            queryParameters: queryParams
        });
    };

    return { getGetToolById, isLoading, data, error };
};
