// NodeService.ts
import { useContext } from 'react';
import { BuilderFlowContext } from "../../store/context/PipelineBuilder/builder-flow-context.ts";
import {
    BuilderPipelineSelectedNodeContext
} from "../../store/context/PipelineBuilder/builder-pipeline-selected-node-context.ts";
import { Edge, MarkerType, Node } from "reactflow";
import { generalLayoutNodes } from "../../helpers/flow/flow-diagram-layout.ts";
import { GraphDirection } from "../../helpers/flow/graph-direction.ts";
import { consoleWrap } from "../../main.tsx";
import {
    calculateDecisionNodePosition,
    generateDecisionNodeEdge,
    generateEmptyDecisionNode,
    getEdgesWithoutAddLeafEdge,
    getNodesWithoutAddLeafNode
} from "../../helpers/PipelineBuilder/BuilderFlowHelpers.ts";
import {
    BuilderToolNodeData,
    BuilderToolNodeIds,
    generateBuilderToolNodeId
} from "../../components/Builder/Flow/Nodes/BuilderToolNode.tsx";
import { useDispatch } from "react-redux";
import { setBuilderMetaFlowViewerPosition } from "../../store/slices/Builder/BuilderMeta.ts";
import { BuilderAssayNodeData } from "../../components/Builder/Flow/Nodes/BuilderAssayNode.tsx";


/**
 * This function is used to get all nodes that are connected downstream to a given node.
 * It uses a breadth-first search algorithm to traverse the graph.
 *
 * @param {string} nodeId - The ID of the node from which to start the search.
 * @param {Edge[]} edges - The array of all edges in the graph.
 * @returns {string[]} - An array of IDs of all nodes that are connected to the given node downstream.
 */
const getConnectedNodes = (nodeId: string, edges: Edge[]): string[] => {
    // Nodes to delete
    const connectedNodes = [nodeId];
    // Initialize the queue for the breadth-first search with the given node
    const queue = [nodeId];

    // Continue the search as long as there are nodes in the queue
    while (queue.length > 0) {
        // Remove the first node from the queue
        const currentNode = queue.shift()!;
        // Check all edges in the graph
        edges.forEach(edge => {
            // If the current node is the source of the edge and the target node is not already in the connected nodes
            if (edge.source === currentNode && !connectedNodes.includes(edge.target)) {
                // Add the target node to the connected nodes
                connectedNodes.push(edge.target);
                // Add the target node to the queue
                queue.push(edge.target);
            }
        });
    }

    // Return the array of connected nodes
    return connectedNodes;
};

const useNodeService = () => {
    const {
        setNodes,
        setEdges: setContextEdges,
        edges: contextEdges,
        nodes: contextNodes
    } = useContext(BuilderFlowContext);
    const { setSelectedNode, selectedNode } = useContext(BuilderPipelineSelectedNodeContext);

    const dispatch = useDispatch();

    /**
     * Adds a new node to the nodes array.
     *
     * @param {Node} node - The new node to add.
     */
    const addNode = (node: Node) => {
        setNodes((prevNodes) => [...prevNodes, node]);
    };

    /**
     * Updates a node in the nodes array and updates the selected node if it is the one being updated.
     *
     * @param oldNodeId - The ID of the node to update.
     * @param {Node} updatedNode - The updated node.
     */
    const updateNode = (oldNodeId: string, updatedNode: Node) => {
        setNodes(prevNodes => {
            // Create a new node list but replace the updated node
            const newNodes = prevNodes.map(node => {
                return node.id === oldNodeId ? updatedNode : node;
            });
            // Optionally update the selected node if it is currently selected
            setSelectedNode((prevSelectedNode) => {
                if (prevSelectedNode && prevSelectedNode.id === oldNodeId) {
                    return updatedNode;
                }
                return prevSelectedNode;
            });
            return newNodes;
        });
    };

    /**
     * Updates the edges in the context.
     *
     * @param { Edge[] } newEdges - The new edges to set.
     */
    const setEdges = (newEdges: Edge[]) => {
        setContextEdges(newEdges);
    };

    /**
     * Deletes a node from the nodes array and clears the selected node if it is the one being deleted.
     *
     * @param {string} nodeId - The ID of the node to delete.
     * @returns {string[]} - An array of IDs of all nodes that were deleted.
     */
    const deleteNode = (nodeId: string): string[] => {
        // Get a list of all nodes that need to be deleted
        const connectedNodes = getConnectedNodes(nodeId, contextEdges);
        const connectedNodeIds = new Set([...connectedNodes, nodeId]);

        // Remove the node and all connected downstream nodes from the nodes array
        const newNodeList = contextNodes.filter(node => !connectedNodes.includes(node.id));

        // Remove all edges that reference the nodes
        const newEdgeList = contextEdges.filter(edge => !connectedNodeIds.has(edge.source) && !connectedNodeIds.has(edge.target));

        const layoutedNodes = generalLayoutNodes(
            newNodeList,
            newEdgeList,
            { graphDirection: GraphDirection.LEFT_TO_RIGHT, rankSeparationDistance: 75 }
        );

        setNodes(layoutedNodes);
        setEdges(newEdgeList);

        // Clear the selected node if it is the one being deleted
        setSelectedNode((prevSelectedNode) => {
            if (prevSelectedNode && connectedNodeIds.has(prevSelectedNode.id)) {
                return null;
            }
            return prevSelectedNode;
        });

        return connectedNodes;
    };

    /**
     * Updates the ID of a node in the nodes array and updates the edges that reference the node.
     *
     * @param {string} oldNodeId - The old ID of the node.
     * @param {string} newId - The new ID of the node.
     */
    const updateNodeId = (oldNodeId: string, newId: string) => {
        setNodes(prevNodes => {
            const newNodes = prevNodes.map(node => {
                return node.id === oldNodeId ? { ...node, id: newId } : node;
            });
            return newNodes;
        });
        // update edges as well
        setContextEdges(prevEdges => {
            const newEdges = prevEdges.map(edge => {
                return {
                    ...edge,
                    source: edge.source === oldNodeId ? newId : edge.source,
                    target: edge.target === oldNodeId ? newId : edge.target
                };
            });
            return newEdges;
        });
    }

    const replaceNode = (oldNodeId: string, newNode: Node) => {
        // Replace the node in the nodes array
        const newNodes = contextNodes.map(node => {
            const replacementNode = node.id === oldNodeId ? newNode : node;
            return replacementNode;
        });

        const newEdges = contextEdges.map(edge => {
            const source = edge.source === oldNodeId ? newNode.id : edge.source;
            const target = edge.target === oldNodeId ? newNode.id : edge.target;
            const id = `${ source }-${ target }`;
            return {
                ...edge,
                id,
                source,
                target
            };
        });
        const layoutedNodes = generalLayoutNodes(newNodes, newEdges, {
            graphDirection: GraphDirection.LEFT_TO_RIGHT,
            rankSeparationDistance: 75
        });
        setNodes(layoutedNodes);
        setEdges(newEdges);
    }

    /**
     * Called when the user clicks the add handle on a node.
     * It creates a new decision node and connects it to the source node.
     * It also removes any pre-existing decision nodes and edges.
     *
     * @param sourceNodeId - The id of the node that is the source of the connection
     * @param sourceHandleId - The id of the handle that is the source of the connection
     */
    const onNewNodeHandleClicked = (sourceNodeId: string, sourceHandleId: string) => {
        consoleWrap.log("New node handle clicked")

        // Find the node that is the source of the connection
        const sourceNode = contextNodes.find(node => node.id === sourceNodeId);
        // If it wasnt found, cancel the connection
        if (!sourceNode) return;

        // Remove any pre-existing decision nodes and edges
        const newNodeList = getNodesWithoutAddLeafNode(contextNodes);
        const { newEdgeList } = getEdgesWithoutAddLeafEdge(contextEdges);

        // Calculate the position of the decision node relative to the source node such that it is centered vertically
        const { xPos, yPos } = calculateDecisionNodePosition(sourceNode);

        // The new decision node
        const decisionNode = generateEmptyDecisionNode();
        decisionNode.position = { x: xPos, y: yPos };
        decisionNode.data.onNewToolClicked = onNewToolClicked;
        decisionNode.data.onNewPipelineClicked = () => {
        };

        decisionNode.data.sourceNodeId = sourceNodeId;

        // The new edge that connects the source node to the decision node
        const decisionNodeEdge = generateDecisionNodeEdge(sourceNodeId, decisionNode, sourceHandleId);

        setNodes([...newNodeList, decisionNode]);
        setContextEdges([...newEdgeList, decisionNodeEdge]);


    }

    /**
     * Called when the user clicks the add tool button on the decision node.
     * It creates a new tool node and connects it to the decision node.
     * It also removes any pre-existing decision nodes and edges.
     *
     * @param x - The x position of the new tool node
     * @param y - The y position of the new tool node
     */
    const onNewToolClicked = (x: number, y: number) => {
        consoleWrap.log("New tool clicked")
        const nodeId = generateBuilderToolNodeId();
        const newNode: Node<BuilderToolNodeData> = {
            id: nodeId,
            position: { x, y },
            data: {
                label: "New Tool",
                byline: "Tool byline",
                isSelected: false,
                toolData: null
            },
            type: BuilderToolNodeIds.NODE_TYPE
        };
        // Remove any pre-existing decision nodes and edges
        const newNodeList = getNodesWithoutAddLeafNode(contextNodes);
        const { newEdgeList: edgeList, addLeafEdge } = getEdgesWithoutAddLeafEdge(contextEdges);
        // List to use for new edges
        let newEdgeList: Edge[] = [...edgeList];
        // If an edge was found, update its information and add it back to the new edge list
        if (addLeafEdge) {
            addLeafEdge.target = nodeId;
            addLeafEdge.id = `${ addLeafEdge.source }-${ addLeafEdge.target }`;
            addLeafEdge.hidden = false;
            newEdgeList = [...edgeList, addLeafEdge];
        }
        // Format entire tree with new node
        const layoutedNodes = generalLayoutNodes(
            [...newNodeList, newNode],
            newEdgeList,
            { graphDirection: GraphDirection.LEFT_TO_RIGHT, rankSeparationDistance: 75 }
        );

        // Set the new nodes and edges
        setEdges([...newEdgeList]);
        setNodes([...layoutedNodes]);

        // Find the new node in the layouted nodes.
        // This is done after layouting the nodes to ensure the new node is in the correct position.
        const newLayoutedNode = layoutedNodes.find(node => node.id === nodeId);
        if (newLayoutedNode) {
            // Set the selected node in the pipeline context
            setSelectedNode(newLayoutedNode);

            // Center the view on the new node
            dispatch(setBuilderMetaFlowViewerPosition({
                x: newLayoutedNode.position.x,
                y: newLayoutedNode.position.y
            }));

        }

    }

    /**
     * Called when the user clicks the add handle on a node.
     * Used to set the currently selected node in the pipeline context.
     *
     * @param nodeId - The id of the node that is the source of the connection
     */
    const onNodeClicked = (nodeId: string) => {
        consoleWrap.log("Node clicked: ", nodeId);
        const node = contextNodes.find(n => n.id === nodeId);
        if (!node) return;
        setSelectedNode(node);
    }

    /**
     * Adds an assay type node to the context. Also adds an edge connecting the root node to the assay type node.
     *
     * @param assayNode - The assay type node to add.
     */
    const addAssayTypeNode = (assayNode: Node<BuilderAssayNodeData>) => {
        const newNodeList = [...contextNodes, assayNode];
        // create a new Edge that connects from the root node to the inputted node
        const newEdge: Edge = {
            id: `${ contextNodes[0].id }-${ assayNode.id }`,
            source: contextNodes[0].id,
            target: assayNode.id,
            markerEnd: { type: MarkerType.Arrow }
        };
        const newEdgeList = [...contextEdges, newEdge];
        const layoutedNodes = generalLayoutNodes(
            newNodeList,
            newEdgeList,
            { graphDirection: GraphDirection.LEFT_TO_RIGHT, rankSeparationDistance: 75 }
        );
        setNodes(layoutedNodes);
        setContextEdges(newEdgeList);
    }

    return {
        setNodes,
        setContextEdges,
        contextEdges,
        contextNodes,
        addNode,
        updateNode,
        deleteNode,
        setEdges,
        updateNodeId,
        replaceNode,
        onNewNodeHandleClicked,
        onNewToolClicked,
        onNodeClicked,
        addAssayTypeNode
    };
};

export default useNodeService;
