import { ReactSVG } from "react-svg";
import IMAGES from "../../assets/images/images.tsx";
import React from "react";
import { useNavigate } from "react-router-dom";

interface PipelineExpandedDiagramTopBarProps {
    setSideBarVisible: () => void,
    pipelineName: string
}

export function PipelineExpandedDiagramTopBar(props: PipelineExpandedDiagramTopBarProps) {


    const navigate = useNavigate();

    function pageBack() {
        navigate(-1)
    }

    return <div className="w-full h-20 p-5 bg-white border-b border-gray-200 justify-between items-center inline-flex">
        <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">
            <div
                className="p-2 rounded-full justify-start items-start gap-2.5 flex hover:bg-icon_button_hover cursor-pointer"
                onClick={ pageBack }>
                <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-0 absolute rounded-full">
                        <ReactSVG src={ IMAGES.left_arrow_icon }/>
                    </div>
                </div>
            </div>

            {/*<div className="text-black text-xl font-semibold font-inter leading-normal">3 pipelines found:</div>*/}
            <div className="pl-3 pr-1.5 py-1.5 bg-slate-100 rounded justify-center items-center gap-1 flex">
                <div className="text-blue-700 text-sm font-normal font-inter leading-tight">{props.pipelineName}</div>
                <div className="w-5 h-5 relative">
                    <div className="w-5 h-5 left-0 top-0 absolute"></div>
                </div>
            </div>
        </div>
        <div className="justify-start items-center gap-2 flex">
            <div className="p-2 rounded-full justify-start items-start gap-2.5 flex">
                <div className="w-6 h-6 relative">
                    {
                        // TODO: Add like icon
                    }
                    {/*<div className="w-6 h-6 left-0 top-0 absolute bg-zinc-300">{ "<3" }</div>*/}
                </div>
            </div>
            <div className="p-2 rounded-full justify-start items-start gap-2.5 flex">
                <div className="w-6 h-6 relative">
                    {
                        // TODO: add an export icon
                    }
                    {/*<div className="w-6 h-6 left-0 top-0 absolute bg-zinc-300">{ "|_^_|" }</div>*/}
                </div>
            </div>
            <div
                className="p-2 rounded-full justify-start items-start gap-2.5 flex hover:bg-icon_button_hover cursor-pointer"
                onClick={ props.setSideBarVisible }>
                <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-0 absolute">
                        <ReactSVG src={ IMAGES.right_panel_icon }/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}