import {useAxios} from "../useAxios.tsx";
import {doRefreshToken} from "../useRefreshToken.tsx";

interface IDeleteDraftAxiosResponse {
    data: {
        message: string;
        pipelineId: string;
    } | null,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useDeleteDraft = () => {
    const { commonFetch, isLoading, data, error }: IDeleteDraftAxiosResponse = useAxios<{
        message: string;
        pipelineId: string;
    }>({
        url: `/data/pipeline/delete-draft`,
        method: "DELETE",
    });

    const deleteDraft = async (draftId: string) => {
        await doRefreshToken()
        await commonFetch({
            queryParameters: {id: draftId}
        });
    }

    return { deleteDraft, isLoading, data, error };
};
