import {ToastPassThroughMethodOptions, ToastPassThroughOptions} from "primereact/toast";
import {classNames} from "primereact/utils";
import {consoleWrap} from "../main.tsx";

export const toast: ToastPassThroughOptions = {
    root: {
        className: classNames('w-96', 'opacity-90')
    },
    message: ({ state, index }: any) => {
        return {
            className: classNames('my-4 rounded-md w-full', {
                'bg-blue-100 border-solid border-0 border-l-4 border-blue-500 text-blue-700': state.messages[index] && state.messages[index].message.severity == 'info',
                'bg-green-100 border-solid border-0 border-l-4 border-green-500 text-green-700': state.messages[index] && state.messages[index].message.severity == 'success',
                'bg-orange-100 border-solid border-0 border-l-4 border-orange-500 text-orange-700': state.messages[index] && state.messages[index].message.severity == 'warn',
                'bg-red-100 border-solid border-0 border-l-4 border-red-500 text-red-700': state.messages[index] && state.messages[index].message.severity == 'error'
            })
        }
    },
    content: ({props, state}: ToastPassThroughMethodOptions) => ({
        className: classNames('flex items-center py-5 px-7')
    }),
    icon: {
        className: classNames('w-6 h-6', 'text-lg mr-2')
    },
    text: ({props, state}: ToastPassThroughMethodOptions) => ({
        className: classNames('text-base font-normal flex flex-col flex-1 grow shrink ml-4')
    }),
    summary: ({props, state}: ToastPassThroughMethodOptions) => ({
        className: classNames('font-bold block')
    }),
    detail: ({props, state}: ToastPassThroughMethodOptions) => ({
        className: classNames('mt-1 block')
    }),
    closeButton: {
        className: classNames('w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out', 'ml-auto overflow-hidden relative', 'flex items-center justify-center', 'hover:bg-white/30')
    },
    transition: {
        classNames: {
            enter: '-translate-y-0',
            enterActive: 'translate-y-4 duration-300 ease-in-out',
            enterDone: 'translate-y-4',
            exit: '-translate-y-0',
            exitActive: '-translate-y-4 duration-300 ease-in-out',
            exitDone: '-translate-y-0'
        },
        addEndListener: (node, done) => {
            node.addEventListener('transitionend', () => {
                consoleWrap.log("transitionend");
                done();
            }, false);
        }
    }
}
