import {classNames} from "primereact/utils";
import {SplitButtonPassThroughMethodOptions, SplitButtonPassThroughOptions} from "primereact/splitbutton";

const TRANSITIONS = {
    overlay: {
        enterFromClass: 'opacity-0 scale-75',
        enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
        leaveActiveClass: 'transition-opacity duration-150 ease-linear',
        leaveToClass: 'opacity-0'
    }
};

export const splitbutton: SplitButtonPassThroughOptions = {
        root: ({ props } : SplitButtonPassThroughMethodOptions) => ({
            className: classNames('inline-flex relative', 'rounded-md', { 'shadow-lg': props.raised })
        }),
        button: {
            root: ({ props } : SplitButtonPassThroughMethodOptions) => ({
                className: classNames(' rounded-r-none border-r-0', { 'rounded-l-full': props.rounded })
            }),
        },
        menu: {
            className: classNames('outline-none', 'm-0 p-0 list-none')
        },

        menuLabel: { className: 'text-sm bg-white rounded-md text-black-700 !important' },
        menuItem: { className: 'text-sm bg-white rounded-md text-black-700 !important' },

}
