import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import React, { FormEvent, MouseEvent, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { FieldArray, Formik, FormikHelpers, FormikValues } from "formik";
import { ModalFieldLabel } from "../../Modal/ModalFieldLabel.tsx";
import { RepeatableInputFormGroup } from "./RepeatableInputFormGroup.tsx";
import { RepeatableInputPanel } from "./RepeatableInputPanel.tsx";
import { ExecutableLinkFormInput } from "./ExecutableLinkFormInput.tsx";
import { FormikProps } from "formik/dist/types";
import { SelectItem } from "primereact/selectitem";
import { ToolDocumentationFormInput } from "./ToolDocumentationFormInput.tsx";
import { IPipelineInformationData } from "../../../store/slices/Builder/BuilderPipelineInfoState.ts";
import { useInitializeTree } from "../../../hooks/pipeline-builder/useInitializeTree.ts";
import { BuilderSaveDraftState, setBuilderSaveDraft } from "../../../store/slices/Builder/BuilderSaveDraftState.ts";
import { useDispatch } from "react-redux";
import { ErrorField } from "../../ErrorField/ErrorField.tsx";
import { IFormInitializationValue } from "../../../hooks/pipeline-builder/useInitializeForm.ts";
import { IExecutableLink } from "../../../models/IExecutableLink.ts";
import {Tooltip} from "primereact/tooltip";

interface IPipelineInfoModalProps {
    onHide: () => void;
    visible: boolean;
    assayTypes: IFormInitializationValue[];
    institutionOptions: IFormInitializationValue[];
    dataTypes: IFormInitializationValue[];
}

export function PipelineInfoModal(props: IPipelineInfoModalProps) {

    // Refs
    const formRef = useRef<FormikProps<FormikValues>>(null);


    const {
        getInitializedTreeData,
        data: initializedTreeData,
    } = useInitializeTree();

    // Navigation
    const navigate = useNavigate();

    const dispatch = useDispatch();

    // When the initialized tree response data changes, initialize the draft data in the state
    useEffect(() => {
        if (initializedTreeData) {
            // Initialize the draft data with the initialized tree data
            // In this, we copy all the data from the results of the initialize tree endpoint, but we add and set the info property of each node to null
            const initialDraftData: BuilderSaveDraftState =  {
                pipeline: {
                    ...initializedTreeData.pipeline,
                },
                graph: {
                    ...initializedTreeData.graph,
                    nodes: initializedTreeData.graph.nodes.map((node: any) => {
                        return {
                            ...node,
                            info: null
                        }
                    })
                },
                _id: initializedTreeData._id,
                status: initializedTreeData.status,
                submittedBy: initializedTreeData.submittedBy,
            };
            dispatch(setBuilderSaveDraft(initialDraftData));
            postSubmissionProcess(initializedTreeData._id)
        }
    }, [initializedTreeData]);

    const validateForm = (values: any) => {
        const errors: any = {};
        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.datatype) {
            errors.datatype = "Required";
        }
        // Check if any assay types have been inputted
        if (values.assayTypes.length === 0) {
            errors.assayTypes = ["Required"];
        } else {
            // Check if any of the assay types are empty
            const assayTypeErrors = values.assayTypes.map((val: string) => {
                if (!val) {
                    return "Required";
                }
                return "";
            });
            if (assayTypeErrors.some((error: any) => error)) {
                errors.assayTypes = assayTypeErrors;
            }
        }
        if (values.executableLinks && values.executableLinks.length > 0) {
            const executableLinkErrors: any[] = values.executableLinks.map((link: IExecutableLink) => {
                const linkErrors: any = {};
                if (!link.executableLink || !link.linkType) {
                    if (!link.executableLink) {
                        linkErrors.executableLink = "Executable Link is required";
                    }
                    if (!link.linkType) {
                        linkErrors.linkType = "Executable Type is required";
                    }
                }
                return linkErrors;
            });

            if (executableLinkErrors.some(error => Object.keys(error).length > 0)) {
                errors.executableLinks = executableLinkErrors;
            }
        }

        return errors;
    }

    /**
     * Submits the form.
     * @param e - The mouse event.
     */
    const submitForm = (e: MouseEvent | FormEvent) => {
        e.preventDefault();
        if (formRef.current) {
            formRef.current.submitForm();
        }
    }

    /**
     * Handles the post submission process. This includes hiding the dialog and navigating to the build page.
     */
    const postSubmissionProcess = (draftId: string) => {
        navigate(`/builder?id=${draftId}&from=builder`);
        props.onHide();
    }

    /**
     * Handles the form submission.
     * @param values - The form values.
     * @param formik - The formik utility functions.
     */
    const handleFormSubmission = (values: FormikValues, formik: FormikHelpers<FormikValues>) => {
        // Filter out any empty executable links
        const filteredExecutableLinks = values.executableLinks.filter((link: IExecutableLink) => link.executableLink !== "" && link.linkType !== "");

        // Tell formik that the form has been submitted
        formik.setSubmitting(false);

        // Create the pipeline information data
        const data: IPipelineInformationData = {
            name: values.name,
            purpose: values.purpose,
            processDatatypes: values.assayTypes,
            version: values.version,
            documentation: values.documentation,
            executableLinks: filteredExecutableLinks,
            institution: values.institution,
            // This field was not on the original cobe form
            datatype: values.datatype,
            doi: values.doi,
        }

        // Initialize the draft with the pipeline information data.
        // This includes sending the initializeTree request and setting its response in the state.
        initializeDraft(data);
    }

    /**
     * Initializes the draft with the given pipeline information data.
     * @param initPipelineInfoData - The pipeline information data.
     */
    const initializeDraft = (initPipelineInfoData: IPipelineInformationData) => {
        const listOfInstitutions = initPipelineInfoData.institution.map((institution: string) => ({name: institution, link: ""}));
        getInitializedTreeData({
            pipeline: {
                name: initPipelineInfoData.name,
                datatype: [initPipelineInfoData.datatype],
                // This says "Process Data Type", but thats just because of old naming, it is really the assay type
                assayTypes: initPipelineInfoData.processDatatypes,
                link: initPipelineInfoData.documentation,
                version: initPipelineInfoData.version,
                doi: initPipelineInfoData.doi,
                // This is not the logged in user, but the institution list
                user: listOfInstitutions,
                main: {
                    executableLinks: initPipelineInfoData.executableLinks,
                    nodes: []
                },
                purposes: [initPipelineInfoData.purpose],
                subBranches: []
            }
        });
    }

    /**
     * Handles the form cancel.
     */
    const handleCancel = () => {
        props.onHide();
    }

    // Footer of the dialog
    const dialogFooter = (
        <div
            className="w-full h-full bg-white justify-end items-center gap-3 inline-flex">
            <Button
                data-testid={'cancelBtn'}
                className="px-3 !py-2 !bg-white !text-gray-800 font-inter rounded shadow border border-zinc-400 justify-center items-center gap-2.5 flex"
                onClick={ handleCancel }>
                Cancel
            </Button>
            <Button
                data-testid={'createPipelineBtn'}
                className="!py-2 !bg-blue-700 text-white font-inter rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex"
                onClick={ submitForm }>
                Create new pipeline
            </Button>
        </div>
    );

    return (
        <Dialog
            header="Create a new pipeline"
            visible={ props.visible }
            onHide={ props.onHide }
            closable={ false }
            footer={ dialogFooter }
            className="w-[50vw]"
            pt={ {
                header: { className: "border-b border-t border-gray-200" },
                content: { className: "max-h-[75vh] !overflow-y-scroll" },
                footer: { className: "border-t border-b border-gray-200 !py-4" }
            } }
        >
            <Formik
                initialValues={ {
                    name: "",
                    purpose: "",
                    // This field was not on the original cobe form
                    datatype: "Genomics Data",
                    version: "",
                    doi: "",
                    pipelineLink: "",
                    executableLinks: [],
                    institution: [],
                    // Originally labeled "Process Data Type"
                    assayTypes: [""],
                    // Originally "Link to Executable"
                    documentation: []
                } }
                validate={ validateForm }
                onSubmit={ handleFormSubmission }
                innerRef={ formRef }
            >
                { formik => (
                    <form
                        onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
                        onSubmit={ formik.handleSubmit }>
                        <div className="w-full flex-col justify-start gap-4 inline-flex items-stretch pt-5">

                            {/* Name input */ }
                            <div className="flex-col justify-start items-start gap-1 flex">
                                <ModalFieldLabel label={"Pipeline Name"} required={true} />
                                <InputText
                                    data-testid={'pipelineName'}
                                    placeholder="New Pipeline"
                                    {...formik.getFieldProps("name")}
                                    className={`self-stretch px-3 py-2.5 bg-white rounded border justify-start items-center gap-2.5 inline-flex ${formik.submitCount > 0 && formik.errors.name ? "border-red-500" : "border-zinc-400"}`}
                                    pt={{
                                        root: {
                                            className: "!text-gray-800"
                                        }
                                    }}
                                />
                                {formik.errors.name && formik.submitCount > 0 && (
                                    <ErrorField errorMessage={"Pipeline name is required"} />
                                )}
                            </div>

                            {/* Data Type dropdown */ }
                            <Tooltip target=".data-type-target-icon" style={{ boxShadow: 'none' }}/>
                            <div className="flex-col justify-start items-start gap-1 flex">
                                <div className="flex-row gap-1 align-middle flex">
                                    <ModalFieldLabel label={"Data Type"} required={true} />
                                    <i className="data-type-target-icon pi pi-info-circle"
                                       data-pr-tooltip="If you want to add another item to this list please email your request to admin@cobe.ca."
                                       data-pr-position="right"
                                       style={{ marginLeft: '2px', cursor: 'pointer' }}
                                    />
                                </div>
                                <Dropdown
                                    editable = {false}
                                    id='dataTypeName'
                                    inputId='dataTypeName'
                                    {...formik.getFieldProps("datatype")}
                                    options={ props.dataTypes }
                                    placeholder="Select a Datatype"
                                    className={`bg-white rounded border ${formik.submitCount > 0 && formik.errors.datatype ? "!border-red-500" : "border-zinc-400"}`}
                                />
                                {formik.errors.datatype && formik.submitCount > 0 && (
                                    <ErrorField errorMessage={"Data Type is required"} />
                                )}
                            </div>

                            {/* Link to Executables input */ }
                            <RepeatableInputPanel
                                name={'assayTypes'}
                                dataTestId={'assayTypesAddOptionBtn'}
                                headerLabel={ "Assay Types" }
                                onAddAnotherOption={ () => {
                                    formik.setFieldValue('assayTypes', [...formik.values.assayTypes, ''])
                                } }
                            >

                                <FieldArray name={ "assayTypes" }>
                                    { ({ remove, form }) => {
                                        // Filter out the options that have already been selected
                                        const filteredOptions = props.assayTypes.filter((option: SelectItem) => !form.values.assayTypes.includes(option.value) );

                                        return (
                                            formik.values.assayTypes.map((val: any, index: number) => {
                                                return (
                                                    <div key={`AssayTypeFormInput-${index}`}>
                                                        <RepeatableInputFormGroup
                                                            key={ `AssayTypeFormInput-${ index }` }
                                                            onDelete={ remove }
                                                            name="assayTypes"
                                                            text="Assay Type"
                                                            index={ index }
                                                            dropdownOptions={ filteredOptions }
                                                        />

                                                    </div>
                                                );
                                            })


                                        );
                                    } }
                                </FieldArray>
                            </RepeatableInputPanel>

                            {/* Purpose input */ }
                            <div className="h-16 flex-col justify-start items-start gap-1 flex">
                                <ModalFieldLabel label={ "Purpose" } />
                                <InputText
                                    data-testid={'purposeName'}
                                    placeholder="Purpose of the pipeline"
                                    { ...formik.getFieldProps("purpose") }
                                    className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                    pt={ {
                                        root: {
                                            className: "!text-gray-800"
                                        }
                                    } }
                                />
                            </div>

                            {/* Version input */ }
                            <div className="h-16 flex-col justify-start items-start gap-1 flex">
                                <ModalFieldLabel label={ "Version" }/>
                                <InputText
                                    data-testid={'version'}
                                    { ...formik.getFieldProps("version") }
                                    className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                    pt={ {
                                        root: {
                                            className: "!text-gray-800"
                                        }
                                    } }
                                />
                            </div>

                            {/* Pipeline Link input */ }
                            <RepeatableInputPanel
                                name={"executables"}
                                dataTestId={"executablesAddOptionBtn"}
                                headerLabel={ "Executables" }
                                onAddAnotherOption={ () => {
                                    formik.setFieldValue('executableLinks', [...formik.values.executableLinks, { linkType: "", executableType: "", default: false }])
                                } }
                            >
                                <FieldArray name={ "executableLinks" }>

                                    { ({ remove }) => {
                                        return (
                                            formik.values.executableLinks.map((val: any, index: number) => {
                                                return (
                                                    <div key={`ExecutableLinkFormInput-${index}`}>
                                                        <ExecutableLinkFormInput
                                                            name="executableLinks"
                                                            placeholder="Link to Executable"
                                                            onDelete={remove}
                                                            index={index}
                                                        />
                                                    </div>
                                                );
                                            })
                                        );
                                    } }
                                </FieldArray>

                            </RepeatableInputPanel>

                            <RepeatableInputPanel
                                name={"pipelineDocumentation"}
                                dataTestId={"pipelineDocumentationAddOptionBtn"}
                                headerLabel={ "Pipeline Documentation" }
                                onAddAnotherOption={ () => {
                                    formik.setFieldValue('documentation', [...formik.values.documentation, '']);
                                } }
                            >
                                <FieldArray name={ "documentation" }>
                                    { ({ remove }) => {
                                        return (
                                            formik.values.documentation?.map((val: any, index: number) =>{
                                                return (
                                                    <ToolDocumentationFormInput
                                                        key={ `ToolDocumentationFormInput-${ index }` }
                                                        onDelete={ remove }
                                                        name="documentation"
                                                        index={ index }
                                                    />
                                                // {...formik.submitCount > 0 && errors.toolDocumentation  &&
                                                // <ErrorField errorMessage={errors.toolDocumentation[0]} />
                                                // }
                                                );

                                            })

                                        );
                                    } }
                                </FieldArray>
                            </RepeatableInputPanel>

                            {/* DOI input */ }
                            <div className="h-16 flex-col justify-start items-start gap-1 flex">
                                <ModalFieldLabel label={ "DOI" }/>
                                <InputText
                                    data-testid={'doi'}
                                    { ...formik.getFieldProps("doi") }
                                    className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                    pt={ {
                                        root: {
                                            className: "!text-gray-800"
                                        }
                                    } }
                                />
                            </div>

                            {/* Institution dropdown */ }
                            <RepeatableInputPanel
                                name={"labGroups"}
                                dataTestId={"labGroupsAddOptionBtn"}
                                headerLabel={ "Lab Group/Institution" }
                                onAddAnotherOption={ () => {
                                    // Ensure that a new object with the necessary keys is added to the array
                                    formik.setFieldValue('institution', [...formik.values.institution, ""])
                                } }
                            >
                                <FieldArray name={ "institution" }>
                                    { ({ remove, form }) => {
                                        // Filter out the options that have already been selected
                                        const filteredOptions = props.institutionOptions.filter((option: SelectItem) => !form.values.institution.includes(option.value) );
                                        return (
                                            formik.values.institution.map((val: any, index: number) => {
                                                return (
                                                    <RepeatableInputFormGroup
                                                        key={ `InstitutionFormInput-${ index }` }
                                                        onDelete={ remove }
                                                        name="institution"
                                                        index={ index }
                                                        dropdownOptions={ filteredOptions }
                                                        text="Lab Group/Institution"
                                                    />
                                                );
                                            })
                                        );
                                    } }
                                </FieldArray>
                            </RepeatableInputPanel>
                        </div>
                    </form>
                ) }
            </Formik>
        </Dialog>
    );
}
