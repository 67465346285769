// TODO: rename this, node type is already a property, and its not these values
export enum NodeType {
    ORIGIN = "origin",
    DATATYPE = "datatype",
    SOFTWARE = "software",
    PYTHONSCRIPT = "pythonScript",
    RDB = "rDb",
    RLIBRARY = "rLibrary",
    RWORKFLOW = "rWorkflow",
    WEBSITE = "website",
    DATABASE = "database",
    UNSET = "",
}
