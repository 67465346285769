import { Handle, NodeProps, Position } from "reactflow";
import IMAGES from "../../../../assets/images/images.tsx";
import { ReactSVG } from "react-svg";
import useNodeService from "../../../../hooks/pipeline-builder/useNodeService.ts";

export enum AddLeafDecisionNodeIds {
    NODE_ID = "add-leaf-node",
    EDGE_ID = "add-leaf-node-edge",
    NODE_INPUT_HANDLE_ID = "input",
    NODE_TYPE = "addLeafDecisionNode"
}

export const addLeafDecisionNodeHeight = 60;

/**
 * When the initial position of the decision node is set, it is set to the position of the node that it is being added to.
 * This is the offset from that position to line up the node correctly.
 */
export enum AddLeafDecisionNodeCoordinateOffsets {
    X = 230,
    Y = -1
}

export interface AddLeafDecisionNodeProps {
    onNewToolClicked: (x: number, y: number) => void
    onNewPipelineClicked: () => void
    sourceNodeId?: string
}

/**
 * This is the node that appears when the user clicks the add handle on a node.
 * It allows the user to add a new tool or pipeline.
 * @constructor
 */
export const AddLeafDecisionNode = (props: NodeProps<AddLeafDecisionNodeProps>) => {

    const { onNewToolClicked } = useNodeService();

    /**
     * Handles when the new tool button is clicked. Creates a new tool in the context via the useNodeService hook.
     */
    const onNewToolClickedHandler = () => {
        onNewToolClicked(props.xPos, props.yPos);
    }

    return (
        <>
            <div
                // Use this if pipeline button is ever added back
                // className={ `w-44 h-[${ addLeafDecisionNodeHeight }px] rounded-md shadow justify-start items-start inline-flex` }>

                className={ `w-20 h-[${ addLeafDecisionNodeHeight }px] rounded-md shadow justify-start items-start inline-flex` }>
                <div
                    // Use this if pipeline button is ever added back
                    // className="h-full  w-1/2 rounded-l-md  p-2 bg-white  border-r border-gray-200 flex-col justify-start items-center gap-1.5 inline-flex cursor-pointer"

                    className="h-full  w-full rounded-md  p-2 bg-white  border-r border-gray-200 flex-col justify-start items-center gap-1.5 inline-flex cursor-pointer"

                    onClick={ onNewToolClickedHandler }>
                    <div className="w-5 h-5 relative">
                        <div className="w-5 h-5 left-0 top-0 absolute">
                            <ReactSVG src={ IMAGES.tool_icon_sm } className="text-neutral-800"/>
                        </div>
                    </div>
                    <div className="self-stretch text-center text-black text-xs font-normal font-inter leading-4">
                        Tool
                    </div>
                </div>
                {
                    // This is commented out because adding pipelines is not currently supported.
                    // When adding this back, make sure w-full is changed to w-1/2 in the div above.

                    //     <div
                    //     className="h-full w-1/2 rounded-r-md p-2 bg-white border-l border-gray-200 flex-col justify-start items-center gap-1.5 inline-flex cursor-pointer"
                    //     onClick={ props.data.onNewPipelineClicked }>
                    //     <div className="w-5 h-5 relative">
                    //         <div className="w-5 h-5 left-0 top-0 absolute">
                    //             <ReactSVG src={ IMAGES.pipeline_icon } className="text-neutral-800"/>
                    //         </div>
                    //     </div>
                    //     <div className="self-stretch text-center text-black text-xs font-normal font-inter leading-4">
                    //         Pipeline
                    //     </div>
                    // </div>
                }
            </div>
            <Handle
                type="target"
                position={ Position.Left }
                id={ AddLeafDecisionNodeIds.NODE_INPUT_HANDLE_ID }
                hidden={ true }/>
        </>
    );
};
