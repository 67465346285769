import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import { Handle, NodeProps, Position } from "reactflow";
import React from "react";
import { NodeType } from "../../../models/Builder/NodeTypeEnum.ts";

export type ToolNodeData = {
    // The main label on the node
    label: string;
    // The by-line on the node
    externalLinkLabel: string;
    // ID of the node
    id: string;
    // ID of the node from MongoDB
    _id: string;
    // Whether the anchors are connectable
    anchorsConnectable: boolean;
    // Whether the node is clickable
    nodeClickable: boolean;
    isSelected?: boolean;
    // TODO: Implement this properly
    inProgress?: boolean;
    nodeType: NodeType;
}

interface NodeDecoration {
    icon: string;
    icon_container_style: string;
}

const githubNodeDecoration: NodeDecoration = {
    icon: IMAGES.github_icon,
    icon_container_style: 'bg-black-900'
}

const codeOceanNodeDecoration: NodeDecoration = {
    icon: IMAGES.codeocean_icon,
    icon_container_style: 'bg-sky-500'
}

const hpcNodeDecoration: NodeDecoration = {
    icon: IMAGES.hpc_icon,
    icon_container_style: 'bg-accent_teal'
}

const defaultNodeDecoration: NodeDecoration = {
    icon: IMAGES.tool_icon_lg,
    icon_container_style: 'bg-accent_green'
}

const dataTypeNodeDecoration: NodeDecoration = {
    icon: IMAGES.bar_chart_icon,
    icon_container_style: 'bg-indigo-500'
}

export const ToolNode = (props: NodeProps<ToolNodeData>) => {

    const getNodeDecoration = (toolTypeLabel: string) => {
        // Switch statement for toolTypeLabel with options for Github, CodeOcean and default
        switch (toolTypeLabel) {
            case "Github":
                return githubNodeDecoration;
            case "CodeOcean":
                return codeOceanNodeDecoration;
            case "HPC":
                return hpcNodeDecoration;
            case "Data Type":
                return dataTypeNodeDecoration;
            default:
                return defaultNodeDecoration;
        }
    }

    const nodeDecoration = getNodeDecoration(props.data.externalLinkLabel);

    return (
        <>

            {/* -m-[10px] is used because the border of this div, and the one nested directly below it offset the position of the node.
                So we need to offset the margin by the same total amount to keep it in the same position. border-8 + border-2 = 10px */}
            <div className={ props.data.isSelected ? `-m-[10px] rounded-md border-8 border-blue-700 border-opacity-0 bg-blue-700 bg-opacity-20 overflow-clip` : "" }>
                <div className={ props.data.isSelected ? `self-stretch rounded-md border-2 border-blue-700 bg-blue-700` : "" }>
                    <div className={ `w-72 p-3 bg-white rounded-md shadow border border-gray-200 justify-center items-start gap-2 inline-flex ${props.data.nodeClickable ? "cursor-pointer" : ''}` }>
                        <div className={`${nodeDecoration.icon_container_style} p-1.5 rounded border border-black border-opacity-20 justify-center items-center gap-2.5 flex`}>
                            <div className="w-6 h-6 relative">
                                <div className="w-6 h-6 left-0 top-0 absolute text-white">
                                    <ReactSVG
                                        src={nodeDecoration.icon}
                                        beforeInjection={(svg: SVGSVGElement) => {
                                            svg.setAttribute('style', 'width: 100%; height: 100%;');
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                            <div className="self-stretch text-neutral-800 text-sm font-semibold font-inter leading-none">{props.data.label}</div>
                            <div className="self-stretch text-neutral-500 text-xs font-normal font-inter leading-[18px]">{props.data.externalLinkLabel}</div>
                        </div>
                        {
                            props.data.inProgress &&
                            <div className="w-6 h-6 relative">
                                <div className="w-6 h-6 left-0 top-0 absolute">
                                    <ReactSVG src={ IMAGES.progress_icon }/>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <Handle type="target" position={ Position.Left } id="input" isConnectable={props.data.anchorsConnectable} />
            <Handle type="source" position={ Position.Right } id="output" isConnectable={props.data.anchorsConnectable} />
        </>
    );
};
