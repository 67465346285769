import { useAxios } from "../useAxios.tsx";
import { UpdateStatusResponse } from "../../models/Builder/UpdateStatusResponse.ts";
import { doRefreshToken } from "../useRefreshToken.tsx";

type UpdateDraftStatusAxiosResponse = {
    data: UpdateStatusResponse | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useSubmitDraft = () => {

    const { commonFetch, isLoading, data, error }: UpdateDraftStatusAxiosResponse = useAxios({
        url: `/view/pipeline-builder/submit`,
        method: "POST",
    });

    const postSubmitDraft = async (pipelineId: string) => {
        await doRefreshToken()
        await commonFetch({ input: { pipelineId } })
    };

    return { postSubmitDraft, isLoading, data, error };
}
