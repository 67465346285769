import React, { useRef, useState } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { confirmDialog } from "primereact/confirmdialog";
import { DeleteConfirmDialog } from "../Dialogs/DeleteConfirmDialog.tsx";
import { formatDateTime } from "../../helpers/utils/formatDateTime.ts";
import { IToolTableDataResponse } from "../../hooks/useGetToolTableData.tsx";


export interface IToolTableProps {
    data: IToolTableDataResponse[];
    onToolDelete: (toolId: string) => void;
    onToolNameClick: (toolData: IToolTableDataResponse) => void;
}

const formatList = (list: string[]) => {
    return list.join(', ');
}

export const ToolTable: React.FC<IToolTableProps> = (props: IToolTableProps) => {
    const { onToolDelete } = props;
    const cm = useRef<ContextMenu | null>(null);
    const [selectedTool, setSelectedTool] = useState<IToolTableDataResponse | null>(null);

    const deleteToolConfirm = (tool: IToolTableDataResponse) => {
        confirmDialog({
            message: 'Are you sure you want to delete this? This action cannot be undone',
            header: 'Delete?',
            defaultFocus: 'accept',
            acceptLabel: 'Yes, delete tool',
            rejectLabel: 'Cancel',
            closable: false,
            accept: () => onToolDelete(tool.id),
            reject: () => {}
        });
    }

    const menuModel = [
        { label: 'Delete', icon: 'pi pi-fw pi-times', command: () => selectedTool && deleteToolConfirm(selectedTool) }
    ];

    const toolNameTemplate = (row: IToolTableDataResponse) => {
        return (
            <span
                onClick={() => props.onToolNameClick(row)}
                className="hover:underline cursor-pointer text-link"
            >
            {row.name}
        </span>
        );
    };

    return (
        <>
            <DeleteConfirmDialog />
            <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedTool(null)} />
            <DataTable
                onContextMenu={ (e) => cm.current?.show(e.originalEvent) }
                contextMenuSelection={ selectedTool ? [selectedTool] : {} }
                onContextMenuSelectionChange={ (e) => setSelectedTool(e.value) }
                value={props?.data}
            >
                <Column
                    className="px-6 py-4 text-black-900 text-table_text cursor-pointer"
                    field="name"
                    header="Name"
                    body={toolNameTemplate}

                />
                <Column className="px-6 py-4 text-black-900 text-table_text" field="input" header="Input" body={(rowData) => formatList(rowData.input)} />
                <Column className="px-6 py-4 text-black-900 text-table_text" field="output" header="Output" body={(rowData) => formatList(rowData.output)} />
                <Column className="px-6 py-4 text-black-900 text-table_text" field="editedOn" header="Edited On" body={(rowData) => formatDateTime(rowData.editedOn)} />
                <Column className="px-6 py-4 text-black-900 text-table_text" field="createdOn" header="Created On" body={(rowData) => formatDateTime(rowData.dateSubmitted)} />
            </DataTable>
        </>
    );
};