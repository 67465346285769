import {ConfirmDialogPassThroughMethodOptions, ConfirmDialogPassThroughOptions} from "primereact/confirmdialog";
import {classNames} from "primereact/utils";

export const confirmdialog: ConfirmDialogPassThroughOptions = {
    root: {
        className: classNames(
            'bg-white text-gray-700 border-0 rounded-md shadow-lg !w-[360px]',
            'z-40 transform origin-center',
            'mt-3 absolute left-0 top-0',
            'before:absolute before:w-0 before:-top-3 before:h-0 before:border-transparent before:border-solid before:ml-6 before:border-x-[0.75rem] before:border-b-[0.75rem] before:border-t-0 before:border-b-white dark:before:border-b-gray-900',
            'dark:border dark:border-blue-900/40 dark:bg-gray-900  dark:text-white/80'
        )
    },
    content: {
        className: 'p-5 items-center flex',
    },
    icon: {
        className: 'text-2xl',
    },
    message: {
        className: 'ml-4',
    },
    footer: {
        className: 'flex gap-2 justify-end align-center text-right px-5 py-5 pt-0',
    },
    transition: ({ props }: ConfirmDialogPassThroughMethodOptions) => {
        return props.position === 'top'
            ? {
                enter: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                enterActive: 'transition-all duration-200 ease-out',
                exitActive: 'transition-all duration-200 ease-out',
                exit: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                addEndListener: (node, done) => {
                    node.addEventListener('transitionend', done, false);
                }
            }
            : props.position === 'bottom'
                ? {
                    enter: 'opacity-0 scale-75 translate-y-full',
                    enterActive: 'transition-all duration-200 ease-out',
                    exitActive: 'transition-all duration-200 ease-out',
                    exit: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
                    addEndListener: (node, done) => {
                        node.addEventListener('transitionend', done, false);
                    }
                }
                : props.position === 'left' || props.position === 'top-left' || props.position === 'bottom-left'
                    ? {
                        enter: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                        enterActive: 'transition-all duration-200 ease-out',
                        exitActive: 'transition-all duration-200 ease-out',
                        exit: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
                        addEndListener: (node, done) => {
                            node.addEventListener('transitionend', done, false);
                        }
                    }
                    : props.position === 'right' || props.position === 'top-right' || props.position === 'bottom-right'
                        ? {
                            enter: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                            enterActive: 'transition-all duration-200 ease-out',
                            exitActive: 'transition-all duration-200 ease-out',
                            exit: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                            addEndListener: (node, done) => {
                                node.addEventListener('transitionend', done, false);
                            }
                        }
                        : {
                            enter: 'opacity-0 scale-75',
                            enterActive: 'transition-all duration-200 ease-out',
                            exitActive: 'transition-all duration-200 ease-out',
                            exit: 'opacity-0 scale-75',
                            addEndListener: (node, done) => {
                                node.addEventListener('transitionend', done, false);
                            }
                        };
    }
}
