import React, {PropsWithChildren} from "react";
import {ApiWrapperError} from "./ApiWrapperError.tsx";
import {ApiWrapperLoading} from "./ApiWrapperLoading.tsx";

export type ApiWrapperProps = {
    isLoading: boolean;
    error: any;
    data: any;
    loadingLabel: string;
    errorLabel: string;
}

export const ApiWrapper = (props: PropsWithChildren<ApiWrapperProps>) => {
    const {
        isLoading,
        error,
        data,
        children,
        loadingLabel,
        errorLabel
    } = props;

    let content;
    if (error) {
        if (error.response.status === 401) {
            // Redirect to login page
            window.location.href = '/login';
        } else {
            content = <ApiWrapperError label={ errorLabel } />;
        }
    } else if (isLoading) {
        content = <ApiWrapperLoading loadingLabel={loadingLabel} />;
    } else {
        content = children;
    }

    return (
        <>
            {content}
        </>
    );
};
