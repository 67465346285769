import {useAxios} from "./useAxios.tsx";

export interface ToolUser {
    _id: string;
    name: string;
    link: string;
    __v: number;
}

export interface GetToolUsersAxiosResponse {
    data: ToolUser[];  // The data is an array of ToolUser objects
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetToolUsers = () => {
    const { commonFetch, isLoading, data, error }: GetToolUsersAxiosResponse = useAxios<any>({
        url: "/data/tooluser/list",  // Correct URL matching the controller
        method: "GET",
    });

    const getToolUsers = async () => {

        // Call commonFetch with an empty object since no additional options are needed for this GET request
        await commonFetch({});

    };

    return { getToolUsers, isLoading, data, error };
};
