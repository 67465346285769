import { useAxios } from "./useAxios.tsx";
import { DocumentLikeDataType } from "../models/DocumentLikeDataType.ts";

export interface IGetExecutableLikesForPipelineResponse {
    data: DocumentLikeDataType | null,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetExecutableLikesForPipeline = (pipelineId: string) => {

    const { commonFetch, isLoading, data, error }: IGetExecutableLikesForPipelineResponse = useAxios<DocumentLikeDataType | null>({
        url: `/data/like/executable_likes/pipeline/${pipelineId}`,
        method: "GET",
    });

    const getExecutableLikesForPipeline = async () =>  await commonFetch({});
    return { getExecutableLikesForPipeline, isLoading, data, error }
}
