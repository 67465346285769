import { useState } from "react";
import axios from 'axios';

// const BASE_URL = "http://localhost:2001/api";
const BASE_URL = import.meta.env.VITE_BASE_API_URL;

console.log('BASE_URL', BASE_URL);

type UseFetchProps = {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE"
};

type CommonFetch = {
    /** the variables that the endpoint expects to receive */
    input?: { [index: string]: any };
    /** this allows you to override any default fetch options on a
     case by case basis. think of it like an escape hatch. */
    queryParameters?: { [index: string]: any };
}

export function useAxios<T> ({ url, method }: UseFetchProps) {
    const [isLoading, setIsLoading] = useState(false);
    // we are assigning the generic type T to our data value here
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState(null);

    /**
     * This function is used to make a fetch request to the server
     * @param input - payload to send to the server
     * @param queryParameters - Query Params
     * @param signal - Signal used to abort request. Used for cleanup in useEffects
     */
    const commonFetch = async ({
                                   input,
                                   queryParameters,
                                   signal
                               }: CommonFetch & { signal?: AbortSignal}) => {
        setIsLoading(true);

        try {
            const response = await axios.request({
                method,
                baseURL: BASE_URL,
                url,
                data: method === 'GET' ? null : input,
                params: queryParameters,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('cobe-accessToken'),
                },
                signal
            });
            setData(response.data);
        } catch (error: any) {
            setIsLoading(false);
            setError(error)
        }
        finally {
            setIsLoading(false);
        }

    };

    return { isLoading, commonFetch, data, error };
};