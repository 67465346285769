import {ReactSVG} from "react-svg";
import {SplitButton} from 'primereact/splitbutton';
import React, {useEffect, useRef, useState} from "react";
import {consoleWrap} from "../../main.tsx";
import {orderExecutableLinks, getDefaultExecutableLink} from "../../helpers/ExecutableLinks/ExecutableLinksHelpers.ts";
import {IExecutableLink} from "../../hooks/useSearch.tsx";

export const CodeLinkSplitButton = ({ executableLinks }: { executableLinks: IExecutableLink[] | null}) => {
    const executableLinkOptions = executableLinks ? orderExecutableLinks(executableLinks) : executableLinks;

    const url = getDefaultExecutableLink(executableLinkOptions);
    const isCodeOcean = url?.includes("codeocean.com");
    const isCodeOceanPMVPC = url?.includes("pmcodeocean.ca");
    const isGitHub= url?.includes("github.com");
    const icon = isCodeOcean ? "/images/codeocean-icon.svg" : isCodeOceanPMVPC ? "/images/codeocean-icon.svg" : isGitHub ? "/images/github-icon.svg" : "/images/webapp-icon.svg";
    const text = isCodeOcean ? "Open in Code Ocean" : isCodeOceanPMVPC ? "Open in PM-Code Ocean" : isGitHub ? "Open in GitHub" : "Open in WebApp";
    const excludedLinkType = isCodeOcean ? "CodeOcean" : isCodeOceanPMVPC ? "CodeOceanVPC" : isGitHub ? "Github" : "WebApp";

    const splitBtnRef = useRef<SplitButton | null>(null);

    useEffect(() => {
        // Get the element with the id 'pipeline-detail-page'
        const detailPageElement: HTMLElement | null = document.getElementById('pipeline-detail-page');
        // Get the current scroll position of the detail page element
        const scrollPosition = detailPageElement?.scrollTop;

        // Define a function to handle the scroll event
        const handleScroll = () => {
            if (splitBtnRef.current) {
                try {
                    // Hide the split button when the detail page is scrolled
                    splitBtnRef.current.hide();
                } catch (e) {}
            } else {
                // Log a message if splitBtnRef is not set
                consoleWrap.log("splitBtnRef is not set");
            }
        };

        // If the detail page element is found, add the scroll event listener
        if (detailPageElement) {
            detailPageElement.addEventListener('scroll', handleScroll);
        } else {
            // Log a message if the detail page element is not found
            consoleWrap.log("detailPageElement not found");
        }

        // Cleanup function to remove the scroll event listener when the component unmounts
        return () => {
            if (detailPageElement) {
                detailPageElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount


    const openUrlInNewTab = () => {
        window.open(url ? url : '', "_blank");
    }

    const openUrlInNewTabSplitBtn = (urlOption: any) => {
        window.open(urlOption, "_blank");
    }

    const IconComponent = ({ className, iconPath } : any) => {
        return (
            <img src={iconPath} className={className} />
        );
    };

    const passThroughProps = {
        button: {
            root: {
                className: 'inline-flex items-center space-x-1 px-1.5 py-1 border border-checkbox_border rounded-r-none text-sm leading-4 font-semibold text-black-700 bg-white hover:bg-gray-50 focus:outline-none h-[32px]'
            },
        },
        icon: {
            root: { className: 'text-sm text-black' },
        },
        menuButton: {
            root: {
                // Add a border, remove the border on the left side of the dropdown button
                className: 'inline-flex items-center px-1 py-2 border border-checkbox_border rounded-l-none text-sm leading-4 font-medium text-black-700 bg-white hover:bg-gray-50 focus:outline-none h-[32px]',
            },
        },
        menu: {
            root: {
                className: 'split-button-menu-index bg-white shadow-lg border border-checkbox_border rounded-md mt-1 py-1'
            },
        },
        menuItem: {
            className: 'flex items-center px-2 py-1 text-sm text-black-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-md',
            icon: { className: 'mr-4' },
            text: { className: 'font-semibold flex-1 pr-4' }
        },
    };

    if (!executableLinkOptions) {
        return;
    }

    if (executableLinkOptions.length === 0) {
        return (
            <div className="h-[48px] w-[207px] flex items-center">
                <div className="h-[32px] w-[161px] flex flex-row">
                </div>
            </div>
        )
    }

    else if (executableLinkOptions.length === 1) {
        return (
            <div className="h-[48px] w-[207px] flex items-center cursor-pointer" onClick={openUrlInNewTab}>
                <div className="h-[32px] ml-auto border border-solid rounded border-checkbox_border items-center shadow-sm px-2 pr-3 pl-2 flex flex-row">
                    <ReactSVG src={icon} className="text-black-900 mr-1" />
                    <div className="text-sm font-semibold w-max whitespace-nowrap">{text}</div>
                </div>
            </div>
        )
    }

    else if (executableLinkOptions.length > 1) {

        const splitButtonOptions = executableLinkOptions
            .map(function (obj : any) {
                const isCodeOcean = obj.executableLink?.includes("codeocean.com");
                const isCodeOceanPMVPC = obj.executableLink?.includes("pmcodeocean.ca");
                const isGitHub = obj.executableLink?.includes("github.com");
                const icon = isCodeOcean ? "/images/codeocean-icon.svg" : isCodeOceanPMVPC ? "/images/codeocean-icon.svg" : isGitHub ? "/images/github-icon.svg" : "/images/webapp-icon.svg";
                const text = isCodeOcean ? "Open in Code Ocean" : isCodeOceanPMVPC ? "Open in PM-Code Ocean" : isGitHub ? "Open in GitHub" : "Open in WebApp";

                return {
                    label: text,
                    icon: icon,
                    url: obj.executableLink,
                    linkType: obj.linkType, // Added this line to keep track of the linkType
                    command: () => {
                        window.open(obj.executableLink, "_blank");
                    }
                };
            })
            .filter((obj: any) => obj.linkType !== excludedLinkType) // This will filter out the excluded linkType

        return (
            <div className="relative flex items-center h-[48px]">
                <SplitButton
                    ref={splitBtnRef}
                    unstyled={true}
                    pt={passThroughProps}
                    label={text}
                    icon={<IconComponent className="h-6 w-6" iconPath={icon} />}
                    model={splitButtonOptions.map((option: any) => ({
                        ...option,
                        command: () => { openUrlInNewTabSplitBtn(option.url); },
                        template: (item: any) => (
                            <div className={`flex items-center space-x-2 ${passThroughProps.menuItem.className}`}>
                                <IconComponent className="h-6 w-6" iconPath={item.icon} />
                                <span>{item.label}</span>
                            </div>
                        )
                    }))}
                    onClick={openUrlInNewTab}
                />
            </div>
        );
    }
};
