import ReactDOM from 'react-dom/client'
import './index.css'
import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import "./styles/tailwind.css";
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from "primereact/api";
import { CoBeDesignSystem } from "./cobe-design-system/cobe-design-system.ts";
import ExplorePagePage, { ExploreDefaultPage } from "./pages/Home";
import { PipelineDetailPage } from "./pages/Home/PipelineDetailPage.tsx";
import { ToolDetailPage } from "./pages/Home/ToolDetailsPage.tsx";
import { BuildPage } from "./pages/Build/BuildPage.tsx"
import { Login } from "./pages/Login/Login.tsx";
import { PipelineExpandedDiagramPage } from "./pages/Home/PipelineExpandedDiagramPage.tsx";
import { Builder } from "./pages/Home/Builder.tsx";
import { Provider } from "react-redux";
import { store } from "./store/store.ts";
import {SubmissionPage} from "./pages/Admin/SubmissionPage.tsx";
import {LearnPage} from "./pages/Learn/LearnPage.tsx";

export type ConsoleWrapType = typeof console;

export let consoleWrap: ConsoleWrapType;

if (import.meta.env.VITE_ENV === 'development') {
    consoleWrap = window.console;
} else {
    consoleWrap = {
        log: () => {},
        error: () => {},
        warn: () => {},
        info: () => {},
        table: () => {},
        // add other console methods if needed
    } as ConsoleWrapType;
}

export const router = createBrowserRouter([
    {
        path: "/",
        element: <ExplorePagePage />,
        children: [
            {
                path: '/',
                element: <ExploreDefaultPage />,
            },
            {
                path: 'pipeline/:id',
                element: <PipelineDetailPage />
            },
            {
                path: 'pipeline/:id/expanded',
                element: <PipelineExpandedDiagramPage />,
            },
            {
                path: 'tool/:id',
                element: <ToolDetailPage />,
            }
        ]
    },
    {
        path: '/build',
        element: <ExplorePagePage />,
        children: [
            {
                path: '/build',
                element: <BuildPage/>,
            }
        ]
    },
    {
        path: '/learn',
        element: <ExplorePagePage />,
        children: [
            {
                path: '/learn',
                element: <LearnPage />,
            }
            ]
    },
    {
        path: '/admin',
        element: <ExplorePagePage />,
        children: [
            {
                path: '/admin',
                element: <SubmissionPage/>,
            }
        ]
    },
    {
        path: '/builder',
        element: <ExplorePagePage />,
        children: [
            {
                path: '/builder',
                element: <Builder />,
            }
        ]
    },
    {
        path: "/login",
        element: <Login />,
    }
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <PrimeReactProvider value={{ unstyled: true, pt: CoBeDesignSystem }}>
                <RouterProvider router={router} />
            </PrimeReactProvider>
        </Provider>
    </React.StrictMode>,
)
