import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, Link  } from '@react-pdf/renderer';
import {INodeInfo, IInfoNode} from "../models/Graph/IInfoNode.ts";
import {getNodes} from "../helpers/PipelineNodeDetailList/NodeDetailListHelpers.ts";
import {toolType} from "../pages/Home/PipelineDetailPage.tsx";
import {IFormattedData} from "../models/IFormattedData.ts";
import { pdfStyles as styles } from './styles/pdfStyles.ts';
import cobeLogo from "../assets/images/cobe-horizontal-logo.png";

interface PipelineNodeDetailListProps {
    formattedData: IFormattedData;
    id: string;
}

export const PipelinePDF = (props: PipelineNodeDetailListProps) => {
    const { pipelineNodes, forks } = getNodes(props.formattedData.pipelines[0], props.formattedData.graph);
    const pipeline = props.formattedData.pipelines[0].main;
    const users = props.formattedData.pipelines[0].user || [];
    const pipelineName = props.formattedData.pipelines[0].name;
    const labName = users.map(user => user.name).join(", ") || 'Unknown Lab';

    const pipelineUrl = `${window.location.origin}/pipeline/${props.id}/`;

    const getFormattedNode = (node: IInfoNode) => {
        if (toolType.includes(node.type)) {
            return ToolNode(node);
        } else {
            return FileNode(node);
        }
    };

    const ToolNode = (node: IInfoNode) => (
        <View style={styles.section} wrap={false}>
            <Text style={styles.title}>
                {node.name} {node.info.version ? `(${node.info.version})` : ''}
            </Text>
            <ToolInfoView toolInfo={node.info} />
        </View>
    );

    const ToolInfoView = ({ toolInfo }: { toolInfo: INodeInfo }) => {
        const links = toolInfo?.executableLinks;
        return (
            <View>
                {toolInfo.keywords?.length > 0 && (
                    <ToolInfoViewLine label="Keywords" value={toolInfo.keywords.join(', ')} />
                )}
                <ToolInfoViewLine label="Purpose" value={toolInfo.purpose || 'Unavailable'} />
                <ToolInfoViewLine label="Input" value={toolInfo.input?.join(', ') || 'Unavailable'} />
                <ToolInfoViewLine label="Output" value={toolInfo.output?.join(', ') || 'Unavailable'} />

                {/* Executable Links */}
                {links.length > 0 ? (
                    <View style={styles.executableSection}>
                        <Text style={styles.executableLabel}>Executables:</Text>
                        {links.map((link, index) => (
                            <Text key={index} style={link.executableLink ? styles.listItem : {}}>
                                {link.executableLink ? `• ${link.executableLink}` : 'Unavailable'}
                            </Text>
                        ))}
                    </View>
                ) : (
                    <Text style={styles.executableLabel}>Executables: Unavailable</Text>
                )}

                <ToolInfoViewLine
                    label="Link"
                    value={
                        toolInfo.link ? (
                            <Text style={styles.linkText}>{toolInfo.link}</Text>
                        ) : 'Unavailable'
                    }
                />
                <ToolInfoViewLine
                    label="DOI"
                    value={
                        toolInfo.doi ? (
                            <Text style={styles.linkText}>{toolInfo.doi}</Text>
                        ) : 'Unavailable'
                    }
                />
            </View>
        );
    };

    const ToolInfoViewLine = ({ label, value }: { label: string; value: any }) => (
        <View style={styles.content}>
            <Text>
                <Text style={{ fontWeight: 'bold' }}>{label}:</Text> {value}
            </Text>
        </View>
    );

    const FileNode = (node: IInfoNode) => (
        <View style={styles.section}>
            <Text>
                [Data] <Text style={{ fontWeight: 'bold' }}>{node.name}</Text>
            </Text>
        </View>
    );

    const pages = [];

    pages.push(
        <Page key="unique_key_1" size="A4" style={styles.page}>
            {/* Header Section */}
            <View style={styles.header}>
                <View style={styles.titleSection}>
                    <Link src={pipelineUrl} style={styles.headerTitleLink}>
                        <Text style={styles.headerTitle}>{pipelineName}</Text>
                    </Link>
                    <Text style={ styles.purposeText }>
                        Purpose: { props.formattedData.pipelines[0].purposes.map(purpose => purpose.text).join('; ') }
                    </Text>
                    <Text style={styles.labInfoText}>{labName}</Text>
                </View>
                <Image src={cobeLogo} style={styles.logo} />
                <View style={styles.linkContainer}>
                    {pipeline.executableLinks && pipeline.executableLinks.length > 0 ? (
                        pipeline.executableLinks.map((link, index) => (
                            <Text key={index} style={styles.linkButton}>{link.executableLink}</Text>
                        ))
                    ) : (
                        <Text style={styles.noLinkButton}>No executable link available</Text>
                    )}
                </View>
            </View>

            {/* Main Content */}
            <View>
                {pipelineNodes.map((node: IInfoNode, idx: number) => (
                    <View key={idx}>{getFormattedNode(node)}</View>
                ))}
            </View>
            {/*{forks.map((fork, j) => (*/}
            {/*    <View key={`fork_${j}`} style={styles.section} wrap={false}>*/}
            {/*        <Text style={styles.subtitle}>*/}
            {/*            FORK {j + 1}: {fork.title}*/}
            {/*        </Text>*/}
            {/*        <View>*/}
            {/*            {fork.nodes.map((node: IInfoNode, idx: number) => (*/}
            {/*                <View key={idx}>{getFormattedNode(node)}</View>*/}
            {/*            ))}*/}
            {/*        </View>*/}
            {/*    </View>*/}
            {/*))}*/}
        </Page>
    );

    return (
        <Document>
            {pages}
        </Document>
    );
};
