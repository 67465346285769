import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column, ColumnPassThroughMethodOptions } from 'primereact/column';
import 'primeicons/primeicons.css';
import { Tag } from "primereact/tag";
import { classNames } from "primereact/utils";
import { consoleWrap } from "../../main.tsx";
import { ContextMenu } from "primereact/contextmenu";
import { useDeleteDraft } from "../../hooks/pipeline-builder/useDeleteDraft.ts";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { DeleteConfirmDialog } from "../Dialogs/DeleteConfirmDialog.tsx";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";
import { setBuilderMetaReadOnly } from "../../store/slices/Builder/BuilderMeta.ts";
import { PipelineDraftStatusTag } from "../PipelineDraft/PipelineDraftStatusTag.tsx";

export interface IPipelineTableRowData {
    id: string;
    name: string;
    status: DraftStatus;
    dataType: string;
    assayTypes: string;
    nodes: string;
    editedOn: string;
    createdOn: string;
}

interface IPipelineTableProps {
    data: IPipelineTableRowData[];
    onPipelineDelete: (pipelineId: string) => void;
}

export const PipelineTable: React.FC<IPipelineTableProps> = (props: IPipelineTableProps) => {
    const { onPipelineDelete } = props;
    const toast = useRef<Toast>(null);
    const cm = useRef<ContextMenu | null>(null);
    const [selectedPipeline, setSelectedPipeline] = useState({});

    const dispatch = useDispatch();

    const {
        deleteDraft,
        isLoading: deleteDraftIsLoading,
        error: deleteDraftError,
        data: deleteDraftResponse
    } = useDeleteDraft()

    const nav = useNavigate();

    useEffect(() => {
        if (deleteDraftResponse) {
            onPipelineDelete(deleteDraftResponse.pipelineId);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Pipeline deleted successfully' });
        }
    }, [deleteDraftResponse]);

    useEffect(() => {
        if (deleteDraftError) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete pipeline' });
        }
    }, [deleteDraftError]);

    const navToPipelineViewer = (pipelineRow: IPipelineTableRowData) => {
        nav(`/pipeline/${ pipelineRow.id }`)
    }

    const navToDraftEditor = (draftRow: IPipelineTableRowData) => {
        // Set the builder to read only if the draft is submitted
        dispatch(setBuilderMetaReadOnly(draftRow.status === DraftStatus.Submitted));
        nav(`/builder?id=${ draftRow.id }&from=builder`);
    };

    const deletePipeline = (pipeline: any) => {
        consoleWrap.log('Delete Pipeline', pipeline);
        if (pipeline.status === 'Approved') {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Unable to delete approved pipelines' });
        } else {
            confirmDialog({
                message: 'Are you sure you want to delete this? This action cannot be undone',
                header: 'Delete?',
                defaultFocus: 'accept',
                acceptLabel: 'Yes, delete pipeline',
                rejectLabel: 'Cancel',
                closable: false,
                accept: () => {
                    deleteDraft(pipeline.id)
                },
                reject: () => {

                }
            });
        }

    };

    const menuModel = [
        // { label: 'View', icon: 'pi pi-fw pi-search', command: () => viewPipeline(selectedPipeline) },
        { label: 'Delete', icon: 'pi pi-fw pi-times', command: () => deletePipeline(selectedPipeline) }
    ];

    const pipelineNameTemplate = (tableRow: IPipelineTableRowData) => {
        //consoleWrap.log(tableRow)
        // If the row is approved, this is a pipeline. Otherwise, it is a draft.
        const nameLinkFunc = tableRow.status === DraftStatus.Approved
            ? () => navToPipelineViewer(tableRow)
            : () => navToDraftEditor(tableRow);

        return (
            <div className="flex items-center">
                <span className="hover:underline cursor-pointer" onClick={ nameLinkFunc }>{ tableRow.name }</span>
            </div>
        );
    };

    return (
        <>
            <Toast ref={ toast }></Toast>
            <DeleteConfirmDialog/>
            <ContextMenu model={ menuModel } ref={ cm } onHide={ () => setSelectedPipeline({}) }/>
            <DataTable
                onContextMenu={ (e) => cm.current?.show(e.originalEvent) }
                contextMenuSelection={ selectedPipeline }
                onContextMenuSelectionChange={ (e) => setSelectedPipeline(e.value) }
                value={ props.data }
                pt={ {
                    column: {
                        headerCell: ({}: ColumnPassThroughMethodOptions) => {
                            classNames('border-0')
                        },
                        bodyCell: ({}: ColumnPassThroughMethodOptions) => {
                            classNames('p-2')
                        }
                    }
                } }>
                <Column className="px-6 py-4 text-black-900 text-table_text" body={ pipelineNameTemplate } field="name"
                        header="Name"></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text" field="status" header="Status"
                        body={(rowData) => <PipelineDraftStatusTag status={rowData.status} />}></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text " field="dataType"
                        header="Data Type"></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text" field="assayTypes"
                        header="Assay Types"></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text" field="nodes" header="Nodes"></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text" field="editedOn"
                        header="Edited On"></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text" field="createdOn"
                        header="Created On"></Column>
            </DataTable>
        </>


    );
};

//  <Button label="Submit" icon="pi pi-check" iconPos="right" />
