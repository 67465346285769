import { createSlice } from "@reduxjs/toolkit";
import { ISubmissionTableRowData } from "../../../hooks/pipeline-builder/useGetSubmissionTable.ts";

export type BuilderSubmittedPipelinesState = {
    rows: ISubmissionTableRowData[]
}

const initialState: BuilderSubmittedPipelinesState = {
    rows: []
}

/**
 * Slice for the builder meta state. Includes information on how the builder should be loaded.
 */
const BuilderSubmittedPipelines = createSlice({
    name: "BuilderSubmittedPipelines",
    initialState,
    reducers: {
        setBuilderSubmittedPipelines: (state, action) => {
            state.rows = action.payload;
        }
    }
});

export const {
    setBuilderSubmittedPipelines
} = BuilderSubmittedPipelines.actions;

export default BuilderSubmittedPipelines.reducer;
