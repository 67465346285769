import {useAxios} from "./useAxios.tsx";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {IRootState} from "../store/store.ts";
import {consoleWrap} from "../main.tsx";
import axios from "axios";

export const useRefreshToken = () => {
    const isLoggedIn = useSelector((state: IRootState) => state.auth.isLoggedIn)

    const { commonFetch, isLoading, data, error } = useAxios<any>({
        url: "auth/refreshToken",
        method: "GET",
    });

    const doRefreshToken = async () =>  await commonFetch({});

    // 15 minutes
    const TIMEOUT = 15 * 60 * 1000;

    useEffect(() => {
        let timeout;
        if (isLoggedIn) {
            timeout = setInterval(() => {
               doRefreshToken();
            }, TIMEOUT);
        } else {
            clearInterval(timeout);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (data) {
            const accessToken = data.access_token;
            localStorage.setItem('cobe-accessToken', accessToken);
        }
    }, [data]);

    return { doRefreshToken, isLoading, data, error };
};

export const doRefreshToken = async () => {
    const BASE_URL = import.meta.env.VITE_BASE_API_URL;
    const jwtToken = localStorage.getItem('cobe-accessToken');
    if (jwtToken) {
        // check if token has expired
        const token = jwtToken.split('.')[1];
        const decodedToken = JSON.parse(atob(token));
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
            // token has expired
            consoleWrap.log('Token has expired')
            try {
                const refreshTokenResponse = await axios.get(`${BASE_URL}/auth/refreshToken`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('cobe-accessToken'),
                    },
                });
                const accessToken = refreshTokenResponse.data.access_token;
                localStorage.setItem('cobe-accessToken', accessToken);
            } catch (e) {
                consoleWrap.log('Error refreshing token', e);
                localStorage.removeItem('cobe-accessToken');
                window.location.href = '/login';
            }
        }
    }
    return Promise.resolve();
}
