import { IPipeline } from "../../hooks/useSearch.tsx";
import { GraphWithNodeInfo } from "../../models/Graph/GraphWithNodeInfo.ts";
import { IInfoNode } from "../../models/Graph/IInfoNode.ts";

export interface IFork {
    title: string;
    nodes: IInfoNode[];
}

export const getNodes = (pipeline: IPipeline, graph: GraphWithNodeInfo): { forks: IFork[], pipelineNodes: IInfoNode[] } => {
    let forks: any[] = [];

    // get nodes that make up the selected pipeline
    const pipelineNodes = getFormattedNodes(pipeline.main.nodes, graph.nodes);
    pipeline.subBranches.forEach((branch: any) => {
        forks.push({
            ...branch,
            nodes: getFormattedNodes(branch.nodes, graph.nodes)
        });
    });
    pipeline.purposes.forEach((purpose: any) => {
        forks.push({
            title: purpose.text,
            nodes: getFormattedNodes(purpose.nodes, graph.nodes)
        });
    });

    return {
        pipelineNodes: pipelineNodes,
        forks: forks
    };
}

export const getFormattedNodes = (pathwayNodes: any, graphNodes: IInfoNode[]): IInfoNode[] => {
    let excluded = ['origin', 'datatype'];
    const pathwayNodeIds = pathwayNodes.map((pn: any) => pn.id);
    let formatted: IInfoNode[] = graphNodes
        .filter((node: IInfoNode) => pathwayNodeIds.includes(node.id))
        .map((node: IInfoNode) => ({
            ...node,
            index: pathwayNodes.findIndex((pn: any) => pn.id === node.id),
            info: {
                ...node.info,
                input: pathwayNodes.find((pn: any) => pn.id === node.id).input,
                output: pathwayNodes.find((pn: any) => pn.id === node.id).output,
            }
        }))
        .sort((a: any, b: any) => (a.index - b.index)) // sort by index in the order of appearence
    formatted = formatted.filter((n: any) => !excluded.includes(n.type));
    return formatted;
}
