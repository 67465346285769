import {useAxios} from "./useAxios.tsx";
import {useEffect} from "react";
import {login} from "../store/slices/authSlice.ts";
import {useDispatch} from "react-redux";
import {consoleWrap} from "../main.tsx";

export const useGoogleLogin = () => {
    const { commonFetch, isLoading, data, error } = useAxios<any>({
        url: `/auth/google/token`,
        method: "POST",
    });

    const dispatch = useDispatch();


    const googleLogin = async (code: string) =>  await commonFetch({input: {code}});

    consoleWrap.log('useGoogleLogin', { isLoading, data, error });

    useEffect(() => {
        if (data) {
            consoleWrap.log('data', data);
            const accessToken = data.access_token;
            localStorage.setItem('cobe-accessToken', accessToken);
            dispatch(login());
        }
    }, [data]);

    return { googleLogin, isLoading, data, error };
};
