import { Dropdown, DropdownPassThroughMethodOptions, DropdownPassThroughOptions } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import {ReactSVG} from "react-svg";
import { consoleWrap } from "../../main";

interface ToolDocLinkButtonProps {
    urlArray: string[];
}

export const ToolDocLinkButton = ({ urlArray }: ToolDocLinkButtonProps) => {
    const [selectedDoc, setSelectedDoc] = useState("");
    const text = "Go to Documentation";

    // Custom DropdownPassThroughOptions
    const customDropdownStyles: DropdownPassThroughOptions = {
        root: ({ props }: DropdownPassThroughMethodOptions) => ({
            className: classNames(
                'inline-flex items-center px-2 py-1 border border-gray-400 rounded-md text-sm font-semibold text-black-900 bg-white hover:bg-gray-50 focus:outline-none h-[32px]',
                { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
            )
        }),
        input: ({ props }: DropdownPassThroughMethodOptions) => ({
            className: classNames(
                'cursor-pointer flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap bg-transparent',
                'text-black-900 font-semibold text-sm px-3',
                { 'pr-7': props.showClear }
            )
        }),
        trigger: {
            className: classNames(
                'flex items-center justify-center shrink-0 border-l border-gray-400 text-gray-500 w-10 rounded-tr-md rounded-br-md' // Border between trigger and input
            )
        },
        wrapper: {
            className: classNames(
                'max-h-[200px] overflow-auto bg-white shadow-lg border border-gray-400 rounded-md' // More prominent border
            )
        },
        item: ({ context }: DropdownPassThroughMethodOptions) => ({
            className: classNames(
                'flex items-center px-4 py-2 text-sm h-[32px] text-black-900 hover:bg-gray-100 rounded-md',
                {
                    'text-black-900': !context.focused && !context.selected,
                    'bg-gray-100': context.focused && !context.selected,
                    'bg-blue-50 text-blue-700': !context.focused && context.selected,
                    'opacity-60 select-none pointer-events-none cursor-default': context.disabled
                }
            )
        }),
        itemGroup: {
            className: classNames('m-0 p-3 text-gray-800 bg-white font-bold cursor-auto')
        },

    };

    const ensureProtocol = (urlString: string): string => {
        if (!/^https?:\/\//i.test(urlString)) {
            return `https://${urlString}`;
        }
        return urlString;
    };

    const openUrlInNewTab = (urlString: string) => {
        const url = ensureProtocol(urlString);
        window.open(url, "_blank");
    };
    let validUrls: any[];
    try {
        validUrls = urlArray.filter((url) => url.trim() !== "");
    } catch (e) {
        consoleWrap.error("Error parsing URL array", e);
        validUrls = [];
    }
    

    const items = validUrls.map((url, index) => ({
        label: `Documentation ${index + 1}`,
        value: url,
    }));

    const handleDocSelect = (e: { value: string }) => {
        setSelectedDoc(e.value);
        openUrlInNewTab(e.value);
    };

    if (validUrls.length === 0) {
        return (
            <div className="h-[48px] w-[220px] flex items-center cursor-pointer invisible">
                <div className="h-[32px] border border-solid rounded border-checkbox_border items-center shadow-sm px-2 pr-3 pl-2 flex flex-row">
                    <div className="text-xs font-semibold w-max whitespace-nowrap"></div>
                </div>
            </div>
        );
    }

    if (validUrls.length === 1) {
        // Show button if there's only one valid URL
        return (
            <div
                className="h-[48px] w-[207px] flex items-center cursor-pointer"
                onClick={() => openUrlInNewTab(validUrls[0])} // Open the single URL directly
            >
                <div className="h-[32px] ml-auto border border-solid rounded border-checkbox_border items-center shadow-sm px-2 pr-3 pl-2 flex flex-row">
                    <div className="text-sm font-semibold w-max whitespace-nowrap">{text}</div>
                </div>
            </div>
        );
    }

    if (validUrls.length > 1) {
        // Show dropdown if there are multiple URLs
        return (
            <div className="h-[48px] w-[220px] flex items-center">
                <Dropdown
                    value={text}
                    options={items}
                    onChange={handleDocSelect}
                    placeholder={text}
                    pt={customDropdownStyles} // Apply the custom styling options
                    itemTemplate={(option) => (
                        <div className="flex items-center space-x-2 px-4 py-2 text-sm text-black-900 hover:bg-gray-100 rounded-md">
                            <span>{option.label}</span>
                        </div>
                    )}
                />
            </div>
        );
    }
};