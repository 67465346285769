import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchTerm } from "../../store/slices/searchSlice.ts";
import { useGetSearchSuggestions } from "../../hooks/useGetSearchSuggestions.tsx";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import IMAGES from "../../assets/images/images.tsx";
import {
    ISessionSearchState,
    updateSessionSearchTermState
} from "../../helpers/state/SearchState.ts";
import { RootState } from "../../store/store.ts";
import { consoleWrap } from "../../main.tsx";
import { DomHandler } from "primereact/utils";

export interface ISearchBarProps {
    onSearchClick: (value: string) => void;
    widthTailwindClass?: string;
}

export const SearchBar = (props: ISearchBarProps) => {
    const dispatch = useDispatch();
    const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
    const [items, setItems] = useState<string[]>([]);

    const autcompleteRef = useRef<AutoComplete>(null);

    const {
        getSearchSuggestions,
        isLoading: searchSuggestionsLoading,
        data: searchSuggestionsResponse,
        error: searchSuggestionsError
    } = useGetSearchSuggestions();

    useEffect(() => {
        loadSearchState();
    }, []);

    useEffect(() => {
        if (searchSuggestionsResponse) {
            const items = searchSuggestionsResponse.map((item: { label: string, name: string }) => item.name);
            setItems(items)
        }
    }, [searchSuggestionsResponse]);


    const loadSearchState = () => {
        const savedSearchString = sessionStorage.getItem("searchState");
        // searchTerm from redux is cleared after pipeline filter is applied.
        // So if a filter was applied, do not display the searchTerm in the input box.
        if (savedSearchString === searchTerm) {
            const savedSearch: ISessionSearchState = JSON.parse(savedSearchString);
            dispatch(setSearchTerm(savedSearch.searchTerm));
        }
    }

    const searchSuggestions = (event: AutoCompleteCompleteEvent) => {
        getSearchSuggestions({ query: event.query })
    }

    const search = () => {
        updateSessionSearchTermState(searchTerm);
        props.onSearchClick(searchTerm);
    }

    const findNextItem = (item: any): any => {
        const nextItem = item.nextElementSibling;

        return nextItem ? (DomHandler.getAttribute(nextItem, 'data-pc-section') === 'itemgroup' ? findNextItem(nextItem) : nextItem) : null;
    };

    const findPrevItem = (item: any): any => {
        let prevItem = item.previousElementSibling;

        return prevItem ? (DomHandler.getAttribute(prevItem, 'data-pc-section') === 'itemgroup' ? findPrevItem(prevItem) : prevItem) : null;
    };

    const addHighlight = (highlightItem: any, overlayRef: any) => {
        highlightItem.classList.add('bg-gray-200');
        const nonHighlightedItems = overlayRef?.querySelectorAll('li:not([data-p-highlight="true"])');
        nonHighlightedItems?.forEach((item: any) => {
            item.classList.remove('bg-gray-200');
        });
    }

    const onAutocompleteKeyDown = (e: any) => {
        const overlayRef = autcompleteRef.current?.getOverlay();
        let highlightItem = overlayRef ? DomHandler.findSingle(overlayRef, 'li[data-p-highlight="true"]') : null;

        if (e.key === 'ArrowDown') {
            let nextElement = findNextItem(highlightItem);
            if (highlightItem) {
                addHighlight(highlightItem, overlayRef);

                if (nextElement) {
                    DomHandler.scrollInView(overlayRef!, nextElement);
                }
            }
        }

        if (e.key === 'ArrowUp') {
            if (highlightItem) {
                let previousElement = findPrevItem(highlightItem);
                addHighlight(highlightItem, overlayRef);

                if (previousElement) {
                    DomHandler.scrollInView(overlayRef!, previousElement);
                }

            }
        }

        if (e.key === 'Enter') {
            search();
        }
    };

    
    return (
        <>
            <div className={`mx-auto ${props.widthTailwindClass ? props.widthTailwindClass : 'w-full'} px-[60px]`}>
                <div className="relative">
                    <Button
                        className="absolute inset-y-0 left-0 px-4 py-2" text
                        pt={{
                            root: { className: 'hover:bg-inherit z-10' },
                        }}
                        onClick={search}
                    >
                        <img src={IMAGES.search_icon} alt="Search" className="w-5 h-5 text-gray-600" />
                    </Button>
                    <AutoComplete placeholder="Search tools or pipelines"
                        ref={autcompleteRef}
                        value={searchTerm}
                        suggestions={items}
                        completeMethod={searchSuggestions}
                        onChange={(e) => dispatch(setSearchTerm(e.value))}
                        autoHighlight={false}
                        onKeyDown={onAutocompleteKeyDown}
                    />
                </div>
            </div>
        </>
    )
}

