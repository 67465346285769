import { TopBar } from "../../components/Builder/TopBar.tsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { BuilderFlow } from "../../components/Builder/Flow/BuilderFlow.tsx";
import { BuilderRightPanel } from "../../components/Builder/BuilderRightPanel.tsx";
import { SelectedNode } from "../../store/context/PipelineBuilder/builder-pipeline-selected-node-context.ts";
import { ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import { BuilderContext } from "../../components/Builder/BuilderContext.tsx";
import { useInitializeForm } from "../../hooks/pipeline-builder/useInitializeForm.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store.ts";
import { useGetPipelineDraftById } from "../../hooks/pipeline-builder/useGetPipelineDraftById.ts";
import { consoleWrap } from "../../main.tsx";
import {
    BuilderSaveDraftState,
    setBuilderSaveDraft,
    updateStatus
} from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import { IPipelineInformationData, setPipelineInfo } from "../../store/slices/Builder/BuilderPipelineInfoState.ts";
import { doRefreshToken } from "../../hooks/useRefreshToken.tsx";
import { confirmDialog } from "primereact/confirmdialog";
import { DeleteConfirmDialog } from "../../components/Dialogs/DeleteConfirmDialog.tsx";
import { useSubmitDraft } from "../../hooks/pipeline-builder/useSubmitDraft.ts";
import { setBuilderMetaReadOnly } from "../../store/slices/Builder/BuilderMeta.ts";
import { useRequestChangesPipelineDraft } from "../../hooks/pipeline-builder/useRequestChangesPipelineDraft.ts";
import { useApprovePipelineDraft } from "../../hooks/pipeline-builder/useApprovePipelineDraft.tsx";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";
import { NavToSubmissionPageState } from "../Admin/SubmissionPage.tsx";
import { jwtDecode } from "jwt-decode";

interface IBuilderProps {}

export const Builder = (props: IBuilderProps) => {
    const navigate = useNavigate();
    const navToAdminReviewPage = (summary: string, detail: string) => {
        const submissionPageNavState: NavToSubmissionPageState = {
            onLoadToasts: [
                {
                    severity: 'success',
                    summary,
                    detail
                }
            ]
        }
        navigate('/admin', { state: submissionPageNavState });
    }

    const [searchParams] = useSearchParams();
    const pipelineDraftIdParam = searchParams.get('id');

    // State that holds the nodes and edges for the pipeline builder, stored in context
    const [nodesState, setNodes] = useNodesState<any>([]);
    const [edgesState, setEdges] = useEdgesState<any>([]);

    // State that holds the selected node, stored in context
    const [selectedNode, setSelectedNode] = useState<SelectedNode | null>(null);

    // Custom hook to get the initialized form data for the PipelineInfo
    const {
        getInitializedFormData,
        data: initializedFormData,
        isLoading,
        error,
    } = useInitializeForm();

    // Custom hook to get the pipeline draft data by id
    // If the pipelineIdParam is not present, an empty string is passed to the hook.
    // This won't cause any problems because the page should not be rendered without a pipeline id.
    const {
        getPipelineDraftById,
        data: fetchedDraftData,
    } = useGetPipelineDraftById(pipelineDraftIdParam || "");

    // Custom hook to submit the draft
    const {
        postSubmitDraft,
        data: submitDraftData,
    } = useSubmitDraft();

    const {
        requestChangesPipelineDraft,
        data: requestChangesPipelineDraftData,
        isLoading: requestChangesPipelineDraftIsLoading,
        error: requestChangesPipelineDraftError,
    } = useRequestChangesPipelineDraft();

    const {
        approvePipelineDraft,
        data: approvePipelineDraftData,
        isLoading: approvePipelineDraftIsLoading,
        error: approvePipelineDraftError,
    } = useApprovePipelineDraft();

    const dispatch = useDispatch();
    const saveDraftState = useSelector((state: RootState) => state.builderSaveDraft);
    const pipelineInfoSlice = useSelector((state: RootState) => state.builderPipelineInfo);

    const isFirstRender = useRef(true);

    useEffect(() => {
        // Ensure that the pipelineDraftIdParam from URL params is present
        if (pipelineDraftIdParam) {
            if (isFirstRender.current) {
                doRefreshToken().then(() => {
                    getInitializedFormData();
                    getPipelineDraftById();
                });
                isFirstRender.current = false;
            }
        }

        // Cleanup
        return () => {
            consoleWrap.log("Builder cleanup");
            dispatch(setBuilderMetaReadOnly(false));
        };
    }, []);

    // When the draft data is fetched from the backend, load it into the context and state
    useEffect(() => {
        if (fetchedDraftData) {
            consoleWrap.log("Draft data fetched: ", fetchedDraftData);

            // Create the pipeline information data for the pipeline info state
            const data: IPipelineInformationData = {
                name: fetchedDraftData.pipeline.name,
                purpose: fetchedDraftData.pipeline.purposes[0],
                processDatatypes: fetchedDraftData.pipeline.assayTypes,
                version: fetchedDraftData.pipeline.version,
                documentation: fetchedDraftData.pipeline.link,
                executableLinks: fetchedDraftData.pipeline.main.executableLinks,
                institution: fetchedDraftData.pipeline.user.map((user) => user.name),
                datatype: fetchedDraftData.pipeline.datatype[0],
                doi: fetchedDraftData.pipeline.doi,
            }

            // Set the pipeline information data in the store
            dispatch(setPipelineInfo(data));
            dispatch(setBuilderSaveDraft(fetchedDraftData));

            // Set the read only state of the builder based on the draft data
            checkAndSetReadOnly(fetchedDraftData);
        }
    }, [fetchedDraftData]);

    useEffect(() => {
        if (submitDraftData) {
            consoleWrap.log("Draft submitted: ", submitDraftData);
            dispatch(updateStatus(submitDraftData.newStatus));
            dispatch(setBuilderMetaReadOnly(true));
        }
    }, [submitDraftData]);

    useEffect(() => {
        if (requestChangesPipelineDraftData) {
            dispatch(updateStatus(DraftStatus.Changes_Requested));
            navToAdminReviewPage('Success', 'Success! Change request sent');
        }
    }, [requestChangesPipelineDraftData]);

    useEffect(() => {
        if (approvePipelineDraftData) {
            dispatch(updateStatus(DraftStatus.Approved));
            navToAdminReviewPage('Success', 'Success! Pipeline Approved');
        }
    }, [approvePipelineDraftData]);

    const handleSubmit = () => {
        confirmDialog({
            message: 'Are you sure you want to submit this pipeline for review? You will not be able to edit the pipeline until you receive a response.',
            header: 'Submit Pipeline',
            defaultFocus: 'accept',
            acceptLabel: 'Yes, submit draft',
            rejectLabel: 'Cancel',
            closable: false,
            accept: () => {
                if (saveDraftState._id) {
                    postSubmitDraft(saveDraftState._id);
                }
            },
            reject: () => {}
        });
    }

    /**
     * Set the read only state based on the users status as pipeline owner or admin.
     * @param draftData
     */
    const checkAndSetReadOnly = (draftData: BuilderSaveDraftState) => {
        const jwtString = localStorage.getItem('cobe-accessToken');

        if (jwtString) {
            const jwt: {
                sub: string,
                email: string,
                firstName: string,
                lastName: string,
                displayName: string,
                jti: any,
                isAdmin: boolean
            } = jwtDecode(jwtString);

            // If the user is the owner of the pipeline, set the builder to read only if the status is submitted
            if (jwt.sub === draftData.submittedBy) {
                dispatch(setBuilderMetaReadOnly(draftData.status === DraftStatus.Submitted));
            } else {
                dispatch(setBuilderMetaReadOnly(true));
            }
        } else {
            dispatch(setBuilderMetaReadOnly(true));
        }
    };

    const handleRequestChangesPipeline = (id: string, subject: string, message: string) => {
		requestChangesPipelineDraft(id, subject, message);
	};

    const handleApprovePipeline = (id: string, email: string) => {
        confirmDialog({
            message: 'Are you sure you want to approve this pipeline? Approved pipelines are published for all CoBE users.',
            header: 'Approve Pipeline',
            acceptLabel: 'Yes, approve',
            rejectLabel: 'Cancel',
            accept: () => {
                approvePipelineDraft(id, email);
            },
            acceptClassName: 'bg-interactive text-white border-none hover:bg-interactive',
        });
    };

    return (
        <>
            {/* Component provides all context for the builder */}
            <BuilderContext
                builderSelectedNodeContextValue={{
                    selectedNode: selectedNode,
                    setSelectedNode: setSelectedNode,
                }}
                builderFlowContextValue={{
                    nodes: nodesState,
                    setNodes: setNodes,
                    edges: edgesState,
                    setEdges: setEdges
                }}
            >
                <DeleteConfirmDialog />
                <div className="w-full h-screen flex flex-col items-stretch">
                    <TopBar
                        pipelineName={pipelineInfoSlice.formData?.name || "New Pipeline"}
                        submissionHandler={handleSubmit}
                        requestChangesHandler={handleRequestChangesPipeline}
                        approveHandler={handleApprovePipeline}
                        status={saveDraftState.status}
                    />
                    {saveDraftState._id && (
                        <div className="h-full justify-start items-center flex">
                            <ReactFlowProvider>
                                <BuilderFlow />
                            </ReactFlowProvider>
                            <BuilderRightPanel initDropdownData={initializedFormData} />
                        </div>
                    )}
                </div>
            </BuilderContext>
        </>
    );
};
