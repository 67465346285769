import { applyEdgeChanges, applyNodeChanges, Background, Controls, Node, ReactFlow, useReactFlow } from "reactflow";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { RootBuilderNode, RootBuilderNodeIds } from "./Nodes/RootBuilderNode.tsx";
import { AddLeafDecisionNode, AddLeafDecisionNodeIds } from "./Nodes/AddLeafDecisionNode.tsx";
import { BuilderToolNode, BuilderToolNodeIds } from "./Nodes/BuilderToolNode.tsx";
import {
    BuilderPipelineSelectedNodeContext
} from "../../../store/context/PipelineBuilder/builder-pipeline-selected-node-context.ts";
import { BuilderFlowContext } from "../../../store/context/PipelineBuilder/builder-flow-context.ts";
import { generalLayoutNodes } from "../../../helpers/flow/flow-diagram-layout.ts";
import { GraphDirection } from "../../../helpers/flow/graph-direction.ts";
import {
    getFlowAssayTypes,
    getFlowEdgesFromState,
    getFlowRootNode,
    getFlowToolNodes
} from "../../../helpers/PipelineBuilder/BuilderFlowHelpers.ts";
import { consoleWrap } from "../../../main.tsx";
import { RootState, store } from "../../../store/store.ts";
import { BuilderAssayNode, BuilderAssayNodeIds } from "./Nodes/BuilderAssayNode.tsx";
import { useSelector } from "react-redux";

interface BuilderFlowProps {
}

export const BuilderFlow = (props: BuilderFlowProps) => {

    const {
        nodes,
        setNodes,
        edges,
        setEdges
    } = useContext(BuilderFlowContext);

    const selectedNodeContext = useContext(BuilderPipelineSelectedNodeContext);

    // Handles the flow view port positioning. Used when focusing on and panning to a node.
    const viewerPosition = useSelector((state: RootState) => state.builderMeta.flowViewer.position);

    const {
        // Sets the center of the flow view port
        setCenter
    } = useReactFlow();

    // Whenever the viewer position changes, update the flow view port position
    useEffect(() => {
        handleTransform(viewerPosition.x, viewerPosition.y);
    }, [viewerPosition]);

    // Callback used to handle the transformation of the flow view port
    const handleTransform = useCallback((x: number, y: number) => {
        setCenter(x + 50, y + 30, { duration: 800 });
    }, [setCenter]);

    // Memoize the nodeTypes object to avoid recreating the object on each re-render
    const nodeTypes = useMemo(() => ( {
        [RootBuilderNodeIds.TYPE]: RootBuilderNode,
        [AddLeafDecisionNodeIds.NODE_TYPE]: AddLeafDecisionNode,
        [BuilderToolNodeIds.NODE_TYPE]: BuilderToolNode,
        [BuilderAssayNodeIds.NODE_TYPE]: BuilderAssayNode
    } ), []);

    /**
     * When the component mounts, get the graph state from the store and format the nodes and edges for the flow component.
     */
    useEffect(() => {

        const graphState = store.getState().builderSaveDraft.graph;

        // From the graph state, get the root node and the assay nodes
        const rootNode = getFlowRootNode(graphState);
        if (!rootNode) return;

        const [assayNodeList] = getFlowAssayTypes(graphState, rootNode.id);
        const toolNodeList = getFlowToolNodes(graphState);
        // Get the edges from the graph state
        const treeEdgeList = getFlowEdgesFromState(graphState);

        // Format entire tree with new node
        const layoutedNodes = generalLayoutNodes(
            [rootNode, ...assayNodeList, ...toolNodeList],
            treeEdgeList,
            { graphDirection: GraphDirection.LEFT_TO_RIGHT, rankSeparationDistance: 75 }
        );
        setNodes(layoutedNodes);
        setEdges(treeEdgeList);
    }, []);

    useEffect(() => {
        consoleWrap.log("Selected node changed: ", selectedNodeContext.selectedNode?.id)

        setNodes(nodes => nodes.reduce((acc: Node[], node: Node) => {
            // If the node is the decision node, skip adding it to the new list. It should be removed when a new node is selected.
            if (node.type === AddLeafDecisionNodeIds.NODE_TYPE) return acc;
            // Update the isSelected property of the node
            const updatedNode = {
                ...node,
                data: {
                    ...node.data,
                    isSelected: node.id === selectedNodeContext.selectedNode?.id
                }
            };
            // Add the updated node to the new list
            acc.push(updatedNode);
            return acc;
        }, []));
    }, [selectedNodeContext.selectedNode]);

    /**
     * A callback function that is called whenever the nodes change
     */
    const onNodesChange = useCallback(
        (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );

    /**
     * A callback function that is called whenever the edges change
     */
    const onEdgesChange = useCallback(
        (changes: any) => {
            setEdges((eds) => applyEdgeChanges(changes, eds))
        },
        [],
    );

    /**
     * Clears the selected node when the background of the flow pane is clicked. Also removes decision node.
     * @param event
     */
    const onPaneClick = (event: React.MouseEvent) => {
        // remove decision node
        const newNodeList = nodes.filter(node => node.type !== AddLeafDecisionNodeIds.NODE_TYPE);
        setNodes([...newNodeList]);
        selectedNodeContext.setSelectedNode(null);
    }

    return (
        <>
            <div className="flex-grow h-full">
                <ReactFlow
                    nodeTypes={ nodeTypes }
                    nodes={ nodes }
                    onNodesChange={ onNodesChange }
                    nodesConnectable={ false }
                    nodesDraggable={ false }
                    edges={ edges }
                    onEdgesChange={ onEdgesChange }
                    fitView={ true }
                    onPaneClick={ onPaneClick }
                    proOptions={ { hideAttribution: true } }
                >
                    <Controls showInteractive={ false }/>
                    <Background gap={ 18 } size={ 1 } className="bg-[#F9F9F9]"/>
                </ReactFlow>
            </div>
        </>
    );
};
