interface AddPipelineRightPanelProps {
    data?: AddPipelineData
}

export interface AddPipelineData {

}

export const AddPipelineRightPanel = (props: AddPipelineRightPanelProps) => {
    return (
        <>
            Add pipeline
        </>
    );
};
