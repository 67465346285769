import React, { useEffect, useRef, useState } from 'react';
import { ApiWrapper } from '../../components/ApiWrapper/ApiWrapper';
import { useDispatch } from 'react-redux';
import { useGetPipelineDraftTableData } from '../../hooks/pipeline-builder/useGetPipelineDraftTableData';
import { SubmissionTable } from "../../components/AdminTable/SubmissionTable.tsx";
import { doRefreshToken } from "../../hooks/useRefreshToken.tsx";
import { resetBuilderSaveDraft } from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import { resetPipelineInfo } from "../../store/slices/Builder/BuilderPipelineInfoState.ts";
import { Toast, ToastMessage } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { consoleWrap } from '../../main.tsx';
import { useGetSubmissionTable } from '../../hooks/pipeline-builder/useGetSubmissionTable.ts';

export type NavToSubmissionPageState = {
    // Toasts to display when the page loads. Used when a pipeline status changes, as in when approved or sent back for changes.
    onLoadToasts: ToastMessage[];
}

export const SubmissionPage: React.FC = () => {
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const navState: NavToSubmissionPageState | undefined = location.state;

    const [toastMessages, setToastMessages] = useState<ToastMessage[]>([])
    const dispatch = useDispatch();

    const {
        getPipelineDraftTableData,
        data: pipelineDraftData,
        isLoading: pipelineDraftDataLoading,
        error: pipelineDraftDataError
    } = useGetPipelineDraftTableData();

    const {
        getSubmissionTableData,
        isLoading: submissionTableDataLoading,
        data: submissionTableData,
        error: submissionTableDataError
    } = useGetSubmissionTable();

    const isFirstRender = useRef(true);

    useEffect(() => {
        // Account for React strict mode and initial render
        if (isFirstRender.current) {
            doRefreshToken().then(() => {
                // Resets the builder state so the next pipeline loaded will have a fresh state
                dispatch(resetBuilderSaveDraft());
                dispatch(resetPipelineInfo());
                getPipelineDraftTableData();
                getSubmissionTableData();
            }).catch(e => {
                consoleWrap.error("Failed to refresh token or fetch data", e);
            });
            isFirstRender.current = false;
        }
    }, []);

    // Loads state with values from nav state.
    useEffect(() => {
        // Check if the page was nav'd to with a state object.
        if (navState) {
            setToastMessages(navState.onLoadToasts);
            // Clear the state so it doesn't show again on refresh.
            // By using replace, the state is removed from the history stack. The page does not load twice.
            navigate(location.pathname, { replace: true, state: undefined });
        }
    }, [navState, navigate, location.pathname]);

    // Display toasts when the page loads.
    useEffect(() => {
        // Display any toasts that were passed in the state.
        toastMessages.forEach(toastMessage => {
            toast.current?.show(toastMessage);
        });
    }, [toastMessages]);



    return (
        <div className="w-full h-full flex">
            <Toast ref={ toast } />
            <ApiWrapper
                isLoading={pipelineDraftDataLoading && submissionTableDataLoading}
                error={pipelineDraftDataError && submissionTableDataError}
                data={pipelineDraftData && submissionTableData}
                loadingLabel={"Loading submissions..."}
                errorLabel={"You must be logged in to view the submissions"}
            >
                <div className="w-full flex flex-col mx-auto px-[60px] py-[28px]">
                    <div className="flex justify-between items-center mb-5">
                        <h1 className="font-inter text-xl font-semibold text-black-900">Review</h1>
                    </div>
                    <div>
                        {submissionTableData && <SubmissionTable tableData={submissionTableData} />}
                    </div>
                </div>
            </ApiWrapper>
        </div>
    );
};
