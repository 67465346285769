import { useAxios } from "../useAxios";
import { doRefreshToken } from "../useRefreshToken";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum";

export interface RequestChangesPipelineDraftDataResponse {
    newStatus: DraftStatus.Changes_Requested;
}

type RequestChangesPipelineDraftAxiosResponse = {
    data: RequestChangesPipelineDraftDataResponse | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
};

export const useRequestChangesPipelineDraft = () => {
    const { commonFetch, isLoading, data, error } = useAxios({
        url: '/view/pipeline-builder/request-changes',
        method: "POST",
    });

    const requestChangesPipelineDraft = async (
        pipelineId: string,
		subject: string,
        message: string
    ) => {
        try {
            await doRefreshToken();
            await commonFetch({
                input: { pipelineId, subject, message},
            });
        } catch (e) {
            console.error("Failed to request changes for pipeline draft", e);
            throw e;
        }
    };

    return { requestChangesPipelineDraft, isLoading, data, error };
};
