import { InputText } from "primereact/inputtext";
import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import React from "react";
import { Dropdown } from "primereact/dropdown";
import { SelectItemOptionsType } from "primereact/selectitem";
import {Field, FieldProps, FormikErrors} from "formik";
import {ErrorField} from "../../ErrorField/ErrorField.tsx";
import { IExecutableLink } from "../../../hooks/useSearch.tsx";

const linkTypeDropdownOptions: SelectItemOptionsType = [
    { label: "Github", value: "Github" },
    { label: "CodeOcean", value: "CodeOcean" },
    { label: "CodeOceanVPC", value: "CodeOceanVPC" },
    { label: "WebApp", value: "WebApp" },
];

type FormProps  = {
    // The placeholder for the text input.
    placeholder: string;
}

type IExecutableLinkFormInputProps = RepeatableInputComponentProps & FormProps;

export const ExecutableLinkFormInput = (props: IExecutableLinkFormInputProps) => {

    const exeLinkFormikName = `${ props.name }[${ props.index }].executableLink`;
    const linkTypeFormikName = `${ props.name }[${ props.index }].linkType`;

    return (
        <div className="w-full h-44 border-b py-4 px-5 justify-center items-end gap-4 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                <div className="flex flex-row w-full items-center gap-4">
                    <div className="flex flex-col w-full gap-2">
                        <div>
                            <div className="self-stretch justify-start items-start gap-0.5 inline-flex">
                                <div className="text-black-900 text-sm font-normal font-inter leading-tight">
                                    Executable Type
                                </div>
                            </div>
                            <Field name={linkTypeFormikName}>
                                {({ field, form: { setFieldValue, submitCount, errors }, meta }: FieldProps) => {
                                    const castErrors = errors as FormikErrors<{ executableLinks: IExecutableLink[] }>;
                                    const showError = submitCount > 0 &&
                                        castErrors.executableLinks &&
                                        castErrors.executableLinks[props.index] &&
                                        (castErrors.executableLinks[props.index] as FormikErrors<IExecutableLink>).linkType;
                                    return (
                                        <>
                                            <Dropdown
                                                {...field}
                                                disabled={ props.readOnly }
                                                id={props.name}
                                                inputId={props.name}
                                                options={linkTypeDropdownOptions}
                                                placeholder="&nbsp;"
                                                onBlur={field.onBlur}
                                                onChange={(e) => {
                                                    setFieldValue(linkTypeFormikName, e.value);
                                                }}
                                                className={`h-12 w-full flex-grow rounded border ${
                                                    showError ? "!border-red-500" : "border-zinc-400"
                                                }`}
                                            />
                                            {showError &&
                                                <div className="mt-1">
                                                    <ErrorField errorMessage={"Executable Type is required"} />
                                                </div>
                                            }
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div>
                            <div className="self-stretch justify-start items-start gap-0.5 inline-flex">
                                <div className="text-black-900 text-sm font-normal font-inter leading-tight">
                                    Executable Link
                                </div>
                            </div>
                            <Field name={exeLinkFormikName}>
                                {(fieldProps: FieldProps) => {
                                    const errors = fieldProps.form.errors as FormikErrors<{
                                        executableLinks: IExecutableLink[]
                                    }>;
                                    const showError = fieldProps.form.submitCount > 0 &&
                                        errors.executableLinks &&
                                        errors.executableLinks[props.index] &&
                                        (errors.executableLinks[props.index] as FormikErrors<IExecutableLink>).executableLink;
                                    return (
                                        <>
                                            <InputText
                                                {...fieldProps.field}
                                                disabled={props.readOnly}
                                                data-testid={props.name}
                                                onBlur={fieldProps.form.handleBlur}
                                                onChange={fieldProps.form.handleChange}
                                                placeholder="&nbsp;"
                                                className={`w-full h-12 self-stretch px-3 py-2.5 bg-white rounded border ${showError ? 'border-red-500' : 'border-zinc-400'} justify-start items-center gap-2.5 inline-flex`}
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800",
                                                    },
                                                }}
                                            />
                                            {showError && (
                                                <div className="mt-1">
                                                    <ErrorField errorMessage={"Executable Link is required"}/>
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                    </div>
                    <div className="w-10 flex-shrink-0">
                        { !props.readOnly && (
                            <ReactSVG
                                src={IMAGES.trash_bin_icon}
                                onClick={() => props.onDelete(props.index)}
                                className="p-2 rounded-full justify-start items-start gap-2.5 flex transition duration-300 ease-in-out hover:bg-zinc-100 hover:cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

}
