import React, { Dispatch, SetStateAction } from "react";
import { Edge, Node } from "reactflow"

export interface IBuilderFlowContext {
    nodes: Node[];
    setNodes: Dispatch<SetStateAction<Node<any, string | undefined>[]>> ;
    edges: Edge[];
    setEdges:  Dispatch<SetStateAction<Edge<any>[]>> ;
}

const defaultSetNodes: Dispatch<SetStateAction<Node<any, string | undefined>[]>> = () => {};
const defaultSetEdges: Dispatch<SetStateAction<Edge<any>[]>> = () => {};

/**
 * Context for the pipeline builder flow.
 */
export const BuilderFlowContext = React.createContext<IBuilderFlowContext>({
    nodes: [],
    //(prevState: Node<any, string | undefined>[]) => Node<any, string | undefined>[]
    setNodes: defaultSetNodes,
    edges: [],
    setEdges: defaultSetEdges,
});
