import { ISearchResult } from "../../hooks/useSearch.tsx";
import { DocumentLikeDataType } from "../../models/DocumentLikeDataType.ts";

export interface ISessionSearchState {
    searchTerm: string;
    results: ISearchResult;
}

export const updateSessionSearchResultState = (results: ISearchResult) => {
    const savedSearchString = sessionStorage.getItem("searchState");
    if (savedSearchString) {
        const savedSearch: ISessionSearchState = JSON.parse(savedSearchString);
        const newSearchState: ISessionSearchState = { ...savedSearch, results };
        sessionStorage.setItem("searchState", JSON.stringify(newSearchState));
    } else {
        const searchState: ISessionSearchState = { searchTerm: '', results };
        sessionStorage.setItem("searchState", JSON.stringify(searchState));

    }
}

export const updateSessionSearchTermState = (searchTerm: string) => {
    const savedSearchString = sessionStorage.getItem("searchState");
    if (savedSearchString) {
        const savedSearch: ISessionSearchState = JSON.parse(savedSearchString);
        const newSearchState: ISessionSearchState = { ...savedSearch, searchTerm };
        sessionStorage.setItem("searchState", JSON.stringify(newSearchState));
    } else {
        const searchState: ISessionSearchState = { searchTerm: '', results: { tools: [], pipelines: [] } };
        sessionStorage.setItem("searchState", JSON.stringify(searchState));

    }
}

/**
 * Used when the user likes or unlikes a tool or pipeline. This function updates the like data in the session storage.
 *
 * @param documentId - The id of the document that was liked or unliked
 * @param type - The type of document that was liked or unliked
 * @param likeData - The new like data for the document that comes from the useToggleToolLike or useTogglePipelineLike hooks
 */
export const updateSessionSearchResultLikesState = (documentId: string, type: "tools" | "pipelines", likeData: DocumentLikeDataType) => {
    // Get the current search state from the session storage
    const savedSearchString = sessionStorage.getItem("searchState");
    if (savedSearchString) {
        const savedSearch: ISessionSearchState = JSON.parse(savedSearchString);

        // Update the like data for the document in the search results
        savedSearch.results[type] = savedSearch.results[type].map((item) => {
            if (item.rankedItem._id === documentId) {
                return { ...item, likeData };
            }
            return item;
        });

        sessionStorage.setItem("searchState", JSON.stringify(savedSearch));
    }
}
