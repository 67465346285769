import {ToggleButtonPassThroughOptions} from "primereact/togglebutton";
import {classNames} from "primereact/utils";

export const togglebutton: ToggleButtonPassThroughOptions = {
    root: ({ props }: any) => ({
        className: classNames(
            'inline-flex cursor-pointer select-none items-center align-bottom text-center overflow-hidden relative',
            'px-3 py-3 rounded-md text-base  h-6',
            'transition duration-200 ease-in-out',
            {
                'bg-subdued dark:bg-gray-900 text-gray-700 dark:text-white/80 hover:bg-gray-100 dark:hover:bg-gray-800/80 hover:border-gray-300 dark:hover:bg-gray-800/70 hover:text-gray-700 dark:hover:text-white/80':
                    !props.checked,
                'bg-selected text-interactive hover:bg-subdued': props.checked
            },
            { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
        )
    }),
    label: (_: any) => ({
        className: classNames('text-center text-sm w-full')
    }),
    icon: ({ props }: any) => ({
        className: classNames(' mr-2', {
            'text-gray-600 dark:text-white/70': !props.modelValue,
            'text-white': props.checked
        })
    })
}
