import { useAxios } from "./useAxios.tsx";
import {doRefreshToken} from "./useRefreshToken.tsx";

interface ExecutableLink {
    executableLink: string;
    linkType: string;
    default: boolean;
}

interface Purpose {
    text: string;
    executableLinks: ExecutableLink[];
}

interface Tool {
    name: string;
    versions: string[];
    purposes: Purpose[];
    input: string[];
    output: string[];
    link: string[] | undefined;
    doi: string;
    user: string[];
}

export interface AddToolDto {
    tool: Tool;
}

export interface AddToolAxiosResponse {
    data: AddToolDto;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useAddTool = () => {
    const { commonFetch, isLoading, data, error }: AddToolAxiosResponse = useAxios<any>({
        url: "/data/tool/add",
        method: "POST",
    });

    const addTool = async (addToolDto: AddToolDto) => {
        await doRefreshToken();  // Ensure the token is refreshed

        // Call commonFetch and let it update the data and error states
        await commonFetch({
            input: addToolDto
        });

    };

    return { addTool, isLoading, data, error };
};
