import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { DataTable, DataTablePassThroughMethodOptions } from 'primereact/datatable';
import { Column, ColumnPassThroughMethodOptions } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from 'primereact/toast';
import { ISubmissionTableRowData, useGetSubmissionTable } from '../../hooks/pipeline-builder/useGetSubmissionTable';
import { setBuilderMetaReadOnly } from "../../store/slices/Builder/BuilderMeta.ts";
import { formatDateTime } from "../../helpers/utils/formatDateTime.ts";
import { PipelineDraftStatusTag } from "../PipelineDraft/PipelineDraftStatusTag.tsx";
import { setBuilderSubmittedPipelines } from "../../store/slices/Builder/BuilderSubmittedPipelines.ts";
import { RootState } from "../../store/store.ts";
import { doRefreshToken } from "../../hooks/useRefreshToken.tsx";


export const SubmissionTable = (props: {tableData: any}) => {

    const [selectedSubmission, setSelectedSubmission] = useState<ISubmissionTableRowData | undefined>(undefined);
    const { rows: pipelineSubmissions } = useSelector((state: RootState) => state.builderSubmittedPipelines);

    const toast = useRef<Toast>(null);
    const cm = useRef<ContextMenu | null>(null);

    const dispatch = useDispatch();

    const nav = useNavigate();

    const isFirstRender = useRef(true);

    useEffect(() => {
        dispatch(setBuilderSubmittedPipelines(props.tableData.submissions));
    }, []);


    const dateTemplate = (rowData: ISubmissionTableRowData, field: 'editedOn' | 'submittedOn') => {
        return formatDateTime(rowData[field]);
    };

    /**
     * Navigate to the draft editor page.
     * @param draftRow
     */
    const navToDraftEditor = (draftRow: ISubmissionTableRowData) => {
        // Viewing pipeline from admin table should be read only
        dispatch(setBuilderMetaReadOnly(true));
        nav(`/builder?id=${ draftRow.id }&from=review`);
    };

    /**
     * Template for the pipeline name column.
     * @param tableRow
     */
    const pipelineNameTemplate = (tableRow: ISubmissionTableRowData) => {
        return (
            <div className="flex items-center">
                <span className="hover:underline cursor-pointer" onClick={ () => navToDraftEditor(tableRow) }>{ tableRow.name }</span>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast}></Toast>
            <DataTable
                onContextMenu={(e) => cm.current?.show(e.originalEvent)}
                contextMenuSelection={selectedSubmission}
                onContextMenuSelectionChange={(e) => setSelectedSubmission(e.value)}
                value={pipelineSubmissions}
                pt={{
                    table: ({}: DataTablePassThroughMethodOptions<any>) => ({
                        className: classNames(' flex-auto rounded-lg border border-separate border-slate-300 border-solid bg-inherit '),
                    }),
                    column: {
                        headerCell: ({}: ColumnPassThroughMethodOptions) => {
                            classNames('border-0')
                        },
                        headerTitle: ({ props }: ColumnPassThroughMethodOptions) => {
                            if (props.sortable) {
                                return classNames('hover:cursor-pointer')
                            }
                        },
                        bodyCell: ({}: ColumnPassThroughMethodOptions) => {
                            classNames('p-2')
                        }
                    }
                }}>
                <Column sortable className="px-6 py-4 text-black-900 text-table_text" field="name" header="Name" body={ pipelineNameTemplate }></Column>
                <Column sortable className="px-6 py-4 text-black-900 text-table_text" field="status" header="Status" body={(rowData) => <PipelineDraftStatusTag status={rowData.status} />}></Column>
                <Column className="px-6 py-4 text-black-900 text-table_text" field="userEmail" header="User"></Column>
                <Column sortable className="px-6 py-4 text-black-900 text-table_text" field="editedOn" header="Edited On" body={(rowData) => dateTemplate(rowData, 'editedOn')}></Column>
                <Column sortable className="px-6 py-4 text-black-900 text-table_text" field="submittedOn" header="Submitted On" body={(rowData) => dateTemplate(rowData, 'submittedOn')}></Column>
            </DataTable>
        </>
    );
};
